import { getStorage, ref, uploadBytes } from "firebase/storage";
// prettier-ignore
import { getFirestore, collection, getDoc, getDocs,  query, where, doc, setDoc, Timestamp, serverTimestamp, updateDoc, deleteDoc, orderBy, limit, addDoc } from "firebase/firestore/lite";

export async function upload_image(image_path, ext, uri) {
  let contentType = ["jpg", "jpeg"].includes(ext) ? "image/jpeg" : "image/png";
  contentType = ext === "gif" ? "image/gif" : contentType;

  const blob = await get_blob_unclosed(uri);
  const cacheControl = "public,max-age=31536000";
  const metadata = { contentType, cacheControl };

  const storage = getStorage();
  const photoRef = ref(storage, image_path);
  await uploadBytes(photoRef, blob, metadata);
  if (blob.close) {
    blob.close();
  }
}

export class FSHelpers {
  constructor(firebase) {
    // just for now till we have the proper init
    const db = getFirestore(firebase);
    this.db = db;
  }
  async setProfileMerge(user_uid, profile) {
    const profileRef = doc(this.db, "profiles", user_uid);
    return await setDoc(profileRef, profile, { merge: true });
  }
  // this requires the uid and is prefixed with option.
  async setProfileOption(user_uid, key, value) {
    const dot_n = `options.${key}`;
    const update_obj = { [dot_n]: value };
    const profileRef = doc(this.db, "profiles", user_uid);
    return await updateDoc(profileRef, update_obj);
  }
  async setUserService(user_uid, service_maze_id, enabled) {
    // prettier-ignore
    if (!user_uid) { return }
    const ts = Timestamp.fromDate(new Date());
    const payload = { enabled, ts };
    const dot_n = `services.${service_maze_id}`;
    const update_obj = { [dot_n]: payload };
    const profileRef = doc(this.db, "profiles", user_uid);
    return await updateDoc(profileRef, update_obj);
  }
  async queryFFS(uid) {
    const q1 = query(
      collection(this.db, "follow_requests"),
      where("to_uid", "==", uid)
    );
    const followers = get_arr(await getDocs(q1));
    const q2 = query(
      collection(this.db, "follow_requests"),
      where("from_uid", "==", uid)
    );
    const following = get_arr(await getDocs(q2));
    return { following, followers };
  }
  async queryMarks(user_uid) {
    // prettier-ignore
    if (!user_uid) { return [] }
    const q = query(
      collection(this.db, "user_marks"),
      where("user_uid", "==", user_uid),
      orderBy("ts", "desc")
    );
    return get_arr(await getDocs(q), false);
  }
  async queryPublicMarks(user_uid) {
    // prettier-ignore
    if (!user_uid) { return [] }

    const q = query(
      collection(this.db, "user_marks"),
      where("user_uid", "==", user_uid),
      where("is_public", "==", true),
      orderBy("ts", "desc")
    );
    return get_arr(await getDocs(q), false);
  }

  async setMark(user_uid, mark) {
    // prettier-ignore
    if (!user_uid) { return }
    const { tvmaze_id } = mark;
    const id = `${user_uid}@${tvmaze_id}`;

    const markRef = doc(this.db, "user_marks", id);
    return await setDoc(markRef, mark, { merge: true });
  }

  async queryFavArt(user_uid, include_private) {
    // prettier-ignore
    if (!user_uid) { return [] }
    const q = include_private
      ? query(
          collection(this.db, "favorite_artists"),
          where("user_uid", "==", user_uid)
        )
      : query(
          collection(this.db, "favorite_artists"),
          where("user_uid", "==", user_uid),
          where("is_public", "==", true)
        );
    return get_arr(await getDocs(q));
  }

  async setFavArt(user_uid, favArt) {
    // prettier-ignore
    if (!user_uid) { return }
    const { tvmaze_artist_id } = favArt;
    const id = `${user_uid}@${tvmaze_artist_id}`;
    const favRef = doc(this.db, "favorite_artists", id);
    return await setDoc(favRef, favArt, { merge: true });
  }

  async deleteFavArt(id) {
    const favRef = doc(this.db, "favorite_artists", id);
    return await deleteDoc(favRef);
  }
  async querySeenEp(user_uid) {
    // prettier-ignore
    if (!user_uid) { return [] }
    const q = query(
      collection(this.db, "seen_episodes"),
      where("user_uid", "==", user_uid)
    );
    return get_arr(await getDocs(q));
  }
  async setSeenEp(id, seenEp) {
    const epRef = doc(this.db, "seen_episodes", id);
    return await setDoc(epRef, seenEp, { merge: true });
  }
  async setFlagForSelfDelete(user_uid, flagged_for_self_deletion, public_page) {
    console.log("Flagging account for self-deletion");
    const profileRef = doc(this.db, "profiles", user_uid);
    const pageRef = doc(this.db, "public_profile_pages", user_uid);
    await updateDoc(profileRef, {
      flagged_for_self_deletion,
      is_deleted: false,
    });
    await updateDoc(pageRef, { flagged_for_self_deletion });
  }

  async makeFollowRequest(from_uid, to_uid) {
    const created = Timestamp.fromDate(new Date());
    const followData = { from_uid, to_uid, created };
    const docRef = await addDoc(
      collection(this.db, "follow_requests"),
      followData
    );
    const { id } = docRef;
    return { ...followData, id };
  }
  async deleteFollowRequest(id) {
    const favRef = doc(this.db, "follow_requests", id);
    return await deleteDoc(favRef);
  }
  //   only used in tests, removed for migration
  async acceptFollow(current_user_uid, id) {
    console.log("NO OP");
  }
  // this one matches the screenName exactly, the other attempts fuzzyness
  async queryPublicUserPages(screenName) {
    screenName = screenName.toLowerCase();
    const q = query(
      collection(this.db, "public_profile_pages"),
      where("screenName", "==", screenName)
    );
    return get_arr(await getDocs(q), false);
  }
  async getPublicUserPage(uid) {
    const docRef = doc(this.db, "public_profile_pages", uid);
    const r = await getDoc(docRef);
    return r.exists() ? r.data() : false;
  }
  // prettier-ignore
  async setPublicUserPage(uid, { screenName, displayName, isActivePublic, userPhoto, bio, twitter, site }) {
    const screenNameCased = screenName;
    screenName = screenName.toLowerCase();
    userPhoto = userPhoto || false
    bio = bio || ''
    twitter = twitter || ''
    site = site || ''
    // XXX needs to be updated here as well as above when we add something
    const obj = { screenName, screenNameCased, displayName, uid, isActivePublic, userPhoto, bio, twitter, site };
    const pageRef = doc(this.db, 'public_profile_pages', uid)
    await setDoc(pageRef, obj, { merge: true });
    return obj;
  }
  async storeUserPageImage(key, { user_uid, uri, ext, name }) {
    const fname = `${name}.${ext}`;
    const image_path = `profile_media/${user_uid}/${fname}`;
    await upload_image(image_path, ext, uri);

    // now update the db
    const obj = { [key]: image_path };

    // prettier-ignore
    const pageRef = doc(this.db, 'public_profile_pages', user_uid)
    await setDoc(pageRef, obj, { merge: true });

    return image_path;
  }
  // this one is for the search, not truly fuzzy but closer than the one above
  async userPageSearchQuery(searchQuery) {
    // no fuzzy matching, just things starting with the prefix (searchQuery)
    // https://stackoverflow.com/a/56815787/83859
    const q = query(
      collection(this.db, "public_profile_pages"),
      where("screenName", ">=", searchQuery),
      where("screenName", "<=", searchQuery + "\uf8ff")
    );
    return get_arr(await getDocs(q));
  }
  async submitUserFeedback({ email, body }) {
    const created = serverTimestamp();
    const isResolved = false;
    const resolvedAt = false;
    return await addDoc(collection(this.db, "feedback"), {
      created,
      email,
      body,
      isResolved,
      resolvedAt,
    });
  }
}

function get_arr(qs, include_id = true) {
  let res = [];
  qs.forEach((doc) => {
    let p = doc.data();
    // prettier-ignore
    if (include_id) { p["id"] = doc.id; }
    res.push(p);
  });
  return res;
}

// https://github.com/expo/firebase-storage-upload-example/blob/master/App.js
// https://github.com/expo/expo/issues/2402#issuecomment-443726662
async function get_blob_unclosed(uri) {
  // Why are we using XMLHttpRequest? See:
  // https://github.com/expo/expo/issues/2402#issuecomment-443726662
  const blob = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      resolve(xhr.response);
    };
    xhr.onerror = function (e) {
      console.log(e);
      reject(new TypeError("Network request failed"));
    };
    xhr.responseType = "blob";
    xhr.open("GET", uri, true);
    xhr.send(null);
  });
  return blob;
  // const ref = firebase.storage().ref().child(path);
  // const snapshot = await ref.put(blob);
  //
  // // We're done with the blob, close and release it
  // blob.close();
  //
  // return // await snapshot.ref.getDownloadURL();
}
