// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, Animated, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import * as Icons from "./components/Icons.js";
import * as ButtonIcons from "./components/ButtonIcons.js";
import * as Toast from "./components/Toast.js";

import CardStack, { Card } from "react-native-card-stack-swiper";

import useSeekStore from "../app_shared/use-seek-store";

import { TvShowCard } from "./components/TvShowCard";
import { ArtistListItem } from "./components/ArtistListItem.js";
import { UserListItem } from "./components/UserListItem.js";

import { SearchWidget } from "./components/SearchWidget";
// import { IndicatorOverlay } from "./components/IndicatorOverlay";
import { ActiveSwipeIndicators } from "./components/ActiveSwipeIndicators.js";

import { useNavigation } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useIsFocused } from "@react-navigation/native";

import { createAsset } from "use-asset";

import { chunkArray } from "./helpers/utils.js";
import { Entypo } from "@expo/vector-icons";
import colors from "./colors.js";

// import { createStackNavigator } from "@react-navigation/stack";
// const Stack = createStackNavigator();
// import { useWindowDims } from "./helpers/dimsHook.js";
// prettier-ignore
import { TvShowListProgressiveItem } from "./components/TvShowListItem.js";
import { ServiceIcon } from "./components/ServiceIcon.js";
import { ActionMarkButton } from "./components/ActionMarkButton.js";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
const FH = new FSHelpers(firebase);

export function SearchContainer(props) {
  const navigation = useNavigation();
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);
  const profile = useSeekStore((state) => state.profile);
  const user_uid = profile?.uid;
  return (
    <SearchWidget
      initialMode={props.initialMode}
      style={props.style}
      renderShowItem={({ item }) => {
        return (
          <TvShowListProgressiveItem
            show_data={item}
            onPress={() => {
              navigation.navigate("ShowDetail", {
                show_id: item.id,
              });
            }}
          />
        );
      }}
      renderArtistItem={({ item }) => {
        const extant = favorite_artists.find(
          (m) => m.tvmaze_artist_id === item.id
        );
        return (
          <ArtistListItem person={item}>
            {extant ? (
              <ButtonIcons.HeartFull
                onPress={() => {
                  if (!user_uid) {
                    // prettier-ignore
                    Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                    return;
                  }

                  seek_actions.setFavArt(item.id);
                }}
              />
            ) : (
              <ButtonIcons.HeartEmpty
                onPress={() => {
                  if (!user_uid) {
                    // prettier-ignore
                    Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                    return;
                  }
                  seek_actions.setFavArt(item.id);
                }}
              />
            )}
          </ArtistListItem>
        );
      }}
      renderUserItem={({ item }) => {
        return <UserListItem userPage={item} navigation={navigation} />;
      }}
    />
  );
}
