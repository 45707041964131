import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Share, Text, ScrollView, Pressable, Button, View, TextInput, Image, Switch} from "react-native";
import { useDebounce } from "../helpers/hooks.js";

import AllAssets from "../helpers/assets.js";
const { thumbAsset } = AllAssets;

import { AntDesign } from "@expo/vector-icons";

export function PlaceHolderUserPhoto(props) {
  const placeholderStyles = [
    styles.placeholderUserPhoto,
    { width: props.size * 2, height: props.size * 2 },
    props.backgroundColor ? { backgroundColor: props.backgroundColor } : {},
  ];

  return (
    <Pressable
      style={placeholderStyles}
      onPress={props.onPress}
      resizeMode="cover"
    >
      <AntDesign name="user" size={props.size} color={props.color || "white"} />
    </Pressable>
  );
}

export function UserPhotoOuter(props) {
  return (
    <Suspense
      fallback={
        <PlaceHolderUserPhoto
          size={props.size / 2}
          color="black"
          onPress={props.onPress}
        />
      }
    >
      <UserPhotoWithLoad {...props} />
    </Suspense>
  );
}

export const UserPhotoComp = React.memo(UserPhotoOuter);
export const UserPhotoWithLoad = React.memo(UserPhotoInner);

function UserPhotoInner(props) {
  // thumbAsset checks to see if the thumb version exists first, and uses the full version if it doesn't
  const uri = thumbAsset.read(props.userPhoto);
  // console.log("uri:", uri);
  const source = uri === false ? false : { uri };

  const userPhotoStyles = [
    styles.userPhoto,
    { width: props.size },
    { height: props.size },
  ];

  if (!source) {
    console.log("no source", storage_path);
    return (
      <PlaceHolderUserPhoto size={props.size / 2} onPress={props.onPress} />
    );
  }

  return (
    <Pressable onPress={props.onPress} style={styles.pressable}>
      <Image style={userPhotoStyles} source={source} resizeMode="cover" />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  userPhoto: {
    borderRadius: 30,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "rgb(50,50,50)",
  },
  pressable: { flexDirection: "row" },
  placeholderUserPhoto: {
    backgroundColor: "gray",
    width: 50,
    height: 50,
    borderRadius: 30,
    justifyContent: "center",
    alignItems: "center",
  },
});
