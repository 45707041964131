import React, { useState, useEffect, useRef } from "react";
import Constants from "expo-constants";
const { extra } = Constants.manifest;
import * as Google from "expo-auth-session/providers/google";
import * as Linking from "expo-linking";
import { makeRedirectUri } from "expo-auth-session";

// prettier-ignore
import { StyleSheet, Platform, Text, SafeAreaView, ScrollView, Pressable, Share, Button, View,  TextInput, ImageBackground, Switch} from "react-native";

import { useActionSheet } from "@expo/react-native-action-sheet";
import { useNavigation } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";

import { createStackNavigator } from "@react-navigation/stack";
// const Stack = createStackNavigator();
import webchannels_json from "../app_shared/maze_webchannels.json";
const possible_services = Object.values(webchannels_json);

import AuthSingleton from "../use-auth.js";
const { useAuth } = AuthSingleton;

import useSeekStore from "../app_shared/use-seek-store";

import useLocalStore from "./stores/local_store";

import { AntDesign } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";

import * as Toast from "./components/Toast.js";
// prettier-ignore
import { LoginAppleButton, LogoutButton, LoginGoogleButton } from "./components/LoginButtons.js";

import { PublicPageSettings } from "./components/PublicPageSettings.js";
import { SummaryForOwnPage } from "./components/SummaryForOwnPage.js";
import { LanguageOptions } from "./components/LanguageOptions.js";
import { useDebounce } from "./helpers/hooks.js";
import AllAssets from "./helpers/assets.js";
const { apiShowAsset, publicPageAsset, thumbAsset } = AllAssets;

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";

// prettier-ignore
import { attempt_display_name, get_public_url_for_sn } from "./helpers/name_helpers.js";
import { uuidv4ish } from "./helpers/utils.js";

import { ServiceIcon } from "./components/ServiceIcon.js";
import colors from "./colors.js";
import { ShareIcon, Edit } from "./components/Icons.js";
import * as ButtonIcons from "./components/ButtonIcons.js";

const FH = new FSHelpers(firebase);

WebBrowser.maybeCompleteAuthSession();

export default function ProfileScreen() {
  const { showActionSheetWithOptions } = useActionSheet();

  const auth = useAuth();

  const profile = useSeekStore((state) => state.profile);
  const public_page = useSeekStore((state) => state.public_page);
  const following = useSeekStore((state) => state.following);
  const followers = useSeekStore((state) => state.followers);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);
  const seek_actions = useSeekStore((state) => state.seek_actions);
  // only applies if we have a public page
  const [editPageSettingsMode, setEditPageSettingsMode] = useState(false);

  const local_store_actions = useLocalStore(
    (state) => state.local_store_actions
  );
  // console.log("ProfileScreen");
  // console.log(profile);

  const onImagePick = async (data) => {
    Toast.alert({ title: "Uploading Profile Pic...", duration: 10 }, "info");
    // const { base64, ext } = data;
    const { uri, ext } = data;
    const user_uid = profile.uid;
    // const name = `userPhoto`;
    // using this to make caching easier, see #84
    const name = uuidv4ish();
    // in ADDITION to uploading it
    // this also updates the firestore object for their profile page BUT NOT useSeekStore
    const path = await FH.storeUserPageImage("userPhoto", {
      user_uid,
      uri,
      ext,
      name,
    });
    thumbAsset.clear(path);
    publicPageAsset.clear(user_uid);

    setTimeout(async () => {
      // XXX this is to update it, so we give the chance for the thumbs to process
      // and we have to clear it because initially the asset will return false when the thumb file it's looking for does not yet exist
      // basically a fun race condition!
      thumbAsset.clear(path);
      Toast.alert({ title: "Profile Pic Uploaded!" }, "success");
      await seek_actions.fetchAndLoadOwnPublicPage(user_uid);
    }, 1500);
  };

  // when we actually delete the account, set another flag, saying it's deleted, and don't show this
  if (profile && profile.flagged_for_self_deletion) {
    return (
      <PendingDeleteWarning seek_actions={seek_actions} profile={profile} />
    );
  }
  const scrollRef = useRef();
  return (
    <SafeAreaView
      source={require("../assets/bg-gradient-half.png")}
      style={styles.container}
    >
      <ScrollView keyboardShouldPersistTaps={"handled"} ref={scrollRef}>
        <View style={styles.titleWrapper}>
          <Text style={styles.titleText}>Profile & Account</Text>
          <View style={styles.shareEditContainer}>
            <View style={{ marginRight: 20 }}>
              <ButtonIcons.ShareIcon
                onPress={async () => {
                  const result = await Share.share({
                    url: get_public_url_for_sn(public_page.screenName)[
                      "actual"
                    ],
                  });
                }}
              />
            </View>
            <View>
              <ButtonIcons.Edit
                onPress={() => {
                  scrollRef.current?.scrollTo({ x: 0, y: 75, animated: true });

                  setEditPageSettingsMode(true);
                }}
              />
            </View>
          </View>
        </View>

        {public_page && !editPageSettingsMode ? (
          <SummaryForOwnPage
            profile={profile}
            public_page={public_page}
            setEditPageSettingsMode={setEditPageSettingsMode}
            onImagePick={onImagePick}
            following={following}
            followers={followers}
            favorite_artists={favorite_artists}
          />
        ) : (
          <PublicPageSettingsWrap
            profile={profile}
            public_page={public_page}
            seek_actions={seek_actions}
            setEditPageSettingsMode={setEditPageSettingsMode}
          />
        )}

        <StreamingServicesSettings />
        <LanguageOptions profile={profile} seek_actions={seek_actions} />

        <View style={styles.bottomContainer}>
          <Pressable
            style={styles.contactUsPressable}
            onPress={() => {
              Linking.openURL("https://www.seekingtv.com/feedback");
            }}
          >
            <View>
              <Text style={styles.contactUsText}>
                Having Trouble? Have Feedback?{" "}
              </Text>
            </View>
            <Text style={[styles.boldContact]}>Contact Us!</Text>
          </Pressable>
        </View>
        <View style={styles.bottomContainer}>
          <Text style={styles.legal}>
            SeekingTV maintains no partnership or agreement with these services.
            All trademarks, logos, and brand names are the property of their
            respective owners. All company, product, and service names used
            herein are for identification purposes only.
          </Text>
        </View>
        <View style={styles.bottomContainer}>
          <Pressable
            onPress={() => {
              local_store_actions.setSeenIntro(false);
            }}
            style={styles.replayIntroPressable}
          >
            <Text style={{ color: "white" }}>Replay Introductory Tour</Text>
          </Pressable>
        </View>
        <View style={styles.bottomContainer}>
          {!auth.user ? null : (
            <LogoutButton
              onPress={async () => {
                await auth.signout();
                seek_actions.onLogOut();
                Toast.alert({ title: "Signed Out!" }, "info");
              }}
            />
          )}
        </View>
        <View style={styles.bottomContainer}>
          {!profile ? null : (
            <Pressable
              onPress={() => {
                showActionSheetWithOptions(
                  {
                    title:
                      "Are you sure you want to request deletion of your account? Once processed, this cannot be undone.",
                    options: ["Delete Account", "Cancel"],
                    destructiveButtonIndex: 0,
                    cancelButtonIndex: 1,
                  },
                  async (buttonIndex) => {
                    if (buttonIndex === 0) {
                      Toast.alert(
                        {
                          title: `👋 Sorry to see you go, ${public_page.displayName}!`,
                          duration: 10,
                          description:
                            "Your account was flagged for deletion. This process may take up to two weeks to complete",
                        },
                        "danger"
                      );
                      await seek_actions.setFlagForSelfDelete(true);
                      await auth.signout();
                      seek_actions.onLogOut();
                    }
                  }
                );
              }}
              style={styles.replayIntroPressable}
            >
              <Text style={{ color: "white" }}>Delete Account</Text>
            </Pressable>
          )}
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

function PendingDeleteWarning(props) {
  return (
    <SafeAreaView
      source={require("../assets/bg-gradient-half.png")}
      style={styles.container}
    >
      <ScrollView>
        <View style={styles.titleWrapper}>
          <Text style={styles.titleText}>Profile & Account</Text>
        </View>
        <View>
          <Text style={{ color: "white", marginLeft: 25, marginTop: 25 }}>
            Sorry, your account is pending deletion.
          </Text>
        </View>
        <Pressable
          style={[
            styles.replayIntroPressable,
            { marginTop: 25, marginHorizontal: 25 },
          ]}
          onPress={() => props.seek_actions.setFlagForSelfDelete(false)}
        >
          <Text style={{ color: "white" }}>Recover Account</Text>
        </Pressable>
      </ScrollView>
    </SafeAreaView>
  );
}

function PublicPageSettingsWrap(props) {
  const auth = useAuth();

  const { profile, public_page, setEditPageSettingsMode } = props;
  // const initialScreenName = public_page.screenName;
  const initialScreenName = public_page.screenNameCased;
  const initialDisplayName =
    public_page.displayName || attempt_display_name(profile);
  const initialIsActivePublic = public_page.isActivePublic;
  const initialBio = public_page.bio || "";
  const initialTwitter = public_page.twitter || "";
  const initialSite = public_page.site || "";

  const onConfirm = async (edited_page) => {
    Toast.alert({
      title: `Profile Page ${public_page ? "Updated" : "Created"}!`,
    });

    props.seek_actions.setPublicUserPage({ ...public_page, ...edited_page });
  };
  const isAndroid = Platform.OS === "android";

  return (
    <View style={styles.signInContainer}>
      {!auth.user ? (
        <Text style={styles.signInText}>
          Sign in to save your lists, settings & more!
        </Text>
      ) : null}

      {!auth.user ? (
        <>
          {!isAndroid ? (
            <View style={styles.loginContainer}>
              <LoginAppleButton
                onPress={async () => {
                  if (Platform.OS === "ios") {
                    const success = await auth.signinAppleIos();
                    if (success) {
                      Toast.alert(
                        { title: "Signing In...", duration: 300 },
                        "info"
                      );
                    } else {
                      Toast.alert(
                        {
                          title: "There was a problem signing in",
                          description:
                            "Did you cancel? If not, please try again later",
                        },
                        "danger"
                      );
                    }
                  } else if (Platform.OS === "web") {
                    await auth.signinAppleWeb();
                  }
                }}
              />
            </View>
          ) : null}
          <View>
            {!isAndroid ? <Text style={styles.orText}>or</Text> : null}
          </View>
          <View style={styles.loginContainer}>
            <WrappedGoogleLoginButton auth={auth} />
          </View>
        </>
      ) : null}

      {auth.user ? (
        <PublicPageSettings
          isLoggedIn={!!profile}
          initialScreenName={initialScreenName}
          initialDisplayName={initialDisplayName}
          initialBio={initialBio}
          initialTwitter={initialTwitter}
          initialSite={initialSite}
          initialIsActivePublic={initialIsActivePublic}
          FH={FH}
          setEditPageSettingsMode={setEditPageSettingsMode}
          confirmButtonText={public_page ? "Update!" : "Create!"}
          onConfirm={onConfirm}
          public_page={public_page}
        />
      ) : null}
    </View>
  );
}

const { appOwnership } = Constants;

const CLIENT_ID_PAYLOAD =
  Platform.OS === "web"
    ? { webClientId: extra.GOOGLE_WEB_CLIENT_ID }
    : appOwnership !== "expo"
    ? {
        iosClientId: extra.GOOGLE_OAUTH_CLIENT_ID_IOS,
        androidClientId: extra.GOOGLE_OAUTH_CLIENT_ID_ANDROID_PROD,
        scopes: ["profile", "email"],
      }
    : {
        androidClientId: extra.GOOGLE_OAUTH_CLIENT_ID_ANDROID_GO,
        expoClientId: extra.GOOGLE_DEV_OAUTH_CLIENT_ID_EXPO_GO,
      };

function WrappedGoogleLoginButton(props) {
  const { auth } = props;

  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(
    CLIENT_ID_PAYLOAD
  );

  React.useEffect(() => {
    if (response?.type === "success") {
      // console.log("got response", response);
      const { id_token } = response.params;
      auth.signinGoogleIos(id_token);
    } else if (response) {
      if (Platform.OS === "android") {
        Toast.alert(
          {
            title: "Login Failed",
            duration: 30,
            description:
              "If there were two SeekingTV icons, please choose the one on the left ⬅️. Sorry!",
          },
          "danger"
        );
      }
      console.log("error code:", response?.errorCode);
      console.log("rtype", response?.type);
    }
  }, [response]);

  return (
    <LoginGoogleButton
      disabled={!request}
      onPress={async () => {
        promptAsync();
      }}
    />
  );
}

function StreamingServicesSettings(props) {
  return (
    <View style={styles.settingsContainer}>
      <View style={styles.settingsTitleContainer}>
        <Text style={styles.settingsTitleText}>Streaming Services</Text>
      </View>
      <View style={styles.servicesContainer}>
        {possible_services.map((service_json, i) => (
          <SingleService
            service_json={service_json}
            key={service_json.id}
            isLast={i + 1 === possible_services.length}
          />
        ))}
      </View>
    </View>
  );
}

function SingleService(props) {
  const seek_actions = useSeekStore((state) => state.seek_actions);

  const { service_json } = props;
  // service maze_id
  const s_m_id = service_json.id;

  const user_service = useSeekStore((state) => state.user_services[s_m_id]);

  const isEnabled = user_service && user_service["enabled"] === true;
  const onValueChange = (enabled) =>
    seek_actions.setUserService(s_m_id, enabled);
  return (
    <View
      style={{
        flexDirection: "row",
        padding: 16,
        paddingTop: 14,
        paddingBottom: 14,
        borderBottomWidth: props.isLast ? 0 : 1,
        borderBottomColor: colors.background,
        // marginHorizontal: 24,
        alignItems: "center",
        justifyContent: "space-between",
        // backgroundColor: colors.background_lighter,
        // borderRadius: 8,
      }}
    >
      <View style={{ flexDirection: "row" }}>
        <View style={styles.serviceIconWrapper}>
          <ServiceIcon serviceName={service_json.reelgood_id} />
        </View>
        <Text style={styles.singleServiceText}>{service_json.name}</Text>
      </View>
      <Switch
        trackColor={{ false: "#767577", true: "#00CC83" }}
        thumbColor={"#f4f3f4"}
        ios_backgroundColor="#3e3e3e"
        onValueChange={onValueChange}
        value={isEnabled}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: { backgroundColor: colors.background, flex: 1 },
  titleWrapper: {
    marginTop: 25,
    marginHorizontal: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  titleText: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
    textTransform: "capitalize",
  },

  shareEditContainer: { flexDirection: "row", position: "absolute", right: 0 },

  settingsContainer: {
    paddingTop: 24,
    marginTop: 32,
    marginHorizontal: 24,
    borderTopWidth: 2,
    borderTopColor: colors.background_lighter,
  },
  // settingsTitleContainer: { marginLeft: 24 },
  settingsTitleText: {
    color: colors.text_white,
    fontSize: 24,
    fontWeight: "bold",
  },
  servicesContainer: {
    backgroundColor: colors.background_lighter,
    marginTop: 32,
    borderRadius: 8,
  },
  serviceIconWrapper: {
    width: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  singleServiceText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 12,
  },

  signInContainer: {
    padding: 15,
    marginTop: 24,
    marginHorizontal: 24,
    borderWidth: StyleSheet.hairlineWidth,
    backgroundColor: colors.background_lighter,
    borderRadius: 8,
  },
  signInText: {
    color: "white",
    marginBottom: 15,
    fontStyle: "italic",
    textAlign: "center",
  },
  orText: {
    color: "white",
    // marginBottom: 15,
    fontStyle: "italic",
    textAlign: "center",
  },
  loginContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 15,
    marginBottom: 15,
  },
  contactUsPressable: {
    padding: 10,
    borderRadius: 3,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.main_purple,
    marginTop: 40,
  },
  boldContact: {
    fontWeight: "bold",
    color: colors.main_purple,
    textAlign: "center",
  },
  contactUsText: {
    color: colors.main_purple,
  },
  replayIntroPressable: {
    padding: 5,
    borderRadius: 3,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: colors.text_grey,
  },
  bottomContainer: { marginVertical: 24, alignItems: "center" },
  legal: {
    color: colors.text_grey,
    marginHorizontal: 20,
    fontSize: 10,
    textAlign: "center",
  },
});
