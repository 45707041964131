// https://github.com/pmndrs/use-asset#readme
import { createAsset } from "use-asset";

import Constants from "expo-constants";
// prettier-ignore
import { seeking_api_domain, storage, db, firebase, gfb_config } from "../../fire.js";

import { FSHelpers } from "../../app_shared/fs_helpers.js";
import assetCore from "../../app_shared/asset_core.js";

const FH = new FSHelpers(firebase);

const MAZE_API_DOMAIN = Constants.manifest.extra.MAZE_API_DOMAIN;
const MAZE_API_STATIC_DOMAIN = Constants.manifest.extra.MAZE_API_STATIC_DOMAIN;
const SEEKING_TV_API_DOMAIN = seeking_api_domain;
import { Image } from "react-native";

export default assetCore({
  FH,
  MAZE_API_DOMAIN,
  SEEKING_TV_API_DOMAIN,
  MAZE_API_STATIC_DOMAIN,
  gfb_config,
  Image,
  createAsset,
});

export function objectToParams(object) {
  // const params = new URLSearchParams();
  let qs = ``;
  Object.entries(object).map((entry) => {
    let [key, value] = entry;
    const pre = qs.length === 0 ? "" : "&";
    qs += `${pre}${key}=${encodeURIComponent(value)}`;
    // params.set(key, value);
  });
  return qs;
  // return params.toString();
}
