import React, { useState, useEffect, useRef, Suspense } from "react";
// prettier-ignore
import { StyleSheet, FlatList, Text, ScrollView, Pressable, Button, View, Image, TextInput, ImageBackground, Switch} from "react-native";

import relativeDate from "tiny-relative-date";

import AllAssets from "../helpers/assets.js";
const { apiShowAsset, publicPageAsset } = AllAssets;

import { FSHelpers } from "../../app_shared/fs_helpers.js";
import { firebase } from "../../fire.js";
import { PlaceHolderUserPhoto, UserPhotoComp } from "./UserImages.js";
import colors from "../colors.js";
import * as Icons from "./Icons.js";
import { STVRating } from "./CurrentUserRatingAndReview.js";
const FH = new FSHelpers(firebase);

import { KIND_TO_LABEL, get_verbage } from "../../app_shared/labels.js";

const KIND_TO_ICON = {
  "seen-liked": <Icons.HeartCircle size={25} />,
  "add-to-watchlist": <Icons.BookmarkCircle size={25} />,
  "seen-disliked": <Icons.ThumbsDownCircle size={25} />,
};

export function FeedListItem(props) {
  const show = apiShowAsset.read(props.item.tvmaze_id, "omit");
  const page = publicPageAsset.read(props.item.user_uid);

  return (
    <View style={styles.container}>
      <View style={styles.userPhotoContainer}>
        {page.userPhoto ? (
          <UserPhotoComp
            size={50}
            userPhoto={page.userPhoto}
            onPress={props.onPress}
          />
        ) : (
          <PlaceHolderUserPhoto size={25} onPress={props.onPress} />
        )}
        <View style={styles.activityIndicatorIconWrapper}>
          {KIND_TO_ICON[props.item.kind]}
        </View>
      </View>
      <View style={styles.showInfoContainer}>
        <Text style={styles.activityIndicatorText}>
          <Text onPress={props.onPress} style={styles.displayName}>
            {page.displayName || "Someone"}
          </Text>
          <Text onPress={props.onPress} style={styles.activityIndicatorText}>
            {" "}
            {get_verbage(props.item)}
          </Text>
          <Text style={styles.showNameText} onPress={props.onImagePress}>
            {" "}
            {show.name}
          </Text>
        </Text>
        <Text style={{ color: "rgb(150,150,150)" }} onPress={props.onPress}>
          {page.screenNameCased ? `@${page.screenNameCased}` : ``}
        </Text>
        {/* is there a rating ?*/}
        {typeof props.item.rating === "number" ? (
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <STVRating
              readonly={true}
              startingValue={props.item.rating}
              ratingColor={colors.text_white}
              imageSize={15}
            />
            <Text
              style={{ color: colors.text_grey, marginLeft: 5, fontSize: 13 }}
            >
              {props.item.rating}/5
            </Text>
          </View>
        ) : null}
        {/* is there a review ?*/}
        {props.item.review_head || props.item.review_body ? (
          <View style={{ marginTop: 4 }}>
            <Text
              style={{ color: colors.text_grey, fontSize: 15, marginTop: 4 }}
            >
              {props.item.review_head}
            </Text>
            <Text style={{ color: colors.text_grey, fontSize: 13 }}>
              {props.item.review_body}
            </Text>
          </View>
        ) : null}

        <Text style={styles.relativeDateText}>
          {relativeDate(props.item.ts.toDate())}
        </Text>
        {props.children}
      </View>
      <Pressable onPress={props.onImagePress}>
        {show.image && show.image.medium ? (
          <Image source={{ uri: show.image.medium }} style={styles.showImage} />
        ) : null}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    marginHorizontal: 24,
    marginBottom: 18,
    // padding: 8,
  },
  userPhotoContainer: { marginTop: 0 },
  activityIndicatorIconWrapper: {
    position: "absolute",
    right: -8,
    top: 35,
  },
  showImage: {
    width: 64,
    height: 97,
    borderRadius: 4,
    marginLeft: 12,
  },
  showInfoContainer: {
    flex: 1,
    marginLeft: 9,
    marginTop: 0,
  },
  activityIndicatorText: {
    color: colors.text_white,
    fontSize: 15,
  },
  displayName: {
    color: colors.text_white,
    fontSize: 15,
  },
  showNameText: {
    color: colors.main_purple,
    fontSize: 15,
  },
  relativeDateText: {
    color: colors.text_grey,
    fontSize: 13,
    marginTop: 5,
  },
  actionIndicatorContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: 25,
  },
});
