export function attempt_display_name(profile) {
  if (!profile) {
    return "";
  }
  if (profile.displayName) {
    return profile.displayName;
  }

  if (profile.fullName) {
    return `${profile.fullName.givenName || ""} ${
      profile.fullName.familyName || ""
    }`;
  }
  return "";
}

export function meets_sn_restricts(s) {
  if (s && s.length > 1 && s.length <= 32) {
    if (s.replace(/\W+/g, "").length === s.length) {
      return true;
    }
  }
  return false;
}

export function get_public_url_for_sn(screenName) {
  if (screenName) {
    const display = `seekingtv.com/u/${screenName}`;
    const actual = `https://seekingtv.com/u/${screenName}`;
    return { display, actual };
  }
}
