// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Share, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { BlurView } from "expo-blur";
import { useDebounce } from "./helpers/hooks.js";
import * as Toast from "./components/Toast.js";

import { AntDesign, Feather, Entypo } from "@expo/vector-icons";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import Popover from "react-native-popover-view";

import useSeekStore from "../app_shared/use-seek-store";

// import { IndicatorOverlay } from "./components/IndicatorOverlay";
// import { ActiveSwipeIndicators } from "./components/ActiveSwipeIndicators.js";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useIsFocused } from "@react-navigation/native";

import * as core_maze_logic from "./../app_shared/core_maze_logic.js";
// prettier-ignore
const { get_upcoming, maze_url_to_slug, maze_ep_to_slug, is_ep_within_specifier } = core_maze_logic;

import { PlayViaSourcesItem } from "./components/PlayViaSources.js";
import { BackButton } from "./components/BackButton.js";
import { ProgressiveImage } from "./components/ProgressiveImage.js";
import { RateAndReviewWidget } from "./components/CurrentUserRatingAndReview.js";
import { MaybeTrailerModal } from "./components/Trailer.js";
import { ActionMarkButtonsLarge } from "./components/ActionMarkButtonsLarge.js";
import { ShowScheduleInfo } from "./components/ShowScheduleInfo.js";
import { ShowCastInfo } from "./components/ShowCastInfo.js";
import { SummaryRatings } from "./components/SummaryRatings.js";
import { NotFoundView } from "./UserPageScreen.js";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
const FH = new FSHelpers(firebase);

import AllAssets from "./helpers/assets.js";
// prettier-ignore
const { apiShowAsset, apiShowCastAsset, mazeApiShowSlugAsset } = AllAssets;

import { remove_html_tags_bad } from "./helpers/utils.js";
import { seeking_api_domain } from "../fire.js";
import Constants from "expo-constants";
const MAZE_API_DOMAIN = Constants.manifest.extra.MAZE_API_DOMAIN;

import useLocalStore from "./stores/local_store";

import colors from "./colors.js";
import * as ButtonIcons from "./components/ButtonIcons.js";
import * as SDHelpers from "./helpers/formatting_helpers.js";

export default function EpisodeDetailScreen(props) {
  return (
    <Suspense fallback={<Fallback />}>
      <EpisodeDetailInner {...props} />
    </Suspense>
  );
}
function Fallback() {
  const navigation = useNavigation();
  const navigationHistory = useNavigationState((state) => state.history);
  const [vis, setVis] = useState(false);
  const visDebounced = useDebounce(vis, 8000);
  useEffect(() => {
    setVis(true);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.backPressable}>
        <BackButton />
      </View>
      <View style={{ marginTop: 150 }}>
        <ActivityIndicator color="white" size="large" />
      </View>
      {visDebounced ? (
        <Text
          onPress={
            navigationHistory.length === 1
              ? () => navigation.navigate("Swipe")
              : navigation.goBack
          }
          style={{
            color: "white",
            textAlign: "center",
            padding: 15,
            marginTop: 50,
          }}
        >
          It seems like something went wrong, go back?
        </Text>
      ) : null}
    </View>
  );
}

const seek_actions_selector = (state) => state.seek_actions;
const seen_episodes_selector = (state) => state.seen_episodes;
const user_marks_selector = (state) => state.user_marks;
const profile_selector = (state) => state.profile;
const user_services_selector = (state) => state.user_services;

export function EpisodeDetailInner(props) {
  const navigation = useNavigation();
  const navigationHistory = useNavigationState((state) => state.history);
  const { params } = props.route;
  const show_data = params.show_id
    ? apiShowAsset.read(params.show_id, true)
    : mazeApiShowSlugAsset.read(params.show_slug, true);

  const epIndex = show_data._embedded.episodes.findIndex(
    (a) => maze_ep_to_slug(a) === params.ep_slug
  );

  const episode = show_data._embedded.episodes[epIndex];
  const prev_ep = show_data._embedded.episodes[epIndex - 1];
  const next_ep = show_data._embedded.episodes[epIndex + 1];

  const genres = SDHelpers.getGenres(show_data, 3);
  const premiered_year = show_data.premiered
    ? show_data.premiered.split("-")[0]
    : ``;
  const seek_actions = useSeekStore(seek_actions_selector);
  const user_marks = useSeekStore(user_marks_selector);
  const seen_episodes = useSeekStore(seen_episodes_selector);
  const user_services = useSeekStore(user_services_selector);

  // console.log(seen_episodes);

  const favorite_artists = useSeekStore((state) => state.favorite_artists);
  const profile = useSeekStore(profile_selector);

  if (show_data.error) {
    return (
      <View style={styles.container}>
        <View style={styles.backPressable}>
          <BackButton />
        </View>
        <View style={styles.notFoundView}>
          <Text style={styles.notFoundText}> TV Show Not Found</Text>
          <Pressable
            onPress={
              navigationHistory.length === 1
                ? () => navigation.navigate("Swipe")
                : navigation.goBack
            }
            style={styles.backButton}
          >
            <AntDesign name="arrowleft" size={20} color="white" />
            <Text style={styles.backButtonText}>Back</Text>
          </Pressable>
        </View>
      </View>
    );
  }

  const airstamp = new Date(episode.airstamp);
  const label = airstamp > new Date() ? "Airs" : "Aired";
  const air_date = SDHelpers.date_helper(airstamp);

  const rg_episode = SDHelpers.findRgEpFromTvmaze(episode, show_data.deeplinks);
  // console.log(rg_episode);
  // console.log(extant);

  return (
    <View style={styles.container}>
      <View style={styles.backPressable}>
        <BackButton />
      </View>

      <ScrollView keyboardShouldPersistTaps={"handled"}>
        <View style={styles.pinUpPressable}>
          <ButtonIcons.ShareIcon
            size={35}
            fill="rgba(255,255,255, 0.7)"
            style={{ marginLeft: 8 }}
            onPress={async () => {
              const slug = maze_url_to_slug(show_data.url);
              // prettier-ignore
              const url = `https://www.seekingtv.com/tv/${slug}/${maze_ep_to_slug(episode)}`;
              console.log(url);
              const result = await Share.share({ url });
            }}
          />
        </View>
        <Animated.View style={[styles.showImageContainer, { height: 400 }]}>
          <ProgressiveImage
            thumbnailSourceUri={episode.image?.medium}
            sourceUri={episode.image?.original}
            resizeMode="cover"
            flex={1}
          />
          <LinearGradient
            colors={["transparent", colors.background]}
            style={styles.gradientBackground}
          />
          <View style={styles.showNameTextWrapper}>
            <Text
              style={styles.showNameText}
              onPress={() => {
                navigation.navigate("ShowDetail", {
                  show_id: props.route.params.show_id,
                });
              }}
            >
              {show_data.name}{" "}
              <Text style={styles.premiereYearText}>
                {" "}
                {maze_ep_to_slug(episode)}
              </Text>
            </Text>
          </View>
        </Animated.View>

        <View style={styles.showDetailsContainer}>
          <Text style={styles.premiereGenreText}>{episode.name}</Text>
        </View>
        <View style={styles.showDetailsContainer}>
          <Text style={styles.premiereGenreText}>
            Season {SDHelpers.getEpSeason(episode)} Episode{" "}
            {SDHelpers.getEpNumber(episode)}
          </Text>
        </View>

        <View style={styles.showSummaryWrapper}>
          <Text style={styles.showSummaryText}>
            {remove_html_tags_bad(episode.summary)}
          </Text>
          <Text style={[styles.showSummaryText, { marginTop: 8 }]}>
            {episode.airstamp ? `${label}: ${air_date}` : `Airs: TBA`}
          </Text>
        </View>
        <View
          style={{
            marginHorizontal: 24,
          }}
        >
          {rg_episode?.ep_sources ? (
            <PlayViaSourcesItem
              sources={rg_episode.ep_sources}
              user_services={user_services}
            />
          ) : (
            <View></View>
          )}
        </View>

        <View
          style={{
            marginHorizontal: 24,
            marginBottom: 24,
          }}
        >
          <EpisodeButtons
            rg_episode={rg_episode}
            show_data={show_data}
            episode={episode}
          />
        </View>

        <View style={[styles.showDetailsContainer, { marginBottom: 16 }]}>
          {prev_ep ? (
            <Pressable
              onPress={() => {
                navigation.navigate("EpisodeDetailScreen", {
                  show_id: props.route.params.show_id,
                  ep_slug: maze_ep_to_slug(prev_ep),
                });
              }}
            >
              <Text
                style={{
                  color: colors.main_purple,
                  fontSize: 15,
                  maxWidth: 150,
                  textAlign: "center",
                }}
              >
                <AntDesign
                  name="arrowleft"
                  size={17}
                  color={colors.main_purple}
                />
                {prev_ep.name}
              </Text>
            </Pressable>
          ) : (
            <View />
          )}
          {next_ep ? (
            <Pressable
              onPress={() => {
                navigation.navigate("EpisodeDetailScreen", {
                  show_id: props.route.params.show_id,
                  ep_slug: maze_ep_to_slug(next_ep),
                });
              }}
            >
              <Text
                style={{
                  color: colors.main_purple,
                  fontSize: 15,
                  maxWidth: 150,
                  textAlign: "center",
                }}
              >
                {next_ep.name}
                <AntDesign
                  name="arrowright"
                  size={17}
                  color={colors.main_purple}
                />
              </Text>
            </Pressable>
          ) : (
            <View />
          )}
        </View>
        <View
          style={[
            styles.showDetailsContainer,
            { marginBottom: 16, justifyContent: "center" },
          ]}
        >
          <Pressable
            onPress={() => {
              navigation.navigate("Episodes", {
                show_id: params.show_id,
              });
            }}
          >
            <Text
              style={{
                color: colors.main_purple,
                fontSize: 15,
                textAlign: "center",
              }}
            >
              All Episodes of {show_data.name}
            </Text>
          </Pressable>
        </View>

        <Suspense fallback={<ActivityIndicator color="white" />}>
          <ShowCastInfo
            show_id={props.route.params.show_id || show_data.id}
            seek_actions={seek_actions}
            favorite_artists={favorite_artists}
            user_uid={profile?.uid}
          />
        </Suspense>
      </ScrollView>
    </View>
  );
}

function EpisodeButtons(props) {
  const { show_data, episode, rg_episode } = props;
  // const { extant_ep, user_marks, show_data, episode, profile } = props;
  return (
    <>
      <SeenEpisodeButton {...props} />

      <MarkAsCaughtUpTil {...props} />
    </>
  );
}

function MarkAsCaughtUpTil(props) {
  // const { show_data, episode, rg_episode } = props;

  const { episode, show_data } = props;

  const profile = useSeekStore(profile_selector);
  const seek_actions = useSeekStore(seek_actions_selector);

  const seen_episodes = useSeekStore(seen_episodes_selector);
  // prettier-ignore
  const extant_ep = seen_episodes.find((m) => m.tvmaze_episode_id === episode?.id);
  const has_ep_spec_has_watched = extant_ep ? extant_ep.has_watched : false;

  const user_marks = useSeekStore(user_marks_selector);
  // todo find the usermark for the show if it exists
  // if it
  const extant_mark = user_marks.find((m) => m.tvmaze_id === show_data.id);
  // console.log(extant_mark);
  const via_caught_up = is_ep_within_specifier(
    episode,
    extant_mark?.caught_up_till
  );
  // console.log(via_caught_up);
  const disabled = !profile;

  const onPress = () => {
    if (!profile) {
      // prettier-ignore
      Toast.alert({title:"Please Login", description:"Sorry, you must login to mark episodes as seen"}, "danger");
      return;
    }
    const { season, number } = episode;
    const caught_up_till = { season, number };
    if (!extant_mark) {
      // just make it removed, should do the same for review
      console.log("DEAL WITH no mark case");
      seek_actions.setMark({
        tvmaze_id: show_data.id,
        kind: "removed",
        caught_up_till,
      });
    } else {
      console.log("existing mark case");
      seek_actions.setMark({ ...extant_mark, caught_up_till });
    }
  };

  if (via_caught_up || has_ep_spec_has_watched) {
    return <></>;
  }

  return (
    <Pressable style={styles.epButton} onPress={onPress}>
      <AntDesign name="checkcircleo" color={colors.aquamarine} size={16} />
      <Text style={{ color: colors.aquamarine, marginLeft: 4 }}>
        Mark All Seen to Here (Episode {maze_ep_to_slug(episode)})
      </Text>
    </Pressable>
  );
}

export function SeenEpisodeButton(props) {
  const { show_data, episode, rg_episode } = props;

  const seek_actions = useSeekStore(seek_actions_selector);
  const seen_episodes = useSeekStore(seen_episodes_selector);

  const tvmaze_show_id = show_data.id;

  const profile = useSeekStore(profile_selector);

  const user_marks = useSeekStore(user_marks_selector);
  //  find the usermark for the show if it exists
  const extant_mark = user_marks.find((m) => m.tvmaze_id === tvmaze_show_id);

  const via_caught_up = is_ep_within_specifier(
    episode,
    extant_mark?.caught_up_till
  );
  // console.log("via_caught_up", via_caught_up);
  const extant_ep = seen_episodes.find(
    (m) => m.tvmaze_episode_id === episode?.id
  );

  const has_watched =
    extant_ep?.has_watched === false
      ? false
      : extant_ep?.has_watched === true
      ? true
      : via_caught_up;

  // console.log({ has_watched, via_caught_up, extant_ep });
  // profile?.uid
  const [disabled, setDisabled] = useState(false);

  const onPress = async () => {
    if (!profile) {
      // prettier-ignore
      Toast.alert({title:"Please Login", description:"Sorry, you must login to mark episodes as seen"}, "danger");
      return;
    }
    if (disabled) {
      console.log("was already working");
      return;
    }
    setDisabled(true);
    // console.log("nnn", !has_watched);
    await seek_actions.setSeenEp(!has_watched, extant_ep?.id, {
      rg_episode_id: rg_episode?.episode_id || false,
      rg_show_id: rg_episode?.show_id || false,
      tvmaze_episode_id: episode.id,
      tvmaze_show_id: tvmaze_show_id,
    });
    setDisabled(false);
  };

  if (!episode) {
    return <></>;
  }

  const content = !has_watched ? (
    <Text style={{ color: colors.aquamarine, marginLeft: 4 }}>
      Mark Episode {maze_ep_to_slug(episode)} Seen
    </Text>
  ) : (
    <Text style={{ color: colors.aquamarine, marginLeft: 4 }}>
      Episode Marked Seen
    </Text>
  );

  return (
    <Pressable style={styles.epButton} disabled={disabled} onPress={onPress}>
      <AntDesign
        name={!has_watched ? "check" : "checkcircle"}
        color={colors.aquamarine}
        size={16}
      />
      {content}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  backPressable: {
    position: "absolute",
    top: 50,
    left: 24,
    zIndex: 5,
    padding: 5,
  },
  pinUpPressable: {
    position: "absolute",
    top: 50,
    right: 24,
    zIndex: 5,
    padding: 5,
    flexDirection: "row",
  },
  nonBlurredContent: {
    alignItems: "center",
    justifyContent: "center",
    zIndex: 4,
    width: 40,
    height: 40,
    borderRadius: 33,
  },
  // showImageContainer: { height: 500, marginTop: -25, width: "100%" },
  showImageContainer: { marginTop: -25, width: "100%" },
  gradientBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 125,
    zIndex: 4,
  },
  showNameTextWrapper: {
    position: "absolute",
    marginRight: 24,
    maxWidth: "100%",
    // bottom: 8,
    bottom: 0,
    left: 24,
    zIndex: 5,
  },
  showNameText: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
  },
  premiereYearText: {
    fontSize: 18,
    fontWeight: "400",
    color: colors.text_grey,
  },
  showDetailsContainer: {
    zIndex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 24,
    // backgroundColor: "red",
    alignItems: "center",
  },
  premiereGenreText: { fontSize: 16, color: colors.text_grey },
  showSummaryWrapper: {
    marginHorizontal: 24,
    marginTop: 16,
    paddingBottom: 16,
  },
  showSummaryText: { fontSize: 15, color: colors.text_grey },
  summaryGradientBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 125,
  },
  readMoreWrapper: {
    alignItems: "center",
  },
  trailerButton: {
    flexDirection: "row",
  },
  airWrap: {
    marginHorizontal: 24,
    marginVertical: 16,

    justifyContent: "space-between",
  },
  epButton: {
    padding: 16,
    backgroundColor: colors.background_lighter_alpha,
    borderRadius: 6,
    marginBottom: 8,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  trailerText: {
    marginLeft: 8,
    fontSize: 18,
    fontWeight: "bold",
    color: colors.text_white,
  },
  notFoundView: { flex: 1, alignItems: "center", justifyContent: "center" },
  notFoundText: { color: colors.text_white, fontSize: 24 },
});
