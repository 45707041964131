// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, FlatList, Text, SafeAreaView, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { Feather, AntDesign } from "@expo/vector-icons";

import useSeekStore from "../app_shared/use-seek-store";

import * as Toast from "./components/Toast.js";
import { PlayViaSourcesItem } from "./components/PlayViaSources.js";

import AuthSingleton from "../use-auth.js";
const { ProvideAuth, useAuth } = AuthSingleton;
import AllAssets from "./helpers/assets.js";
const { apiAllEpisodesAsset, apiShowAsset } = AllAssets;
import * as core_maze_logic from "./../app_shared/core_maze_logic.js";
// prettier-ignore
const { get_upcoming, maze_url_to_slug, maze_ep_to_slug, is_ep_within_specifier } = core_maze_logic;

import colors from "./colors.js";
import * as Icons from "./components/Icons.js";
import * as ButtonIcons from "./components/ButtonIcons";
import * as SDHelpers from "./helpers/formatting_helpers.js";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
const FH = new FSHelpers(firebase);

export default function EpisodesScreen(props) {
  const auth = useAuth();
  const show_data = apiShowAsset.read(props.route.params.show_id, true);
  const user_services = useSeekStore((state) => state.user_services);
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const profile = useSeekStore((state) => state.profile);
  const seen_episodes = useSeekStore((state) => state.seen_episodes).filter(
    (a) => a.tvmaze_show_id === show_data.id
  );
  // previously used  // const tvmaze_all_episodes = apiAllEpisodesAsset.read(show_data.id);
  // but we can just do this with include_episodes=true above
  const tvmaze_all_episodes = show_data._embedded.episodes;

  const premiered_year = show_data.premiered
    ? show_data.premiered.split("-")[0]
    : ``;
  const genres = SDHelpers.getGenres(show_data);
  const season_count = SDHelpers.getSeasonCount(show_data);
  const episode_count = SDHelpers.getEpisodeCount(show_data);

  // deal with a race condition
  // const is_loading_user_data = auth.user?.uid && !profile?.uid;

  return (
    <SafeAreaView style={styles.container}>
      <ButtonIcons.BackArrow
        style={styles.backButton}
        onPress={() => props.navigation.goBack()}
      />

      <Pressable
        hitSlop={10}
        style={styles.titleWrapper}
        onPress={() =>
          props.navigation.navigate("ShowDetail", {
            show_id: props.route.params.show_id,
          })
        }
      >
        <Text style={styles.titleText}>{show_data.name}</Text>
      </Pressable>
      <View style={{ marginHorizontal: 24 }}>
        <Text style={styles.seasonOverviewText}>
          {premiered_year}
          {genres && premiered_year ? "  |  " : ""}
          {genres}
        </Text>
        <Text style={styles.seasonOverviewText}>
          {season_count}{" "}
          {season_count === 1 ? "season" : season_count > 1000 ? "" : "seasons"}{" "}
          · {episode_count} {episode_count === 1 ? "episode" : "episodes"}
        </Text>
      </View>

      <FlatList
        data={tvmaze_all_episodes}
        ListEmptyComponent={
          <View style={styles.emptyListView}>
            <Text style={styles.searchForPersonText}>
              Sorry, no available episodes
            </Text>
          </View>
        }
        style={styles.flatList}
        keyboardShouldPersistTaps="handled"
        keyExtractor={(item) => item.id.toString()}
        renderItem={(e) => (
          <EpisodeItem
            {...e}
            user_services={user_services}
            seek_actions={seek_actions}
            seen_episodes={seen_episodes}
            tvmaze_show_id={show_data.id}
            tvmaze_all_episodes={tvmaze_all_episodes}
            deeplinks={show_data.deeplinks}
            navigation={props.navigation}
          />
        )}
      />
    </SafeAreaView>
  );
}

function EpisodeItem(props) {
  const {
    item,
    deeplinks,
    tvmaze_show_id,
    user_services,
    seek_actions,
    seen_episodes,
    navigation,
  } = props;

  const epSeason = SDHelpers.getEpSeason(item);
  const epNumber = SDHelpers.getEpNumber(item);
  const [disabled, setDisabled] = useState(false);
  // const rg_episode = SDHelpers.findRgEpFromTvmaze(item, deeplinks);

  const user_marks = useSeekStore((state) => state.user_marks);
  const extant_mark = user_marks.find((m) => m.tvmaze_id === tvmaze_show_id);

  const via_caught_up = is_ep_within_specifier(
    item,
    extant_mark?.caught_up_till
  );
  const extant_ep = seen_episodes.find((m) => m.tvmaze_episode_id === item.id);

  const has_watched =
    extant_ep?.has_watched === false
      ? false
      : extant_ep?.has_watched === true
      ? true
      : via_caught_up;
  return (
    <>
      <Pressable
        style={styles.episodeContainer}
        onPress={() =>
          navigation.navigate("EpisodeDetailScreen", {
            show_id: tvmaze_show_id,
            ep_slug: maze_ep_to_slug(item),
          })
        }
      >
        <View style={styles.epDetails}>
          <Text style={styles.epDescText}>
            S{epSeason} E{epNumber}{" "}
            {has_watched ? (
              <AntDesign name="check" color={colors.aquamarine} size={16} />
            ) : null}
          </Text>
          <Text style={styles.epNameText}>{SDHelpers.getEpName(item)}</Text>
        </View>
        {item.image ? (
          <Image
            source={{ uri: item.image.medium }}
            style={{ width: 100, height: 50 }}
          />
        ) : (
          <></>
        )}
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
  },
  backButton: {
    padding: 10,
    paddingTop: 13,
    paddingLeft: 24,
    width: 70,
    alignItems: "center",
    flexDirection: "row",
  },
  titleWrapper: {
    marginTop: 13,
    marginHorizontal: 24,
  },
  titleText: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
  },
  seasonOverviewText: {
    // fontSize: 13,
    color: colors.text_grey,
    // fontWeight: "bold",
    marginTop: 8,
  },
  flatList: {
    marginTop: 24,
    marginHorizontal: 24,
  },
  emptyListView: { marginTop: 100, alignItems: "center" },
  emptyText: {
    fontSize: 16,
    color: colors.text_white,
  },
  episodeContainer: {
    backgroundColor: colors.background_lighter,
    marginTop: 16,
    paddingHorizontal: 18,
    paddingVertical: 16,
    borderRadius: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkCircle: { position: "absolute", right: 10, top: 10 },
  epTitleText: { fontSize: 13, color: colors.text_grey },
  epDescText: {
    fontSize: 12,
    fontWeight: "bold",
    color: colors.main_purple,
  },
  epNameText: {
    marginTop: 5,
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "italic",
    color: colors.text_white,
  },

  epDateWrapper: { flexDirection: "row", marginTop: 8 },
  epDateText: { marginLeft: 3, fontSize: 13, color: colors.text_grey },

  epDetails: {
    maxWidth: 200,
  },
});
