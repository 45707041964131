import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Share, Text, ScrollView, Pressable, Button, View,  TextInput, Image, Switch} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useDebounce } from "../helpers/hooks.js";

import AllAssets from "../helpers/assets.js";

// prettier-ignore
import { attempt_display_name, meets_sn_restricts, get_public_url_for_sn } from "../helpers/name_helpers.js";
import * as ImagePicker from "expo-image-picker";
// slightly breaking my rule using this down here, but it's read only
// import { firebase } from "../../fire.js";
// import { FSHelpers } from "../../app_shared/fs_helpers.js";
// const FH = new FSHelpers(firebase);
import { PlaceHolderUserPhoto, UserPhotoComp } from "./UserImages.js";

import colors from "../colors.js";

import Clipboard from "expo-clipboard";

async function attempt_get_photo_from_lib() {
  const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
  if (status !== "granted") {
    alert("Sorry, we need camera roll permissions to make this work!");
  }
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 3],
    quality: 0.25,
    // base64: true,
  });
  return result;
}

export function SummaryForOwnPage(props) {
  const navigation = useNavigation();
  const { userPhoto } = props.public_page;
  // console.log("SummaryForOwnPage comp render");
  // console.log("userPhoto:", userPhoto);
  const pickPhoto = async () => {
    const result = await attempt_get_photo_from_lib();
    if (!result.cancelled) {
      // console.log(Object.keys(result));  console.log("b64 len:", result.base64.length);  console.log(result.base64.slice(0, 100));  console.log(result.uri);
      const ext = result.uri.split(".").pop();
      // evidently whitespace is a problem ?! https://forums.expo.io/t/blob-stopped-working-in-expo-sdk-39/43516/18
      // const base64 = result.base64.replace(/\s/g, "");
      props.onImagePick({ uri: result.uri, ext });
    }
  };
  return (
    <Suspense
      fallback={
        <ActivityIndicator
          size="large"
          color="white"
          style={styles.activityIndicator}
        />
      }
    >
      <View style={styles.userInfoContainer}>
        {userPhoto ? (
          <UserPhotoComp size={64} userPhoto={userPhoto} onPress={pickPhoto} />
        ) : (
          <PlaceHolderUserPhoto size={32} onPress={pickPhoto} />
        )}
        <View style={styles.userInfoContainerInner}>
          <View style={styles.displayNameContainer}>
            <Text style={styles.displayNameText}>
              {props.public_page.displayName}
            </Text>
          </View>
          <View style={styles.screenNameContainer}>
            <Text style={styles.screenNameText}>
              @{props.public_page.screenNameCased}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.folFolContainer}>
        <Pressable
          onPress={() => {
            navigation.navigate("Connections", {
              whichList: "followers",
              uid: props.public_page.uid,
            });
          }}
        >
          <View style={styles.folFolWrapper}>
            <Text style={styles.folFolText}>{props.followers.length}</Text>
            <Text style={styles.folFolLabel}>
              {props.followers.length === 1 ? "Follower" : "Followers"}
            </Text>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            navigation.navigate("Connections", {
              whichList: "following",
              uid: props.public_page.uid,
            });
          }}
        >
          <View style={styles.folFolWrapper}>
            <Text style={styles.folFolText}>{props.following.length}</Text>
            <Text style={styles.folFolLabel}>Following</Text>
          </View>
        </Pressable>
        <Pressable
          onPress={() => {
            navigation.navigate("FavoriteArtists", {
              uid: props.public_page.uid,
            });
          }}
        >
          <View style={styles.folFolWrapper}>
            <Text style={styles.folFolText}>
              {props.favorite_artists.length}
            </Text>
            <Text style={styles.folFolLabel}>
              {props.favorite_artists.length === 1
                ? "Fav. Artist"
                : "Fav. Artists"}
            </Text>
          </View>
        </Pressable>
      </View>
    </Suspense>
  );
}

const styles = StyleSheet.create({
  activityIndicator: { marginTop: 200 },
  userInfoContainer: {
    flexDirection: "row",
    marginHorizontal: 24,
    marginTop: 29,
  },
  userInfoContainerInner: {
    marginLeft: 16,
  },
  displayNameContainer: {
    flexDirection: "row",
  },
  displayNameText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
  },
  screenNameContainer: {
    flexDirection: "row",
    marginTop: 8.5,
  },
  screenNameText: { fontSize: 14, color: colors.text_grey },
  folFolContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginHorizontal: 24,
    // marginTop: 24,
    // borderTopWidth: 2,
    // borderTopColor: colors.background_lighter,
  },
  folFolWrapper: {
    alignItems: "center",
    marginTop: 29,
    paddingHorizontal: 15,
  },
  folFolText: { color: colors.text_grey, fontWeight: "bold" },
  folFolLabel: { color: colors.main_purple },
});
