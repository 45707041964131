export class Debouncer {
  constructor() {
    this.lookup = {};
  }
  cancel(identifier) {
    let timer_id = this.lookup[identifier];
    if (timer_id) {
      clearTimeout(timer_id);
      delete this.lookup[timer_id];
    }
  }
  set(identifier, ms, cb) {
    this.cancel(identifier);
    let new_timer_id = setTimeout(cb, ms);
    this.lookup[identifier] = new_timer_id;
  }
}

// prettier-ignore
export const chunkArray = (a,n)=>[...Array(Math.ceil(a.length/n))].map((_,i)=>a.slice(n*i,n+n*i));

export function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

// for descriptions not a very good impl, there is no good one
export function remove_html_tags_bad(s) {
  if (!s) {
    return s;
  }
  return s.replace(/<[^>]*>?/gm, "").replace(/&amp;/g, "&");
}

// https://stackoverflow.com/a/46431916/83859
export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

// https://stackoverflow.com/a/2117523
export function uuidv4ish() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
