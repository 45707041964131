import React, { Suspense, useRef, useState } from "react";
import { AntDesign } from "@expo/vector-icons";

// prettier-ignore
import { Dimensions, Platform, ActivityIndicator, Modal, Pressable, ImageBackground, StyleSheet, View, Text } from "react-native";

import AllAssets from "../helpers/assets.js";
const { ytSearchQueryAsset } = AllAssets;

import { WebView } from "react-native-webview";

export function MaybeTrailerModal(props) {
  const { show_data } = props;
  if (!props.trailerMode) {
    return <></>;
  }

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.trailerMode}
      onRequestClose={() => {
        // Alert.alert("Modal has been closed.");
      }}
    >
      <View style={styles.modalContainer}>
        <View style={inner_modal_wrap_style}>
          <Suspense
            fallback={
              <ActivityIndicator
                color="white"
                size="large"
                style={styles.activityIndicator}
              />
            }
          >
            <TrailerModalInner show_data={show_data} />
          </Suspense>
          <Pressable
            style={styles.closeButton}
            onPress={() => {
              props.setTrailerMode(false);
            }}
          >
            <AntDesign name="close" size={25} color="rgb(230,230,230)" />

            <Text style={styles.closeButtonText}>Close</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  );
  // if (Platform.OS == "web") {
  //   return <Text style={{ color: "green" }}>Trailer</Text>;
  // }
}

function TrailerModalInner({ show_data }) {
  // prettier-ignore
  const yt_res = ytSearchQueryAsset.read(`${show_data.name} season 1 trailer`, show_data.id)
  // console.log();
  if (!yt_res.items.length) {
    return <Text style={styles.modalInnerText}>No Trailer Found, Sorry!</Text>;
  }
  const vid_id = yt_res["items"][0]["videoId"];
  const uri = `https://www.youtube.com/embed/${vid_id}?&autoplay=1&modestbranding=1&autohide=1&showinfo=0&controls=0`;
  return (
    <>
      <WebView
        source={{ uri }}
        style={styles.webView}
        mediaPlaybackRequiresUserAction={
          Platform.OS !== "android" ? false : undefined
        }
      />
    </>
  );
}
// autoplay
// userAgent="Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/77.0.3865.90 Safari/537.36"

// https://github.com/react-native-webview/react-native-webview/issues/859#issuecomment-602448804

const inner_modal_wrap_style = {
  // margin: 20,
  backgroundColor: "rgba(0,0,0,0.8)",
  borderRadius: 20,
  // height: "80%",
  height: "100%",
  width: "100%",
  // width: "95%",
  // width: "80%",
  // just hide the damn thing in web because it doesn't work
  // display: Platform.OS === "web" ? "none" : "flex",
  display: "flex",
  // paddingTop: 35,
  paddingBottom: 35,
  // alignItems: "center",
  shadowColor: "#fff",
  // shadowColor: "#000",
  shadowOffset: {
    width: 0,
    height: 2,
  },
  shadowOpacity: 0.25,
  shadowRadius: 3.84,
  // elevation: 5,
};

const styles = StyleSheet.create({
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  activityIndicator: { marginTop: 100, marginBottom: 35 },
  closeButton: {
    marginTop: 20,
    flexDirection: "row",
    backgroundColor: "rgba(204, 0, 0, 0.8)",
    borderWidth: 1,
    padding: 8,
    justifyContent: "center",
  },
  closeButtonText: { color: "white", fontSize: 22, marginLeft: 5 },
  modalInnerText: { color: "white", fontSize: 18 },
  webView: { marginTop: 10 },
});
