import React, { useState, useEffect } from "react";
// prettier-ignore
import { StyleSheet, Text, ScrollView, Pressable, Button, View,  TextInput, ImageBackground, Switch} from "react-native";
import { useDebounce } from "../helpers/hooks.js";
// prettier-ignore
import { attempt_display_name, meets_sn_restricts, get_public_url_for_sn } from "../helpers/name_helpers.js";
import { AntDesign } from "@expo/vector-icons";
import colors from "../colors.js";

// prettier-ignore
const BLOCKED_PAGE_NAMES = ["legal", "company", "pages", "tv", "team", "contact", "help", "seek", "seeking", "movies", "tos", "privacy", "staff", "admin", "support", "administrator", "about", "faq", "pages", "blog", "posts", "video", "stream", "artist", "actor", "production", "services", "blog"]

export function PublicPageSettings(props) {
  const { FH, isLoggedIn, setEditPageSettingsMode } = props;

  const [screenName, setScreenName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [bio, setBio] = useState("");
  const [twitter, setTwitter] = useState("");
  const [site, setSite] = useState("");
  // a now unused flag we could use to let people turn off their public profile but keep their account - but it's set to false for everyone
  const [isActivePublic, setIsActivePublic] = useState(false);
  // this is just so they update when we log-in
  // prettier-ignore
  useEffect(() => {
    setDisplayName(props.initialDisplayName|| "");
    setScreenName(props.initialScreenName || "");
    setBio(props.initialBio || "");
    setTwitter(props.initialTwitter || "");
    setSite(props.initialSite || "");
    setIsActivePublic(props.initialIsActivePublic || false);
  }, [ props.initialDisplayName, props.initialScreenName, props.initialIsActivePublic, props.initialBio,  props.initialTwitter, props.initialSite ]);

  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState(false);
  const debouncedScreenName = useDebounce(screenName, 500);
  useEffect(() => {
    if (meets_sn_restricts(debouncedScreenName)) {
      setIsSearching(true);
      FH.queryPublicUserPages(debouncedScreenName).then((results) => {
        setIsSearching(false);
        setSearchResults(results);
      });
    } else {
      setSearchResults(false);
    }
  }, [debouncedScreenName]);

  // prettier-ignore
  const has_changed = props.initialDisplayName !== displayName || props.initialIsActivePublic !== isActivePublic || props.initialScreenName !== screenName || props.initialBio !== bio  || props.initialTwitter !== twitter || props.initialSite !== site
  const isCurrentSN =
    debouncedScreenName?.toLowerCase() ===
    props?.initialScreenName?.toLowerCase();
  const sn_not_taken_or_is_mine =
    searchResults &&
    (searchResults.length === 0 || isCurrentSN) &&
    !BLOCKED_PAGE_NAMES.includes(debouncedScreenName);
  // everything above should wrap a dumb inner comp.
  // that could be reused for edit and delete AND no profile state
  const can_confirm =
    props.isLoggedIn &&
    meets_sn_restricts(debouncedScreenName) &&
    sn_not_taken_or_is_mine &&
    has_changed;

  return (
    <View>
      <Text style={styles.publicProfileText}>
        Public Profile{!props.public_page ? " Setup" : ""}
      </Text>
      {props.public_page ? (
        <Pressable
          onPress={() => setEditPageSettingsMode(false)}
          style={styles.closeButton}
        >
          <AntDesign name="close" color="white" size={16} />
        </Pressable>
      ) : null}
      <View style={styles.screenNameContainer}>
        <Text style={styles.screenNameText}>Screen Name</Text>
        <TextInput
          maxLength={100}
          editable={isLoggedIn}
          onChangeText={setScreenName}
          value={screenName}
          autoCompleteType="off"
          placeholder={
            !isLoggedIn ? "(Please Sign In First)" : "A unique screen name"
          }
          placeholderTextColor={colors.text_grey}
          style={styles.nameInput}
        />
      </View>
      <SNValidator
        searchResults={searchResults}
        debouncedScreenName={debouncedScreenName}
        screenNameValid={meets_sn_restricts(debouncedScreenName)}
        isCurrentSN={isCurrentSN}
        sn_not_taken_or_is_mine={sn_not_taken_or_is_mine}
      />

      <View style={styles.displayNameContainer}>
        <Text style={styles.displayNameText}>Display Name</Text>
        <TextInput
          editable={isLoggedIn}
          maxLength={255}
          value={displayName}
          onChangeText={setDisplayName}
          placeholder={
            !isLoggedIn ? "(Please Sign In First)" : "A name people know you by"
          }
          autoCompleteType="off"
          placeholderTextColor={colors.text_grey}
          style={styles.nameInput}
        />
      </View>
      <View style={styles.bioContainer}>
        <Text style={styles.displayNameText}>Bio</Text>
        <TextInput
          editable={isLoggedIn}
          value={bio}
          maxLength={255}
          onChangeText={setBio}
          placeholder={!isLoggedIn ? "" : "Tell us about yourself (optional)"}
          autoCompleteType="off"
          placeholderTextColor={colors.text_grey}
          style={styles.nameInput}
        />
      </View>
      <View style={styles.createProfileContainer}>
        {!props.public_page ? (
          <Text style={styles.createProfileText}>
            Create a public profile to let everyone see your picks!
          </Text>
        ) : (
          <Text></Text>
        )}
        {/* prev had isActivePublic switch here */}

        <Pressable
          onPress={() => {
            props.onConfirm({
              screenName,
              displayName: displayName.trim(),
              isActivePublic,
              bio,
              twitter,
              site,
            });
            setEditPageSettingsMode(false);
          }}
          style={{
            // backgroundColor: colors.aquamarine,
            padding: 10,
            borderRadius: 59,
            borderWidth: 2,
            borderColor: colors.aquamarine,
            opacity: can_confirm ? 1 : 0.2,
            flex: 0.5,
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
          disabled={!can_confirm}
        >
          <Text style={styles.confirmButtonText}>
            {props.confirmButtonText}
          </Text>
        </Pressable>
      </View>
    </View>
  );
}

function SNValidator(props) {
  // prettier-ignore
  const { searchResults, debouncedScreenName, screenNameValid, isCurrentSN } = props
  const v_style = {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 16,
  };
  const standard_message = "Letters, numbers, & underscores only";

  // base case
  if (!searchResults && !debouncedScreenName) {
    const textColor = "rgb(200,200,200)";
    return (
      <View style={v_style}>
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 12,
          }}
        >
          {standard_message}
        </Text>
      </View>
    );
  }
  if (isCurrentSN) {
    const textColor = "rgb(200,200,200)";

    return (
      <View style={v_style}>
        <AntDesign name="user" size={16} color={textColor} />
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 14,
          }}
        >
          {" "}
          This is your current screen name.
        </Text>
      </View>
    );
  }

  if (!searchResults && screenNameValid) {
    const textColor = "rgb(200,200,200)";
    return (
      <View style={v_style}>
        <AntDesign name="loading1" size={16} color={textColor} />
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 14,
          }}
        >
          Searching...
        </Text>
      </View>
    );
  }
  if (
    !searchResults &&
    debouncedScreenName &&
    !meets_sn_restricts(debouncedScreenName)
  ) {
    const textColor = "rgb(255,200,200)";
    return (
      <View style={v_style}>
        <AntDesign name="exclamationcircle" size={16} color="rgb(230,80,90)" />
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 14,
          }}
        >
          {standard_message}
        </Text>
      </View>
    );
  }
  if (props.sn_not_taken_or_is_mine) {
    const textColor = "rgb(200,200,200)";
    return (
      <View style={v_style}>
        <AntDesign name="checkcircle" size={16} color="rgb(30,230,130)" />
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 14,
          }}
        >
          Screen Name Available!
        </Text>
      </View>
    );
  } else {
    const textColor = "rgb(200,200,200)";
    return (
      <View style={v_style}>
        <AntDesign name="exclamationcircle" size={16} color="rgb(230,80,90)" />
        <Text
          style={{
            color: textColor,
            textAlign: "right",
            marginLeft: 4,
            fontSize: 14,
          }}
        >
          Sorry, someone already has that screen name.
        </Text>
      </View>
    );
  }

  return <></>;
}

const styles = StyleSheet.create({
  publicProfileText: {
    color: "white",
    textAlign: "center",
    fontSize: 16,
    marginBottom: 12,
  },
  closeButton: { position: "absolute", right: -13, top: -13, padding: 10 },
  screenNameContainer: {
    // alignItems: "center",
    // flexDirection: "row",
    marginTop: 4,
    justifyContent: "center",
    marginBottom: 4,
  },
  screenNameText: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
    // textAlign: "center",
  },
  nameInput: {
    backgroundColor: colors.background_lighter,
    marginTop: 10,
    marginBottom: 4,
    padding: 10,
    borderColor: colors.main_purple,
    // borderTopColor: colors.background_lighter,
    borderWidth: 1,
    borderRadius: 59,
    flex: 1,
    color: "rgb(255,255,255)",
  },
  displayNameContainer: {
    justifyContent: "center",
  },
  bioContainer: {
    marginTop: 8,
    justifyContent: "center",
  },
  displayNameText: {
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
    // textAlign: "center",
  },
  displayNameInput: {
    backgroundColor: colors.background_lighter,
    marginTop: 10,
    marginBottom: 4,
    padding: 10,
    borderColor: colors.main_purple,
    borderWidth: 1,
    borderRadius: 59,
    flex: 1,
    color: "rgb(255,255,255)",
  },
  createProfileContainer: {
    marginTop: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flex: 1,
  },
  createProfileText: {
    color: "white",
    marginRight: 4,
    flex: 1,
  },
  confirmButtonText: {
    color: colors.aquamarine,
    fontWeight: "bold",
  },
});
