import Constants from "expo-constants";

// import * as GoogleSignIn from "expo-google-sign-in";
import * as AppleAuthentication from "expo-apple-authentication";
import * as Crypto from "expo-crypto";
import { signInWithCredential, OAuthProvider } from "firebase/auth";

import { firebase } from "./fire";
import React, { useState, useEffect, useContext, createContext } from "react";

import { setupGfbHook } from "./app_shared/use-gfb-auth.js";

export default setupGfbHook({
  useState,
  useEffect,
  useContext,
  createContext,
  React,
  firebase,
  do_native_apple_auth_signin,
});

// helper for the below
async function generate_crypto_for_apple_native() {
  const SHA256 = Crypto.CryptoDigestAlgorithm.SHA256;
  const csrf = Math.random().toString(36).substring(2, 15);
  const rawNonce = Math.random().toString(36).substring(2, 10);
  const hashedNonce = await Crypto.digestStringAsync(SHA256, rawNonce);
  return { csrf, hashedNonce, rawNonce };
}

// returns {uid, email, fullName };
async function do_native_apple_auth_signin(auth) {
  try {
    const crypto_tokens = await generate_crypto_for_apple_native();
    const appleCredential = await AppleAuthentication.signInAsync({
      requestedScopes: [
        AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
        AppleAuthentication.AppleAuthenticationScope.EMAIL,
      ],
      state: crypto_tokens.csrf,
      nonce: crypto_tokens.hashedNonce,
    });
    // signed in
    // console.log("signed in!");
    const { identityToken, state, email, fullName } = appleCredential;
    // console.log(appleCredential);
    // console.log(identityToken, email, state);
    const provider = new OAuthProvider("apple.com");
    const credential = provider.credential({
      idToken: identityToken,
      rawNonce: crypto_tokens.rawNonce, // nonce value from above
    });
    const firebaseCredential = await signInWithCredential(auth, credential);
    // console.log(firebaseCredential);
    const { uid } = firebaseCredential.user;
    return { uid, email, fullName };
  } catch (e) {
    if (e.code === "ERR_CANCELED") {
      console.log("error: user canceled");
      // handle that the user canceled the sign-in flow
    } else {
      console.log("error: other login error");
      console.log(e);
      // handle other errors
    }
  }
}
