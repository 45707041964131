import Constants from "expo-constants";
import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect, Suspense, useCallback } from "react";
// prettier-ignore
import { StyleSheet, Text, View, ImageBackground, Button, ActivityIndicator, LogBox } from "react-native";
import { Platform } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import * as ScreenOrientation from "expo-screen-orientation";
import { NotifierWrapper } from "react-native-notifier";
import * as Toast from "./src/components/Toast.js";

// this silences a warning we don't really care about for android builds - see https://github.com/facebook/react-native/issues/12981

if (Platform.OS !== "web") {
  LogBox.ignoreLogs(["Setting a timer"]);
}

import * as Google from "expo-auth-session/providers/google";

import { useFonts, Oswald_400Regular } from "@expo-google-fonts/oswald";

import { Roboto_400Regular } from "@expo-google-fonts/roboto";

import * as Icons from "./src/components/Icons.js";
import colors from "./src/colors.js";

import { useInterval } from "./src/helpers/hooks.js";

import AuthSingleton from "./use-auth.js";
const { ProvideAuth, useAuth } = AuthSingleton;

import { NavigationContainer } from "@react-navigation/native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
const TabNav = createBottomTabNavigator();

import AnimatedSplash from "react-native-animated-splash-screen";
import { firebase } from "./fire";
import * as Updates from "expo-updates";
import * as Sentry from "sentry-expo";
import * as Linking from "expo-linking";

// prettier-ignore
Sentry.init({
  dsn:
    "https://356cf0bd440342eb944520cecf414408@o362268.ingest.sentry.io/5607039",
  enableInExpoDevelopment: false,
  debug: false, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
});

import useSeekStore from "./app_shared/use-seek-store";
import useLocalStore from "./src/stores/local_store";

// const ErrorBoundary = Platform.OS !== "web" ? Sentry.Native.ErrorBoundary : View;

import { ErrorBoundary } from "./src/components/ErrorBoundary";

import { TabIcon, ICON_SCREEN_MAP } from "./src/components/BottomTabHelpers.js";

import FeedScreen from "./src/FeedScreen";

import ProfileScreen from "./src/ProfileScreen";
import SwipeScreen from "./src/SwipeScreen";
import WatchListScreen from "./src/WatchListScreen";
import UserPageScreen from "./src/UserPageScreen";
import ConnectionsScreen from "./src/ConnectionsScreen";
import FavoriteArtistsScreen from "./src/FavoriteArtistsScreen";
import ScheduleScreen from "./src/ScheduleScreen";
import ArtistDetailScreen from "./src/ArtistDetailScreen";
import ShowDetailScreen from "./src/ShowDetailScreen";
import EpisodeDetailScreen from "./src/EpisodeDetailScreen";
import EpisodesScreen from "./src/EpisodesScreen";
import IntroductionScreen from "./src/IntroductionScreen";

// XXX If you change the DEV_ROUTE here, please don't commit those changes in git
const DEV_ROUTE = "Swipe";

// XXX the ACTUAL default is Swipe in prod.  but in dev it's useful to vary this
const initialRouteName = __DEV__ ? DEV_ROUTE : "Swipe";

//  https://reactnavigation.org/docs/configuring-links/
// https://reactnavigation.org/docs/deep-linking/#universal-links
// to test
// npx uri-scheme open exp://127.0.0.1:19000/--/tv/loki --ios
const config = {
  screens: {
    UserPage: "u/:screenName",
    Profile: "profile",
    Feed: "home",
    Swipe: "swipe",
    ShowDetail: "tv/:show_slug",
    WatchList: "lists/watch",
  },
};

const prefix = Linking.createURL("/");

// XXX  this disables linking for web version, mostly for dev
const linking =
  Platform.OS !== "web"
    ? {
        prefixes: [
          prefix,
          "https://seekingtv.com",
          "https://*.seekingtv.com",
          "http://seekingtv.com",
          "http://*.seekingtv.com",
        ],
        config,
      }
    : false;

const seen_intro_sel = (state) => state.user_data["seen_intro_v1"];
const is_loaded_sel = (state) => state.isLoaded;
const last_q_key_sel = (state) => state.user_data["last_swipe_screen_q_key"];
const last_feed_key_sel = (state) => state.user_data["last_feed_screen_key"];
const last_list_kind_sel = (state) => state.user_data["last_list_screen_kind"];

function AppIndex(props) {
  // prettier-ignore
  const seen_intro = useLocalStore(seen_intro_sel);
  const lsIsLoaded = useLocalStore(is_loaded_sel);
  const lastSwipeQueueKey = useLocalStore(last_q_key_sel) || "all";
  const lastFeedKey = useLocalStore(last_feed_key_sel) || "all";
  const lastListKind = useLocalStore(last_list_kind_sel) || "add-to-watchlist";
  // this should load have loaded already, but just in case
  if (!lsIsLoaded) {
    return <></>;
  }

  return (
    <ActionSheetProvider>
      <ErrorBoundary fallback={error_fallback} style={{ flex: 1 }}>
        <StatusBar style="auto" translucent={true} />

        <Suspense fallback={sus_fallback}>
          {/* IntoductionScreen not a propper "screen", but putting the intro screen here so the rest can load underneath in the initial load*/}
          {!lsIsLoaded || seen_intro ? null : <IntroductionScreen />}
          <NavigationContainer linking={linking}>
            <TabNav.Navigator
              tabBarOptions={getTabBarOptions()}
              screenOptions={getScreenOptions()}
              initialRouteName={initialRouteName}
              backBehavior="history"
            >
              <TabNav.Screen
                name="WatchList"
                component={WatchListScreen}
                initialParams={{ kind: lastListKind }}
              />
              <TabNav.Screen
                name="Feed"
                component={FeedScreen}
                initialParams={{ key: lastFeedKey }}
              />

              <TabNav.Screen
                name="Swipe"
                component={SwipeScreen}
                initialParams={{ queue_key: lastSwipeQueueKey }}
              />
              <TabNav.Screen name="Schedule" component={ScheduleScreen} />
              <TabNav.Screen name="Profile" component={ProfileScreen} />
              <TabNav.Screen
                name="UserPage"
                component={UserPageScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="Connections"
                component={ConnectionsScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="FavoriteArtists"
                component={FavoriteArtistsScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="EpisodeDetailScreen"
                component={EpisodeDetailScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="ShowDetail"
                component={ShowDetailScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="ArtistDetailScreen"
                component={ArtistDetailScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
              <TabNav.Screen
                name="Episodes"
                component={EpisodesScreen}
                options={{
                  tabBarButton: () => null,
                }}
              />
            </TabNav.Navigator>
          </NavigationContainer>
        </Suspense>
      </ErrorBoundary>
    </ActionSheetProvider>
  );
}

// hide a single tab button: https://github.com/react-navigation/react-navigation/issues/4069#issuecomment-643193483
// how to hide one screen from the tabs
/*<TabNav.Screen
  name="SomeScreenName"
  component={SomeScreenComp}
  options={{
    tabBarButton: () => null,
    // tabBarVisible: false, // if you don't want to see the tab bar
  }}
/>*/

const profile_selector = (state) => state.profile;
const seek_actions_selector = (state) => state.seek_actions;

function AppAuthWrap(props) {
  const auth = useAuth();
  const profile = useSeekStore(profile_selector);
  const seek_actions = useSeekStore(seek_actions_selector);

  useEffect(() => {
    if (auth.user?.uid) {
      // console.log("auth user uid change!", auth.user?.uid);
      Toast.alert(
        {
          title: "Signing In...",
          description: "Loading your profile as well...",
          duration: 300,
        },
        "info"
      );
      seek_actions.onAuth(auth.user.uid);
    }
  }, [auth.user?.uid]);

  useEffect(() => {
    if (profile?.uid) {
      Toast.clear();
      Toast.alert(
        {
          title: "👍 Signed In!",
          description: "Your profile was loaded successfully!",
          duration: 2,
        },
        "success"
      );
    }
  }, [profile?.uid]);

  // this will check for updates once a day
  useInterval(() => {
    console.log("poll for update");
    attempt_update();
  }, one_day_in_ms);
  return <AppIndex />;
}

const one_day_in_ms = 8.64e7;

// XXX the actual export!
export default function AppLoadWrap() {
  // const [preloadDone, setPreloadDone] = useState(false);

  // prettier-ignore
  const local_store_actions = useLocalStore((state) => state.local_store_actions);
  local_store_actions.init();
  // seek_actions.init(authUser);
  const [fontsLoaded, error] = useFonts({
    Oswald_400Regular,
    Roboto_400Regular,
  });
  // console.log({ fontsLoaded, error });
  // console.log("appLW", authUser?.uid);
  bootstrap_and_check_updates();

  return (
    <AnimatedSplash
      translucent={true}
      isLoaded={fontsLoaded}
      backgroundColor={"#4b50ce"}
      logoImage={require("./assets/logo_white.png")}
      logoHeight={192}
      logoWidth={192}
    >
      <ProvideAuth>
        <NotifierWrapper>
          {fontsLoaded ? <AppAuthWrap /> : null}
        </NotifierWrapper>
      </ProvideAuth>
    </AnimatedSplash>
  );
}

// stolen from proto
function getTabBarOptions() {
  return {
    activeBackgroundColor: "black",
    // inactiveBackgroundColor: "yellow",
    inactiveBackgroundColor: "black",
    activeTintColor: colors.aquamarine,
    inactiveTintColor: colors.main_purple,
    // XXX this is hardcoded at 60 to prevent issues in the proto, may be not nec with useBottomTabBarHeight in children!
    style: { borderTopWidth: 0, paddingBottom: 0, height: 60 },
    showLabel: false,
    // safeAreaInset: { bottom: 0, top: 0 },
  };
}

function getScreenOptions() {
  function screenops({ route }) {
    // console.log(route.name);
    const tabBarIcon = ({ focused, color, size }) => {
      return <TabIcon route_name={route.name} color={color} />;
    };
    return { tabBarIcon };
  }
  return screenops;
}

async function bootstrap_and_check_updates() {
  if (Platform.OS !== "web") {
    // console.log(ScreenOrientation.ScreenOrientationInfo);
    // this locks it SOMEWHAT, but things like the webview + modal still break it
    await ScreenOrientation.lockAsync(
      ScreenOrientation.OrientationLock.PORTRAIT_UP
    );
    // for debug, the opposite!
    // await ScreenOrientation.unlockAsync();
  }

  return await attempt_update();
}

async function attempt_update() {
  if (Platform.OS !== "web" && !__DEV__) {
    // check for new updates
    // https://stackoverflow.com/questions/51147794/how-do-i-force-an-expo-app-to-update/61552271#61552271
    try {
      console.log("checking for update");
      const update = await Updates.checkForUpdateAsync();
      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        console.log("update found!");
        // NOTIFY USER HERE
        // alert("✅ New App Version Found! Press OK to restart.");
        Updates.reloadAsync();
      }
    } catch (e) {
      console.log("UPDATE ERROR");
      console.log(e);
    }
  }
}

const sus_fallback = (
  <ImageBackground
    source={require("./assets/bg-gradient-half.png")}
    style={{
      backgroundColor: "#000",
      flex: 1,
    }}
  >
    <ActivityIndicator
      color="rgb(200,200,200)"
      size="large"
      style={{ marginTop: 50 }}
    />
  </ImageBackground>
);
const error_fallback = (
  <View style={{ flex: 1 }}>
    <Text
      style={{ marginTop: 150, color: "rgb(220,220,220)", textAlign: "center" }}
    >
      Sorry! Something went wrong
    </Text>
  </View>
);
