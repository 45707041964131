import React, { Suspense, useRef, useState, useEffect } from "react";
import { AntDesign } from "@expo/vector-icons";
import { Entypo } from "@expo/vector-icons";
import * as Icons from "./Icons.js";
import colors from "../colors.js";

import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
// prettier-ignore
import { TextInput, Modal, Animated, Pressable, ImageBackground, StyleSheet, View, Text } from "react-native";
import { useWindowDims } from "../helpers/dimsHook.js";

export function ActiveSwipeIndicators(props) {
  const { panX, panY } = props;

  const likeOpacity = panX.interpolate({
    inputRange: [0, 10, 150],
    outputRange: [0, 0, 1],
  });
  const dislikeOpacity = panX.interpolate({
    inputRange: [-150, -10, 0],
    outputRange: [1, 0, 0],
  });

  const likeScale = likeOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0.75, 1.5],
  });
  const dislikeScale = dislikeOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0.75, 1.5],
  });
  const likeRot = likeOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: ["-15deg", "0deg"],
  });
  const dislikeRot = dislikeOpacity.interpolate({
    inputRange: [0, 1],
    outputRange: ["15deg", "0deg"],
  });

  // for dev only: // const downOpacityAnim = panY.interpolate({inputRange: [0, 10, 150],outputRange: [1, 0, 1],});
  // down is dependent on how left or right it is.
  const downOpacityAnim = Animated.subtract(
    panY.interpolate({
      inputRange: [0, 10, 150],
      outputRange: [0, 0, 1],
    }),
    Animated.add(likeOpacity, dislikeOpacity)
  );

  const downScale = downOpacityAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0.75, 1.5],
  });
  // this impliments a tiny easter egg, making it spin if you reallly swipe down
  // to get rid of this just get rid of the last value for each
  const downRot = downOpacityAnim.interpolate({
    inputRange: [0, 1, 2.8, 2.82],
    outputRange: ["0deg", "0deg", "30deg", "90deg"],
    // extrapolate: "clamp",
  });

  return (
    <Animated.View
      style={[
        styles.outerWrap,
        {
          transform: [{ translateX: panX }, { translateY: panY }],
        },
      ]}
      pointerEvents={"none"}
    >
      <Animated.View
        style={[
          styles.standardInner,
          styles.likeInner,
          {
            transform: [{ scale: likeScale }, { rotateZ: likeRot }],
            opacity: likeOpacity,
          },
        ]}
      >
        <Icons.HeartEmpty size={25} viewBox={"0 0 22 20"} />
        <Text style={styles.likeText}>Like</Text>
      </Animated.View>
      <Animated.View
        style={[
          styles.standardInner,
          styles.dislikeInner,
          {
            transform: [{ scale: dislikeScale }, { rotateZ: dislikeRot }],
            opacity: dislikeOpacity,
          },
        ]}
      >
        <Icons.ThumbsDown size={25} viewBox={"0 -2 22 25"} />
        <Text style={styles.dislikeText}>Dislike</Text>
        {/*<AntDesign name="frowno" size={25} color={textColor} />*/}
      </Animated.View>
      <Animated.View
        style={[
          styles.standardInner,
          styles.watchlistInner,
          {
            transform: [{ scale: downScale }, { rotateZ: downRot }],
            opacity: downOpacityAnim,
          },
        ]}
      >
        <Icons.BookmarkEmpty size={25} viewBox={"-2 -2 20 20"} />
        <Text style={styles.watchlistText}>Add to Watchlist</Text>
        {/*<AntDesign name="star" size={25} color={textColor} />*/}
      </Animated.View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  outerWrap: {
    position: "absolute",
    zIndex: 2000,
    width: "100%",
    height: "100%",
  },
  standardInner: {
    backgroundColor: colors.background_lighter_alpha,
    flexDirection: "row",
    position: "absolute",
    borderRadius: 15,
    padding: 8,
    zIndex: 5,
    shadowColor: "rgb(0,0,0)",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 1,
    shadowRadius: 10,
    alignItems: "center",
    justifyContent: "center",
  },
  likeInner: {
    top: "33%",
    left: "5%",
  },
  likeText: {
    fontSize: 18,
    fontFamily: "Oswald_400Regular",
    letterSpacing: 0.5,
    color: colors.aquamarine,
    marginLeft: 8,
    fontWeight: "bold",
  },
  dislikeInner: {
    top: "33%",
    right: "5%",
  },
  dislikeText: {
    fontFamily: "Oswald_400Regular",
    letterSpacing: 0.5,
    fontSize: 18,
    color: colors.red,
    marginRight: 8,
    fontWeight: "bold",
  },
  watchlistInner: {
    top: "33%",
    left: "25%",
    width: "50%",
  },
  watchlistText: {
    fontFamily: "Oswald_400Regular",
    letterSpacing: 0.5,
    fontSize: 18,
    color: colors.yellow,
    textAlign: "center",
    fontWeight: "bold",
  },
});
