import { Platform } from "react-native";
import * as Linking from "expo-linking";

// https://docs.expo.io/guides/linking/#opening-links-from-your-app
// safer to use web, could open web as a fallback but because of dialog cancel also rejecting the promise, that would result in the web firing then, and canOpen requires an entry in plist in the config for each app
// workaroud: could a hook, use web the second time the press
export async function open_appropriate_source(source) {
  const best_url = get_best_source_url(source);
  const web_url = source.web_link;
  try {
    console.log("open_appropriate_source: trying best...");
    await Linking.openURL(best_url);
  } catch (e) {
    // console.log(e);
    console.log("could not open best source url", best_url);
    console.log("trying web...");
    await Linking.openURL(web_url);
  }
}

function get_best_source_url(source) {
  // sometimes it seems there's only a web link, so use that if we lack the appropriate one
  if (
    (!source.ios_link && Platform.OS === "ios") ||
    (!source.android_link && Platform.OS === "android")
  ) {
    return source.web_link;
  } else if (Platform.OS === "ios") {
    return source.ios_link;
  } else if (Platform.OS == "android") {
    return source.android_link;
  } else {
    return source.web_link;
  }
}
