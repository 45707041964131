import React, { useEffect, useState, useRef, useMemo } from "react";

import setupPaginationHooks from "../../app_shared/setup-pagination-hooks.js";
const paginationHooks = setupPaginationHooks({
  React,
});

import setupGeneralHooks from "../../app_shared/setup-general-hooks.js";

const generalHooks = setupGeneralHooks({ React });

export const useFSPagination = paginationHooks.useFSPagination;
export const useFSPagingMQWorkAround = paginationHooks.useFSPagingMQWorkAround;

export const useDebounce = generalHooks.useDebounce;

// via https://www.joshwcomeau.com/snippets/react-hooks/use-interval/
export function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    if (typeof delay === "number") {
      intervalRef.current = window.setInterval(tick, delay);
      return () => window.clearInterval(intervalRef.current);
    }
  }, [delay]);
  return intervalRef;
}
