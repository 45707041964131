// there's also 600 and 1200

// the thumbs are in a relative directory and are all resized converted and renamed .jpeg
// so for the 200 by 200 version of
// profile_media/nyBs7zuboabHU9J7BicT0TXolG33/somePhoto.jpg
// we want
// profile_media/nyBs7zuboabHU9J7BicT0TXolG33/thumbs/somePhoto_200x200.jpeg

export function get_thumb_path_200(path) {
  // console.log("get_thumb_path_200", path, typeof path);
  if (!path) {
    return;
  }
  const [name, ext] = path.split("/")[path.split("/").length - 1].split(".");
  const p_arr = path.split("/");
  p_arr.pop();
  return `${p_arr.join("/")}/thumbs/${name}_200x200.jpeg`;
}
