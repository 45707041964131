import create from "zustand";
import AsyncStorage from "@react-native-async-storage/async-storage";

// import produce from "immer";

export const LSKeys = {
  seen_intro: "seen_intro_v1",
  pin_ds_up: "pin_details_screen_up",
  last_swipe_screen_q_key: "last_swipe_screen_q_key",
  last_feed_screen_key: "last_feed_screen_key",
  last_list_screen_kind: "last_list_screen_kind",
};

export default create((set, get) => ({
  // where we put the actual user data
  user_data: {},
  // just so we can easily have all the strings for keys in one place
  local_keys: LSKeys,
  isLoaded: false,
  local_store_actions: {
    init: async () => {
      const user_data = await getLocalData("user_data");
      // console.log(user_data);
      set({ user_data, isLoaded: true });
    },

    setSeenIntro: async (value) => {
      const { local_store_actions } = get();
      await local_store_actions.setUserData(LSKeys.seen_intro, value);
    },
    setUserData: async (key, value) => {
      if (!Object.values(LSKeys).includes(key)) {
        // prettier-ignore
        console.log("XXX THAT LOCAL STORE KEY", key, "WAS INVALID! Not writing");
        return;
      }
      // console.log("XXX SKIPPING ACTUALLY SETTING PERSISTANT LOCAL DATA");

      // there was a typo where I didn't destructure this
      const { user_data } = get();
      user_data[key] = value;

      await setLocalData("user_data", user_data);
      // console.log("final", user_data);
      set({ user_data: { ...user_data } });
    },
  },
}));

// reduce((accumulator, currentValue, index, array) => { ... }, initialValue)

// async function getUserData() {
//   let result = {};
//   const all_keys = Object.values(LSKeys);
//   // console.log(ALL_KEYS);
//   // hacky but fine, out to be .reduce but tricky to make that work with async
//   await Promise.all(
//     all_keys.map(async (currentValue, index) => {
//       const val = await getLocalData(currentValue);
//       result[currentValue] = val;
//     })
//   );
//   return result;
// }

const setLocalData = async (key, value) => {
  try {
    return await AsyncStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    console.log(e);
    // saving error
  }
};

const getLocalData = async (key) => {
  try {
    const string = await AsyncStorage.getItem(key);
    return string ? JSON.parse(string) : {};
  } catch (e) {
    console.log(e);
    // error reading value
  }
};
