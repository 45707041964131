import * as core_maze_logic from "./core_maze_logic.js";

import { get_thumb_path_200 } from "./thumbs.js";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import pool from "@ricokahler/pool";
import { useState, useEffect } from "react";

const one_hour_in_ms = 3.6e6;

const AssetCore = ({
  FH,
  MAZE_API_DOMAIN,
  MAZE_API_STATIC_DOMAIN,
  SEEKING_TV_API_DOMAIN,
  gfb_config,
  Image,
  createAsset,
}) => {
  // first, some setup and some helper functions
  const STORAGE_BUCKET = gfb_config.storageBucket;
  // https://storage.googleapis.com/<bucketName>/<pathToFile>
  // helper for thumbAsset below
  async function get_image_url(path) {
    // could just do this, but checking if it's valid lets us fallback to nonthumbs for recent uploads (like the current user!)
    // const url = get_storage_path(path);
    const storage = getStorage();
    try {
      url = await getDownloadURL(ref(storage, path));
      if (Image && url) {
        Image.prefetch(url);
      }
      return url;
    } catch (e) {
      return false;
    }
  }

  const patch_maze_show_image_paths = (data) =>
    core_maze_logic.patch_maze_show_image_paths(data, MAZE_API_STATIC_DOMAIN);

  const get_storage_path = (path) => {
    return `https://storage.googleapis.com/${STORAGE_BUCKET}/${path}`;
  };

  const api_show_helper = async (maze_show_id, include_episodes) => {
    // prettier-ignore
    if (!maze_show_id) { return  false }

    // console.log("called api_show_helper", maze_show_id);
    try {
      const maybe_inc = include_episodes
        ? `&include_episodes=${include_episodes}`
        : ``;
      const url = `${SEEKING_TV_API_DOMAIN}/show?id=${maze_show_id}${maybe_inc}`;

      const res = await fetch(url);
      if (res.status !== 200) {
        // prettier-ignore
        console.log("api_show_helper res status", res.status, "for", maze_show_id, "incEps was", include_episodes  );
        return false;
      }
      const data = patch_maze_show_image_paths(await res.json());
      if (data._embedded && data._embedded.previousepisode) {
        data._embedded.previousepisode = patch_maze_show_image_paths(
          data._embedded.previousepisode
        );
      }
      if (data._embedded && data._embedded.nextepisode) {
        data._embedded.nextepisode = patch_maze_show_image_paths(
          data._embedded.nextepisode
        );
      }
      if (data._embedded && data._embedded.episodes) {
        data._embedded.episodes = data._embedded.episodes.map((s) =>
          patch_maze_show_image_paths(s)
        );
      }
      return data;
    } catch (e) {
      console.log("api_show_helper failure for ", maze_show_id);
      return false;
    }
  };

  const apiShowAsset = createAsset(async (maze_show_id, include_episodes) => {
    // console.log("apiShowAsset called", maze_show_id, include_episodes);
    return await api_show_helper(maze_show_id, include_episodes);
  }, one_hour_in_ms);

  // XXX unlike everything else in this file,  useConcurrentFetchMarkedShows
  // is a HOOK for the schedule screen
  function useConcurrentFetchMarkedShows(pos_marks_string) {
    const ids_arr = pos_marks_string.split(",").filter((a) => a);
    // console.log(pos_marks_string);
    const [results, setResults] = useState([]);
    const [isDone, setIsDone] = useState(false);
    // const collection = marks.map(({ tvmaze_id }) => tvmaze_id);
    const maxConcurrency = 3;
    // define the task
    const innerTask = async (id) => {
      // if we already have it, use the asset / cached version
      if (apiShowAsset.peek(id, "omit")) {
        // console.log("cache hit");
        return apiShowAsset.peek(id, "omit");
      }
      // console.log("cache miss");
      // otherwise fetch it
      const show = await api_show_helper(id, "omit");
      // guh if only we could set the apiShowAsset cache here, instead i'm preloading it in the sched item for now XXX
      return show;
    };
    const task = async (id) => {
      const item = await innerTask(id);
      setResults((prevArray) => [...prevArray, item]);
    };

    useEffect(() => {
      if (!ids_arr.length) {
        return;
      }
      // console.log("go");
      run();
    }, [pos_marks_string]);

    const run = () => {
      setIsDone(false);
      setResults([]);
      pool({ collection: ids_arr, maxConcurrency, task }).then(() => {
        setIsDone(true);
      });
    };

    const ratio = results.length / ids_arr.length;
    // console.log(results);
    return { isDone, results, ratio, refresh: run };
  }

  //what we actually return for use
  return {
    // asset for a single show in our endpoint, basically the tvmaze response but
    // -_embedded contains an episode_count
    // -if include_episodes is truthy _embedded also contains an episodes array
    //  *unless* it's the string "omit" in which case neither eps or a count is included
    // - maybe: a deeplinks prop which is either falsey or an array
    // - maybe: a ratings array with aggregate and external ratings
    apiShowAsset,
    // same as above
    api_show_helper,
    useConcurrentFetchMarkedShows,
    apiShowCastAsset: createAsset(async (maze_show_id) => {
      const url = `${MAZE_API_DOMAIN}/shows/${maze_show_id}?embed[]=cast&embed[]=crew`;
      const res = await fetch(url);
      const data = await res.json();
      return data._embedded.cast.map((item) => {
        item.character = patch_maze_show_image_paths(item.character);
        item.person = patch_maze_show_image_paths(item.person);
        return item;
      });
    }),

    apiPersonAsset: createAsset(async (tvmaze_artist_id) => {
      const url = `${MAZE_API_DOMAIN}/people/${tvmaze_artist_id}`;
      const res = await fetch(url);
      return patch_maze_show_image_paths(await res.json());
    }),

    apiCastCreditsAsset: createAsset(async (tvmaze_artist_id) => {
      const url = `${MAZE_API_DOMAIN}/people/${tvmaze_artist_id}/castcredits?embed[]=show&embed[]=character`;
      const res = await fetch(url);
      return patch_maze_show_image_paths(await res.json());
    }),

    apiAllEpisodesAsset: createAsset(async (tvmaze_show_id) => {
      const url = `${MAZE_API_DOMAIN}/shows/${tvmaze_show_id}/episodes?specials=1`;
      const res = await fetch(url);
      return (await res.json()).map((s) => patch_maze_show_image_paths(s));
    }),

    publicPageAsset: createAsset(async (uid) => {
      // console.log("publicPageAsset");
      const d = await FH.getPublicUserPage(uid);
      // console.log(d);
      return d ? d : { error: true };
      // return d;
    }),

    publicMarksAsset: createAsset(async (uid) => {
      return await FH.queryPublicMarks(uid);
    }),

    // https://stackoverflow.com/a/56815787/83859
    userPageSearchQueryAsset: createAsset(async (query) => {
      if (query.length < 2) {
        return [];
      }
      return await FH.userPageSearchQuery(query.toLowerCase());
    }),

    pageByScreenNameAsset: createAsset(async (screenName) => {
      const arr = await FH.queryPublicUserPages(screenName);
      if (arr.length === 1) {
        return arr[0];
      } else {
        console.log("something is wrong", arr);
        // this is hacky, but idk how to do it propperly with asset, can't throw or return Error
        return { error: true };
      }
    }),

    userFolsAsset: createAsset(async (uid) => {
      return await FH.queryFFS(uid);
    }),

    userFavArtAsset: createAsset(async (uid) => {
      return await FH.queryFavArt(uid);
    }),

    get_storage_path,
    get_thumb_path: (p) => get_storage_path(get_thumb_path_200(p)),

    //  if thumb doesn't exist (yet) use non thumb version
    thumbAsset: createAsset(async (path) => {
      if (!path) {
        return false;
      }
      // XXX this actually uses the storage system so we can determine if the file actually exists
      // whereas get_storage_path does not
      const url = await get_image_url(get_thumb_path_200(path));
      if (Image && url) {
        Image.prefetch(url);
      }
      // console.log("thumb url", url);
      if (url) {
        // console.log("RETURNING thumb url ");
        return url;
      } else {
        // console.log("RETURNING standard url");
        return await get_storage_path(path);
      }
    }),
    // this is used for incoming universal links into the show screen page in the expo app
    mazeApiShowSlugAsset: createAsset(async (slug, include_episodes) => {
      const name = slug.replace(/-/g, " ");
      const res = await core_maze_logic.fetch_single_show_search(
        name,
        MAZE_API_DOMAIN
      );
      if (res && res.id) {
        return await api_show_helper(res.id, include_episodes);
      } else {
        return { error: true };
      }
    }),
    // our own fuzzy search api for shows
    seekApiShowSearchQueryAsset: createAsset(async (q) => {
      if (q === "") {
        return [];
      }
      const encoded_q = q; //encodeURIComponent(q);
      let url = `${SEEKING_TV_API_DOMAIN}/search-shows?q=${encoded_q}`;
      const res = await fetch(url);
      if (res.status !== 200) {
        // really this should warn the user there was an error but not crashing will do for now
        return [];
      }
      const data = await res.json();
      return data;
    }),

    seekApiPeopleSearchQueryAsset: createAsset(async (q) => {
      if (q === "") {
        return [];
      }
      const encoded_q = encodeURIComponent(q);
      let url = `${SEEKING_TV_API_DOMAIN}/search-artists?q=${encoded_q}`;
      const res = await fetch(url);
      if (res.status !== 200) {
        return [];
      }
      const data = (await res.json()).map(patch_maze_show_image_paths);
      return data;
    }),

    // https://docs.expo.io/guides/preloading-and-caching-assets/#pre-loading-and-caching-assets
    // https://firebase.google.com/docs/functions/http-events#invoke_an_http_function
    ytSearchQueryAsset: createAsset(async (q, tvmaze_id) => {
      const encoded_q = encodeURIComponent(q);
      const url = `${SEEKING_TV_API_DOMAIN}/yt_search?q=${encoded_q}&tvmaze_id=${tvmaze_id}`;
      const res = await fetch(url);
      return await res.json();
    }),

    // NOT AN ASSET, just the url
    get_reco_page_url: (q_key, last, user_service_ids, lang_arr) => {
      // if q_key is in ['all'] don't include user_service_ids so we can cache that independently bc it doesn't matter for that one
      const payload =
        !["all"].includes(q_key) && user_service_ids
          ? { q_key, user_service_ids }
          : { q_key };

      if (lang_arr) {
        payload["lang_arr"] = lang_arr;
      }
      if (last) {
        payload["last"] = `${last.pmc}-${last.id}`;
      }
      // console.log(payload);
      const search_string = objectToParams(payload);
      const url = `${SEEKING_TV_API_DOMAIN}/shows-recoPaginated?${search_string}`;
      // console.log(url);
      return url;
    },
    // include this helper as well
    patch_maze_show_image_paths,
  }; // end returned object of default export
}; // end AssetCore

export default AssetCore;

export function objectToParams(object) {
  // const params = new URLSearchParams();
  let qs = ``;
  Object.entries(object).map((entry) => {
    let [key, value] = entry;
    const pre = qs.length === 0 ? "" : "&";
    qs += `${pre}${key}=${encodeURIComponent(value)}`;
    // params.set(key, value);
  });
  return qs;
  // return params.toString();
}
