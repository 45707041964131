import React from "react";
import {
  Modal,
  Pressable,
  ImageBackground,
  StyleSheet,
  View,
  Text,
} from "react-native";
// import { useWindowDims } from "../helpers/dimsHook.js";
import colors from "../colors.js";
import {
  HBO,
  HBOMax,
  Hulu,
  Amazon,
  Paramount,
  Disney,
  Apple,
  Netflix,
  Peacock,
  YouTube,
  //youtube has no reelgood id?
} from "./Icons.js";

export function ServiceIcon(props) {
  const { source_id } = props;

  const webChannelToIcon = {
    "hbo_max-hbo_max-subscription": (
      // <HBOMax size={props.size} viewBox={props.viewBox} />
      <HBO size={props.size} viewBox={props.viewBox} />
    ),
    "hbo-now-subscription": (
      // <HBO size={props.size} viewBox={props.viewBox} />
      <></>
    ),
    "hulu_plus-hulu_plus-subscription": (
      <Hulu size={props.size} viewBox={props.viewBox} />
    ),
    "amazon_prime-amazon_prime-subscription": (
      <Amazon size={props.size} viewBox={props.viewBox} />
    ),
    "paramount_plus-paramount_plus-subscription": (
      <Paramount size={props.size} />
    ),
    "disney_plus-disney_plus-subscription": (
      <Disney size={props.size} viewBox={props.viewBox} />
    ),
    "apple_tv_plus-apple_tv_plus-subscription": <Apple size={props.size} />,
    "netflix-netflix-subscription": (
      <Netflix size={props.size} viewBox={props.viewBox} />
    ),
    "hulu_plus-hulu_plus-subscription": (
      <Hulu size={props.size} viewBox={props.viewBox} />
    ),
    "peacock_free-peacock_free-free": (
      <Peacock size={props.size} viewBox={props.viewBox} />
    ),
  };

  return <>{webChannelToIcon[source_id]}</>;
}
