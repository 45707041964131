// prettier-ignore
import { getFirestore, collection, getDoc, getDocs, query, where, doc, setDoc, Timestamp, serverTimestamp, writeBatch } from "firebase/firestore/lite";

export function setupSeekStore({
  firebase,
  FH,
  create,
  produce,
  React,
  platform_os,
}) {
  // the actual zustand store hook we return
  //
  return create((set, get) => ({
    profile: false,
    public_page: false,
    user_marks: [],
    user_services: {},
    followers: false, // follow requests TO current user
    following: false, // follow requests FROM current user to others
    favorite_artists: [],
    seen_episodes: [],
    seek_actions: {
      onAuth: async (uid) => {
        const { seek_actions } = get();
        if (uid) {
          // this should always return a profile or we have a problem
          const profile = await get_profile(firebase, uid);
          // console.log("profile found", profile);
          seek_actions.onLogin(profile);
        } else {
          // this could call onLogout, but then it'll get called on web initially
          console.log("no uid!");
        }
      },
      onLogOut: async () => {
        // prettier-ignore
        set({ profile: false, user_marks: [], user_services: {}, following:false, followers:false, public_page:false, favorite_artists:[], seen_episodes: [] });
      },
      makeFollowRequest: async (to_uid) => {
        const { profile, following } = get();
        if (following.find((a) => a.to_uid === to_uid)) {
          // console.log("no, already following", to_uid);
          return;
        }
        const from_uid = profile ? profile.uid : false;
        const followData = await FH.makeFollowRequest(from_uid, to_uid);
        set({ following: [...following, followData] });
      },
      deleteFollowRequest: async (id) => {
        const { profile, following } = get();
        if (following.find((a) => a.id === id)) {
          const f_without = following.filter((a) => a.id !== id);
          await FH.deleteFollowRequest(id);
          set({ following: f_without });
        } else {
          console.log("not found!", id);
        }
      },
      setFavArt: async (tvmaze_artist_id) => {
        const { profile, favorite_artists, public_page } = get();
        const user_uid = profile ? profile.uid : false;
        const ts = Timestamp.fromDate(new Date());
        const is_public = !!public_page;
        const docId = `${user_uid}@${tvmaze_artist_id}`;
        // prettier-ignore
        const extant_fav = favorite_artists.find((a) => a.tvmaze_artist_id === tvmaze_artist_id)
        if (extant_fav) {
          // prettier-ignore
          const filtered = favorite_artists.filter((a) => a.tvmaze_artist_id !== tvmaze_artist_id);
          set({ favorite_artists: filtered });
          FH.deleteFavArt(docId);
        } else {
          const fav = { user_uid, tvmaze_artist_id, ts, is_public };
          const pushed = [...favorite_artists, fav];
          set({ favorite_artists: pushed });
          FH.setFavArt(user_uid, { user_uid, tvmaze_artist_id, ts, is_public });
        }
      },
      // extant_id will be false in the usual case, where we're marking a new ep as watched
      setSeenEp: async (
        has_watched,
        extant_id,
        { rg_episode_id, rg_show_id, tvmaze_episode_id, tvmaze_show_id }
      ) => {
        const { profile, seen_episodes } = get();
        const user_uid = profile ? profile.uid : false;
        const ts = Timestamp.fromDate(new Date());

        // prettier-ignore
        const seenEp = {has_watched, user_uid, ts, rg_episode_id, rg_show_id, tvmaze_episode_id, tvmaze_show_id }
        // it already existed because we were passed an id
        if (extant_id) {
          const extant_index = seen_episodes.findIndex(
            (a) => a.id === extant_id
          );
          // need to do it this way or use immer, otherwise we'll get a read-only error
          const seen_arr = [...seen_episodes];
          seenEp["id"] = extant_id;
          seen_arr[extant_index] = seenEp;
          set({ seen_episodes: seen_arr });
          await FH.setSeenEp(extant_id, seen_arr[extant_index]);
        } else {
          const id = `${user_uid}@${tvmaze_show_id}@${tvmaze_episode_id}`;
          seenEp["id"] = id;
          await FH.setSeenEp(id, seenEp);
          set({ seen_episodes: [...seen_episodes, seenEp] });
        }
      },

      setFlagForSelfDelete: async (flag) => {
        const { profile, public_page, seek_actions } = get();
        const user_uid = profile ? profile.uid : false;
        await FH.setFlagForSelfDelete(user_uid, flag, public_page);
        // if we're recovering from a delete
        if (flag === false) {
          seek_actions.fetchAndLoadOwnPublicPage(user_uid);
          const profile = await get_profile(firebase, user_uid);
          set({ profile });
        }
      },
      setUserProfileOption: (key, value) => {
        const { profile } = get();
        const user_uid = profile ? profile.uid : false;
        set((state) =>
          produce(state, (draftState) => {
            draftState.profile.options[key] = value;
          })
        );
        FH.setProfileOption(user_uid, key, value);
      },
      setUserService: async (service_maze_id, enabled) => {
        const ts = Timestamp.fromDate(new Date());
        set((state) =>
          produce(state, (draftState) => {
            draftState.user_services[service_maze_id] = { enabled, ts };
          })
        );
        const { profile } = get();
        const user_uid = profile ? profile.uid : false;
        // this will only execute if uid is provided
        FH.setUserService(user_uid, service_maze_id, enabled);
      },

      // setMark: async ({ tvmaze_id, kind }) => {
      // setMark: async ({ tvmaze_id, kind, rating = false, review_head = false, review_body = false }) => {
      setMark: async (mData) => {
        const { tvmaze_id, kind } = mData;
        const { profile } = get();
        const user_uid = profile ? profile.uid : false;
        const ts = Timestamp.fromDate(new Date());
        const findIndex = (m) => m.tvmaze_id === tvmaze_id;

        const { public_page } = get();

        set((state) =>
          produce(state, (draftState) => {
            const extantIndex = draftState["user_marks"].findIndex(findIndex);
            // it's public if they have a public_page AND kind isn't "removed" (see #12)
            // XXX update: because of this, we need to require that they mark
            // like, dislike or add-to-watchlist a show that they want to review/rate,
            // otherwise it won't be public. can just do it in the ui, not enabled till marked, sub the popover
            const is_public = !!public_page && kind !== "removed";

            if (extantIndex !== -1) {
              const extant = draftState["user_marks"][extantIndex];
              extant["kind"] = kind;
              extant["ts"] = ts;
              extant["is_public"] = is_public;
              // prettier-ignore
              const rating = mData.rating === undefined ? extant["rating"] : mData.rating;
              // prettier-ignore
              const review_head = mData.review_head === undefined ? extant["review_head"] : mData.review_head;
              // prettier-ignore
              const review_body = mData.review_body === undefined ? extant["review_body"] : mData.review_body;
              // console.log(mData);
              // prettier-ignore
              const caught_up_till = mData.caught_up_till === undefined ? extant["caught_up_till"] :  mData.caught_up_till;
              extant["rating"] = rating || false;
              extant["review_head"] = review_head || false;
              extant["review_body"] = review_body || false;
              extant["caught_up_till"] = caught_up_till || false;
              FH.setMark(user_uid, extant);
            } else {
              const rating = mData.rating === undefined ? false : mData.rating;
              // prettier-ignore
              const review_head = mData.review_head === undefined ? false : mData.review_head;
              // prettier-ignore
              const review_body = mData.review_body === undefined ? false : mData.review_body;
              // prettier-ignore
              const caught_up_till = mData.caught_up_till === undefined ? false : mData.caught_up_till;
              const created = Timestamp.fromDate(new Date());
              // prettier-ignore
              const created_mark = { ts, created, tvmaze_id, kind, user_uid, is_public, rating, review_head, review_body, caught_up_till };
              draftState["user_marks"].unshift(created_mark);
              FH.setMark(user_uid, created_mark);
            }
          })
        );
      },

      fetchAndLoadOwnPublicPage: async (uid) => {
        const public_page = await FH.getPublicUserPage(uid);
        set({ public_page });
      },

      onLogin: async (profile) => {
        set({ isDoneLoginLoading: false });
        const { seek_actions } = get();
        await seek_actions.fetchAndLoadOwnPublicPage(profile.uid);

        const { user_services } = get();
        // if they have no services in their profile from the server
        if (
          Object.keys(profile.services).length === 0 &&
          Object.keys(user_services).length !== 0
        ) {
          // otherwise let's write it from local
          await FH.setProfileMerge(profile.uid, { services: user_services });
        } else {
          set({ user_services: profile.services });
        }

        const server_marks = await FH.queryMarks(profile.uid);
        // console.log("server_marks", server_marks.length); console.log("local pre", get()["user_marks"].length);
        // get the local ones, and give them the user's uid f0real
        const local_marks = get()
          ["user_marks"].map((m) => {
            return { ...m, user_uid: profile.uid };
          })
          .filter((m) => {
            //  make sure "tvmaze_id" doesn't already exist in server_marks
            const match = server_marks.find((x) => m.tvmaze_id == x.tvmaze_id);
            // console.log(match);
            return match ? false : true;
          });

        if (local_marks.length) {
          await update_fs_batch_marks(firebase, profile.uid, local_marks);
        }
        // console.log("local_marks", local_marks.length);
        const user_marks = [...server_marks, ...local_marks];

        // console.log("total marks now:", user_marks.length);
        // console.log("login", profile.uid);
        const { followers, following } = await FH.queryFFS(profile.uid);
        const favorite_artists = await FH.queryFavArt(profile.uid, true);
        const seen_episodes = await FH.querySeenEp(profile.uid);
        set({
          user_marks,
          profile,
          following,
          followers,
          favorite_artists,
          seen_episodes,
          isDoneLoginLoading: true,
        });
        // finally, record this login
        const last_auth_ts = serverTimestamp();
        const last_auth_platform = platform_os;
        await FH.setProfileMerge(profile.uid, {
          last_auth_ts,
          last_auth_platform,
        });
      },
      // XXX this one actually sets it in firebase !
      setPublicUserPage: async (edited_page) => {
        const { profile } = get();
        const public_page = await FH.setPublicUserPage(
          profile.uid,
          edited_page
        );
        set({ public_page });
      },
    },
  })); // end return of create
} // end setupSeekStore

// helper
async function get_profile(firebase, uid) {
  // prettier-ignore
  if (!uid) { return false }
  const db = getFirestore(firebase);

  const docSnap = await getDoc(doc(db, "profiles", uid));
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    console.log(uid);
    throw new Error("No Profile Found !!!");
  }
}

// helper
async function update_fs_batch_marks(firebase, user_uid, marks_arr) {
  // https://firebase.google.com/docs/firestore/manage-data/transactions#batched-writes
  // const batch = db.batch();
  const db = getFirestore(firebase);

  const batch = writeBatch(db);

  for (let mark of marks_arr) {
    const { tvmaze_id } = mark;
    // mark["user_uid"] = user_uid;
    const id = `${user_uid}@${tvmaze_id}`;
    const markRef = doc(db, "user_marks", id);
    batch.set(markRef, mark, { merge: true });
  }
  await batch.commit();
  console.log("done with batch update to server!");
}
