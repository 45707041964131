import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, SafeAreaView, ScrollView, FlatList, Pressable, Button, View, Image, TextInput, ImageBackground, Switch, Keyboard } from "react-native";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import { useNavigation } from "@react-navigation/native";

import { ActionMarkButton } from "./components/ActionMarkButton.js";
import { ArtistListItem } from "./components/ArtistListItem.js";

import { SearchContainer } from "./SearchContainer.js";

import AllAssets from "./helpers/assets.js";
// prettier-ignore
const { apiPersonAsset, publicPageAsset, pageByScreenNameAsset, userFavArtAsset } = AllAssets;

import useSeekStore from "../app_shared/use-seek-store";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
import { current } from "immer";
import colors from "./colors.js";
import * as Icons from "./components/Icons.js";
import * as Toast from "./components/Toast.js";
import * as ButtonIcons from "./components/ButtonIcons.js";

const FH = new FSHelpers(firebase);

export default function FavoriteArtistsScreen(props) {
  const { params } = props.route;
  const userPage = params.uid
    ? publicPageAsset.read(params.uid)
    : params.screenName
    ? pageByScreenNameAsset.read(params.screenName)
    : false;

  const title =
    params.uid || params.screenName ? "Favorite Artists" : "Please Login First";

  return (
    <SafeAreaView style={styles.container}>
      <SearchContainer style={styles.SearchContainer} initialMode="artist" />
      <View style={styles.backButton}>
        <ButtonIcons.BackArrow onPress={props.navigation.goBack} />
      </View>
      <View style={styles.titleWrapper}>
        <Text style={styles.titleText}>{title}</Text>
      </View>

      <Suspense
        fallback={
          <ActivityIndicator
            size="large"
            color="white"
            style={styles.activityIndicator}
          />
        }
      >
        <ErrorBoundary fallback={<></>} style={styles.errorBoundary}>
          {userPage ? (
            <InnerFavArtistList {...props} userPage={userPage} />
          ) : (
            <></>
          )}
        </ErrorBoundary>
      </Suspense>
    </SafeAreaView>
  );
}

function InnerFavArtistList(props) {
  const { userPage } = props;
  const current_user_profile = useSeekStore((state) => state.profile);

  const profile_favorite_artists = userFavArtAsset.read(userPage.uid);
  const current_user_favorite_artists = useSeekStore(
    (state) => state.favorite_artists
  );

  const favorite_artists =
    userPage.uid === current_user_profile.uid
      ? current_user_favorite_artists
      : profile_favorite_artists;
  const emptyListText =
    userPage.uid === current_user_profile.uid
      ? "Try searching for a person to add!"
      : `${userPage.screenNameCased} hasn't liked anyone!`;

  return (
    <FlatList
      data={favorite_artists}
      ListEmptyComponent={
        <View style={styles.emptyListView}>
          <Text style={styles.searchForPersonText}>{emptyListText}</Text>
        </View>
      }
      style={styles.flatList}
      keyboardShouldPersistTaps="handled"
      keyExtractor={(item) => item.tvmaze_artist_id.toString()}
      renderItem={(a) => <WrappedArtistWithLoad {...a} userPage={userPage} />}
    />
  );
}

function WrappedArtistWithLoad(props) {
  const { item, index, separators, userPage } = props;
  const current_user_profile = useSeekStore((state) => state.profile);
  const seek_actions = useSeekStore((state) => state.seek_actions);

  const favorite_artists = useSeekStore((state) => state.favorite_artists);

  const extant = favorite_artists.find(
    (m) => m.tvmaze_artist_id === item.tvmaze_artist_id
  );
  const person = apiPersonAsset.read(item.tvmaze_artist_id);

  return (
    <ArtistListItem person={person}>
      <>
        {extant ? (
          <ButtonIcons.Trashcan
            onPress={() => {
              if (!current_user_profile.uid) {
                // prettier-ignore
                Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                return;
              }
              seek_actions.setFavArt(person.id);
            }}
          />
        ) : (
          <ButtonIcons.PlusCircle
            onPress={() => {
              if (!current_user_profile.uid) {
                // prettier-ignore
                Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                return;
              }
              seek_actions.setFavArt(person.id);
            }}
          />
        )}
      </>
    </ArtistListItem>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  backButton: {
    marginTop: 13,
    marginLeft: 24,
    alignItems: "center",
    flexDirection: "row",
  },
  titleWrapper: {
    marginTop: 15,
    marginLeft: 24,
  },
  titleText: {
    // fontFamily: "Oswald_400Regular",
    fontWeight: "bold",
    fontSize: 24,
    color: colors.text_white,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  flatList: {
    marginTop: 32,
    marginHorizontal: 24,
  },
  // OLD STYLES

  activityIndicator: { marginTop: 200 },
  errorBoundary: { flex: 1 },
  noResultView: { alignItems: "center", marginTop: 200 },
  noResultText: {
    fontSize: 16,
    color: colors.text_white,
  },
  emptyListView: { marginTop: 100, alignItems: "center" },
  emptyText: {
    fontSize: 16,
    color: colors.text_white,
  },
  searchForPersonText: {
    fontSize: 14,
    color: colors.text_white,
    marginTop: 10,
  },
  SearchContainer: { top: 65 },
});
