import Constants from "expo-constants";
import { Platform } from "react-native";
const { extra } = Constants.manifest;
const { appOwnership } = Constants;

// hacky fix to get image uploads working
// https://forums.expo.io/t/blob-stopped-working-in-expo-sdk-39/43516/18
import { atob } from "abab"; // react native has no built in atob()/btoa() methods, but firebase depends on it internally
global.atob = atob; // this tricks firebase into using the expected atob() method

import { initializeApp, getApps, getApp } from "firebase/app";

// XXX
// most importantly, this one is configured with the apple service id on gfb
// console XXX maybe it should be app id, currently com.seekingtv.gfba
// we need the auth configured this way to work for apple login on standalone build
const prodFirebaseConfig = {
  apiKey: extra.GFB_apiKey,
  authDomain: extra.GFB_authDomain,
  databaseURL: extra.GFB_databaseURL,
  projectId: extra.GFB_projectId,
  storageBucket: extra.GFB_storageBucket,
  messagingSenderId: extra.GFB_messagingSenderId,
  appId: extra.GFB_appId,
  measurementId: extra.GFB_measurementId,
};

// this one uses the service key for expo, host.exp.Expo
// ref: https://medium.com/@dansinger_68758/adding-sign-in-with-apple-to-a-managed-expo-app-using-firebase-authentication-ca331b4de05
const devFirebaseConfig = {
  apiKey: extra.GFB_DEV_apiKey,
  authDomain: extra.GFB_DEV_authDomain,
  databaseURL: extra.GFB_DEV_databaseURL,
  projectId: extra.GFB_DEV_projectId,
  storageBucket: extra.GFB_DEV_storageBucket,
  messagingSenderId: extra.GFB_DEV_messagingSenderId,
  appId: extra.GFB_DEV_appId,
  measurementId: extra.GFB_DEV_measurementId,
};

// https://docs.expo.io/versions/latest/sdk/constants/?redirected#constantsappownership

// web uses prod/standalone settings
const use_prod = Platform.OS === "web" || appOwnership !== "expo";

// console.log(appOwnership);
// console.log(use_prod);

const gfb_config = use_prod ? prodFirebaseConfig : devFirebaseConfig;

// console.log("USING DEV NO MATTER WHAT", gfb_config); // const gfb_config = false ? prodFirebaseConfig : devFirebaseConfig;

// this logic evidently prevents errors in dev with hot reload?  via https://github.com/expo-community/expo-firebase-starter/blob/master/components/Firebase/firebase.js#L8
const firebase = getApps().length === 0 ? initializeApp(gfb_config) : getApp();

// ACTUALLY this will rely on `firebase use` so can we use that instead?
// XXX some more tricky logic- this is correct, for when we need to use auth, it should be prod projectId even in dev  - to work with the above logic
// const local_domain = `http://localhost:5000/${gfb_config.projectId}/us-central1`;
// but for now use dev project id in dev - eventually use above instead
const local_domain = `http://localhost:5000/${extra.GFB_DEV_projectId}/us-central1`;
// and for production
const deployed_domain = `https://${gfb_config.projectId}.web.app`;

// const seeking_api_domain = __DEV__ ? local_domain : deployed_domain;
// this is what it was, the notes above refer to it -
// const seeking_api_domain = Platform.OS === "web" ? local_domain : deployed_domain;
// this removes the annoying logic of the above, because my computer was struggling to run both functions inc site and the expo app lol
const seeking_api_domain = deployed_domain;

console.log("using API domain:", seeking_api_domain);
console.log("using GFB app:", gfb_config.projectId);

const platform_os = Platform.OS;

export { firebase, gfb_config, seeking_api_domain, platform_os };
