import React, { useRef, useState, useEffect, useMemo, Suspense } from "react";
import { AntDesign } from "@expo/vector-icons";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useNavigation } from "@react-navigation/native";
import * as Icons from "./Icons.js";
import { HelpButton } from "./SwipeMisc.js";
import { useDebounce } from "../helpers/hooks.js";

// prettier-ignore
import { Animated, Modal, Pressable, ActivityIndicator, ScrollView, Platform, Image, StyleSheet, View, Text } from "react-native";
import { BlurView } from "expo-blur";

import ProgressiveImage from "./ProgressiveImage.js";
import { EpisodeCountComp } from "./ShowScheduleInfo.js";

import AllAssets from "../helpers/assets.js";
const { apiShowAsset } = AllAssets;

import { remove_html_tags_bad } from "../helpers/utils.js";
import { MaybeTrailerModal } from "./Trailer.js";
import { ActiveSwipeIndicators } from "./ActiveSwipeIndicators.js";

import colors from "../colors.js";

import * as Linking from "expo-linking";

import { WebView } from "react-native-webview";

import { useWindowDims } from "../helpers/dimsHook.js";

// XXX breaking my own guidence of not using the zustand stores inside components
// in an attempt to fix #130
import useSeekStore from "../../app_shared/use-seek-store";

import webchannels_json from "../../app_shared/maze_webchannels.json";
// const possible_services = Object.values(webchannels_json);
const services_by_rg = Object.values(webchannels_json).reduce((acc, cur) => {
  acc[cur.reelgood_id] = cur;
  return acc;
}, {});

const services_by_maze_id = Object.values(webchannels_json).reduce(
  (acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  },
  {}
);

// console.log(services_by_rg);
const prevMarkSelector = (state) => state.user_marks[0];

// prettier-ignore
const KIND_TO_FUNC_NAME = {
  "removed": "swipeTop",
  "seen-disliked": "swipeLeft",
  "seen-liked": "swipeRight",
  "add-to-watchlist": "swipeBottom",
};

export function TvShowCard(props) {
  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;
  const navigation = useNavigation();
  const { swiperRef, lastIndex } = props;
  const bottomTabHeight = useBottomTabBarHeight();

  // this is a  fix for #130, if the most recent (0th) mark
  // is the same show as the current card, i.e. this, then we've detected
  // the situation where a user clicks through and has marked the current card,
  // so don't want them to have to swipe again on it
  const prevMark = useSeekStore(prevMarkSelector);
  useEffect(() => {
    const isTopCard = props.index === lastIndex.current + 1;
    if (isTopCard && prevMark && prevMark?.tvmaze_id === props.show_id) {
      console.log("Running autoswipe...", props.show_id);
      const name = KIND_TO_FUNC_NAME[prevMark.kind];
      swiperRef.current[name]();
    }
  }, [prevMark]);

  return (
    <Suspense fallback={<CardFallback swiperRef={swiperRef} />}>
      <TvShowCardInner
        {...props}
        navigation={navigation}
        bottomTabHeight={bottomTabHeight}
      />
    </Suspense>
  );
}

function CardFallback(props) {
  const bottomTabHeight = useBottomTabBarHeight();
  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;
  const [vis, setVis] = useState(false);
  const visDebounced = useDebounce(vis, 8000);
  useEffect(() => {
    setVis(true);
  }, []);

  const onPress = () => props.swiperRef.current.swipeTop();

  return (
    <View
      style={{
        backgroundColor: "rgb(50,50,50)",
        width: windowWidth,
        height: windowHeight - bottomTabHeight,
      }}
    >
      <ActivityIndicator
        color="rgb(200,200,200)"
        size="large"
        style={{ marginTop: 150 }}
      />
      {visDebounced ? (
        <Text
          onPress={onPress}
          style={{
            color: "white",
            textAlign: "center",
            padding: 15,
            marginTop: 30,
          }}
        >
          It seems like something went wrong, skip to the next?
        </Text>
      ) : null}
    </View>
  );
}

export function DummyTVCard(props) {
  return (
    <Suspense fallback={<></>}>
      <TvShowCardInner {...props} />
    </Suspense>
  );
}

export function TvShowCardInner(props) {
  const show_data = apiShowAsset.read(props.show_id, "omit");

  const { groupedFeedmarks, bottomTabHeight } = props;

  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;
  // XXX hack no longer needed it seems!
  // XXX hack for notched iphones, should use safeareaview but complicated by cards needing to be full bleed
  // windowHeight = windowHeight === 844 ? windowHeight + 30 : windowHeight;

  const card_style = {
    backgroundColor: "rgb(10,10,10)",
    width: windowWidth,
    height: windowHeight - bottomTabHeight,
  };
  // console.log(groupedFeedmarks);
  const feedMarks =
    groupedFeedmarks && groupedFeedmarks[show_data.id]
      ? groupedFeedmarks[show_data.id]
      : false;

  return (
    <Card style={card_style}>
      <HelpButton />
      <ProgressiveImage
        thumbnailSourceUri={show_data.image?.medium}
        sourceUri={show_data.image?.original}
        resizeMode="cover"
        flex={1}
      />
      <CardBottomInfo
        show_data={show_data}
        feedMarks={feedMarks}
        navigation={props.navigation}
      />
    </Card>
  );
}

function CardBottomInfo(props) {
  const { show_data, feedMarks, navigation } = props;
  // console.log();
  const premiered_year = show_data.premiered
    ? show_data.premiered.split("-")[0]
    : ``;
  const genres = show_data.genres ? show_data.genres.join(", ") : ``;

  return (
    <Pressable
      style={styles.CardBottomInfo}
      onPress={() => {
        // may be in a dummy card in the intro if navigation isn't present
        if (navigation) {
          navigation.navigate("ShowDetail", { show_id: show_data.id });
        }
      }}
    >
      <Text style={styles.showName}>{show_data.name} </Text>
      {/* a row*/}
      <View style={styles.showInfoRow}>
        <Text style={styles.showInfoTextYear} numberOfLines={1}>
          {premiered_year}
        </Text>
        <Text style={styles.showInfoTextGenres} numberOfLines={1}>
          {genres}
        </Text>
      </View>
      <View style={styles.showInfoRow}>
        <Suspense fallback={<View style={{ height: 15 }} />}>
          <EpisodeCountComp
            style={styles.showInfoTextSmall}
            show_id={show_data.id}
          />
        </Suspense>
      </View>
      {feedMarks ? (
        <View style={styles.showInfoRow}>
          <Text style={styles.showInfoTextSmall} numberOfLines={1}>
            <Icons.Users
              size={16}
              viewBox="0 -5 25 25"
              stroke={colors.text_grey}
              style={{ marginRight: 4 }}
            />
            {feedMarks.length} {feedMarks.length === 1 ? "Friend" : "Friends"}{" "}
            Marked it
          </Text>
        </View>
      ) : (
        <></>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  CardBottomInfo: {
    // shadowOffset: { width: 0, height: 2 },
    shadowColor: "black",
    shadowOpacity: 0.5,
    shadowRadius: 5,
    bottom: 10,
    left: "2.5%",
    position: "absolute",
    width: "95%",
    borderRadius: 5,
    backgroundColor: colors.background_lighter_alpha,
    padding: 10,
    paddingLeft: 15,
    zIndex: 5,
  },
  showName: {
    fontFamily: "Oswald_400Regular",
    fontSize: 28,
    color: colors.text_white,
    marginBottom: 10,
  },
  showInfoRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    // justifyContent: "space-between",
    flex: 1,
    // alignItems: "center",
    width: "100%",
    marginBottom: 10,
  },
  showInfoTextYear: {
    color: colors.main_purple,
    fontSize: 15,
    marginRight: 25,
  },
  showInfoTextGenres: {
    color: colors.main_purple,
    fontSize: 15,
  },
  showInfoTextSmall: {
    color: colors.text_grey,
    fontSize: 15,
    textTransform: "capitalize",
  },
});
