// prettier-ignore
// import { getFirestore, collection,  getDocs,  query, where, doc, setDoc, orderBy, limit, queryEqual, startAfter } from "firebase/firestore/lite";
// prettier-ignore
import { limit, queryEqual, startAfter, getDocs, query, where } from "firebase/firestore/lite";

export default function setupPaginationHooks({ React }) {
  const { useEffect, useState, useRef, useMemo } = React;

  // https://usehooks.com/useFirestoreQuery/
  function useMemoCompare(next, compare) {
    // Ref for storing previous value
    const previousRef = useRef();
    const previous = previousRef.current;
    // Pass previous and next value to compare function
    // to determine whether to consider them equal.
    const isEqual = compare(previous, next);
    // If not equal update previousRef to next value.
    // We only update if not equal so that this hook continues to return
    // the same old value if compare keeps returning true.
    useEffect(() => {
      if (!isEqual) {
        previousRef.current = next;
      }
    });
    // Finally, if equal then return the previous value
    return isEqual ? previous : next;
  }

  // a hook to deal with pagination of firestore collections, originally vaguely based on https://usehooks.com/useFirestoreQuery/
  function useFSPagination(
    queryObj,
    page_length,
    deps = [],
    filter_ids = false
  ) {
    const queryCached = useMemoCompare(queryObj, (prevQuery) => {
      // Use built-in Firestore isEqual method to determine if "equal"
      // https://firebase.google.com/docs/reference/js/firestore_.md#queryequal
      return prevQuery && queryObj && queryEqual(queryObj, prevQuery);
    });

    // we use this one to keep track of the actual pagination, for startAfter
    const lastLoaded = useRef();
    // a flag so fetch data only really runs concurrently once at a time - kinda like a throttle
    const isFetching = useRef();
    // where we store the actual data
    const [arr, setArr] = useState([]);

    useEffect(() => {
      lastLoaded.current = null;
      fetchData();
    }, deps);

    const fetchData = async () => {
      // intentionally putting this logic here so as we swipe we don't add to what we're filtering out
      const filter_func = !filter_ids
        ? () => true
        : ({ tvmaze_id }) => !filter_ids.includes(tvmaze_id);
      // console.log("filter ids len:", filter_ids.length);
      if (isFetching.current) {
        return;
      }
      isFetching.current = true;

      const q = lastLoaded.current
        ? query(queryCached, limit(page_length), startAfter(lastLoaded.current))
        : query(queryCached, limit(page_length));

      const res = await getDocs(q);
      // const res = await q.get();

      const d = get_arr(res).filter(filter_func);
      isFetching.current = false;

      setArr(lastLoaded.current ? [...arr, ...d] : d);
      // now set our other flag
      if (res.docs.length) {
        lastLoaded.current = res.docs[res.docs.length - 1];
      }
    };

    const refreshData = () => {
      lastLoaded.current = null;
      return fetchData();
    };

    return [arr, fetchData, refreshData];
  } // end useFSPagination

  // very similar to the above useFSPagination, but specific to marks, bc it includes a workaround
  // for the  'in' firestore query max 10 issue
  function useFSPagingMQWorkAround(
    query_sans_in,
    following_uids,
    page_length,
    deps,
    filter_ids = false
  ) {
    // we use this one to keep track of the actual pagination, for startAfter
    // note, object, unlike above, for each batch
    const lastLoaded = useRef({});
    // a flag so fetch data func only really runs concurrently once at a time
    // though our batch qs will run in parallel
    const isFetching = useRef();
    // where we store the actual data
    const [arr, setArr] = useState([]);

    // console.log(following_uids);
    useEffect(() => {
      // console.log("useffect");
      lastLoaded.current = {};
      fetchData();
    }, deps);

    const fetchData = async () => {
      // console.log("calling fetch data   total fols:", following_uids.length);
      // intentionally putting this logic here so as we swipe we don't add to what we're filtering out
      const filter_func = !filter_ids
        ? () => true
        : ({ tvmaze_id }) => !filter_ids.includes(tvmaze_id);
      // console.log("filter ids len:", filter_ids.length);
      if (isFetching.current) {
        return;
      }
      isFetching.current = true;

      const results = (
        await maxFSOperatorWorkaround(
          query_sans_in,
          page_length,
          [...following_uids],
          lastLoaded.current
        )
      ).filter(filter_func);

      // console.log(results);
      setArr([...arr, ...results]);
      isFetching.current = false;
    }; // end fetchData

    const refreshData = () => {
      lastLoaded.current = {};
      return fetchData();
    };
    // what our useFSPagingMQWorkAround hooks returns
    return [arr, fetchData, refreshData];
  }

  // XXX what we actually return from the outer
  return { useMemoCompare, useFSPagingMQWorkAround, useFSPagination };
}

// helper for above, shouldn't need scope of above

// WORKAROUND for max of 10 for "in" a given array query in firestore queries
// i.e. how we deal with following people
// https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any

// via https://stackoverflow.com/questions/61354866/is-there-a-workaround-for-the-firebase-query-in-limit-to-10
// XXX note the use of .splice() - modifies ids arr
function maxFSOperatorWorkaround(collectionPath, page_length, ids, lastLoaded) {
  return new Promise((res) => {
    // don't run if there aren't any ids or a path for the collection
    if (!ids || !ids.length || !collectionPath) return res([]);
    // we store our promises here while we wait for them to resolve
    let batches = [];
    // we increment this
    let count = 0;
    // XXX this is destructive to the id list, so make sure it's a copy
    while (ids.length) {
      // firestore limits batches to 10, see:
      // https://firebase.google.com/docs/firestore/query-data/queries#limitations_2
      const batch = ids.splice(0, 10);
      // const batch = ids.splice(0, 2); //just for debug
      // increment the count
      count += 1;
      const cursorKey = `b${count}`;
      // console.log(count);
      const queryObj = lastLoaded[cursorKey]
        ? query(
            collectionPath,
            where("user_uid", "in", [...batch]),
            startAfter(lastLoaded[cursorKey]),
            limit(page_length)
          )
        : query(
            collectionPath,
            where("user_uid", "in", [...batch]),
            limit(page_length)
          );

      // add the batch request to to a queue
      batches.push(
        new Promise((response) => {
          getDocs(queryObj).then((results) => {
            // without this check to see if we actually returned docs, lastLoaded[cursorKey] will end up flipping BACK to a falsy state, and so we'll end up trying again at the start and then having dupe ids !
            if (results.docs.length) {
              lastLoaded[cursorKey] = results.docs[results.docs.length - 1];
            }
            // console.log(results.docs.length);
            response(
              results.docs.map((result) => ({
                id: result.id,
                ...result.data(),
              }))
            );
          });
        })
      );
    }
    // after all of the data is fetched, return it
    Promise.all(batches).then((content) => {
      res(content.flat());
    });
  });
}

function get_arr(qs, include_id = true) {
  let res = [];
  qs.forEach((doc) => {
    let p = doc.data();
    // prettier-ignore
    if (include_id) { p["id"] = doc.id; }
    res.push(p);
  });
  return res;
}
