import { firebase, db, platform_os } from "../fire.js";
import React, { useState, useEffect, useContext, createContext } from "react";
import create from "zustand";
import produce from "immer";
import { FSHelpers } from "./fs_helpers.js";
const FH = new FSHelpers(firebase);

import { setupSeekStore } from "./setup-use-seek-store.js";
import AllAssets from "../src/helpers/assets.js";

export default setupSeekStore({
  firebase,
  FH,
  create,
  produce,
  React,
  platform_os,
});
