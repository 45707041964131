import React, { useState, useEffect, useMemo } from "react";
// prettier-ignore
import { StyleSheet, Text, Animated, Platform, FlatList, TextInput, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";
import colors from "../colors.js";
import Popover from "react-native-popover-view";
import * as Toast from "./Toast.js";

import { AntDesign, Feather, Entypo } from "@expo/vector-icons";

import { Rating, AirbnbRating } from "react-native-ratings";

const star_outer_img = require("../../assets/star_outer.png");
// https://github.com/Monte9/react-native-ratings#ratingprops
// default ratingColor #f1c40f

export function CurrentUserRatingAndReview(props) {
  // could useState and a listener here to show it dynamically as you swipe TODO
  const curRating = props.rating || 0;
  return (
    <View style={styles.CurrentUserRatingAndReview}>
      <Text style={styles.bTitle}>
        Your Rating
        {typeof props.rating !== "number" ? null : (
          <>
            : <Text style={styles.ratingText}>{curRating}</Text>
          </>
        )}
      </Text>
      <View style={styles.rowContainer}>
        <STVRating
          style={styles.CUSTVRatingFull}
          startingValue={curRating}
          onFinishRating={props.onFinishRating}
          ratingColor={colors.orangy}
        />

        <DeleteButton
          visible={typeof props.rating === "number"}
          onPress={() => {
            props.onFinishRating(false);
          }}
        />
      </View>
      <ReviewHeadBodyAndButton {...props} />
    </View>
  );
}

function DeleteButton(props) {
  if (!props.visible) {
    return null;
  }
  return (
    <AntDesign
      onPress={props.onPress}
      name="delete"
      size={20}
      color={colors.main_purple}
      style={{ marginLeft: 20 }}
    />
  );
}

export function STVRating(props) {
  return (
    <Rating
      showRating={false}
      ratingBackgroundColor="rgba(255,255,255, 0.2)"
      type="custom"
      ratingImage={star_outer_img}
      fractions={1}
      jumpValue={0.5}
      {...props}
    />
  );
}

function ReviewHeadBodyAndButton(props) {
  const [head, setHead] = useState(props.review_head);
  const [body, setBody] = useState(props.review_body);

  const submit = () => {
    if ((head && head.length > 3) || (body && body.length > 5)) {
      props.onPostReviewPress(head, body);
      props.close();
      Toast.alert({ title: "Review Posted!" });
    } else {
      Toast.alert(
        { title: "Review too short", description: "Please write a bit more" },
        "danger"
      );
    }
  };

  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "baseline" }}>
        <Text style={styles.bTitle}>Your Review</Text>
        <DeleteButton
          visible={props.review_head !== false || props.review_body !== false}
          onPress={() => {
            props.onPostReviewPress(false, false);
            props.close();
            Toast.alert({ title: "Review Deleted!" }, "info");
          }}
        />
      </View>
      <TextInput
        placeholder="Review Headline"
        maxLength={100}
        placeholderTextColor={colors.text_grey}
        style={styles.reviewHeadInput}
        autoCompleteType="off"
        selectionColor={"white"}
        value={head || ""}
        onChangeText={(t) => setHead(t)}
        returnKeyType="done"
        onSubmitEditing={submit}
      />
      <TextInput
        placeholder="Review text..."
        maxLength={1000}
        placeholderTextColor={colors.text_grey}
        multiline={true}
        numberOfLines={3}
        returnKeyType="done"
        style={styles.reviewBodyInput}
        autoCompleteType="off"
        blurOnSubmit={true}
        selectionColor={"white"}
        value={body || ""}
        onChangeText={(t) => setBody(t)}
        onSubmitEditing={submit}
      />
      <Pressable
        onPress={submit}
        style={{
          backgroundColor: colors.main_purple,
          padding: 10,
          marginTop: 15,
        }}
      >
        <Text
          style={{
            color: "white",
            textAlign: "center",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          Post Review
        </Text>
      </Pressable>
    </>
  );
}

function RRButtonInner(props) {
  const { canRateAndReview, extant } = props;
  const doesReviewExist = extant && (extant.review_head || extant.review_body);

  if (
    canRateAndReview &&
    extant.rating !== false &&
    extant.rating !== undefined
  ) {
    return (
      <View style={{ flexDirection: "column" }}>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.rTitle}>You: {extant.rating}</Text>
          <STVRating
            startingValue={1}
            readonly={true}
            ratingColor={colors.orangy}
            imageSize={20}
            ratingCount={1}
            style={{ marginRight: 10 }}
          />
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 4,
          }}
        >
          <Text style={styles.rTitle}>
            {doesReviewExist ? "Edit" : "Write"} Review
          </Text>
          <Feather
            name="message-square"
            size={20}
            color={canRateAndReview ? colors.aquamarine : colors.text_grey}
          />
        </View>
      </View>
    );
  }
  // the normal cases, not yet marked (grayed out)
  // and marked but not rated or reviewed
  return (
    <>
      <Feather
        name="message-square"
        size={20}
        color={canRateAndReview ? colors.aquamarine : colors.text_grey}
      />
      <Text
        style={canRateAndReview ? styles.textLabel : styles.textLabelInactive}
      >
        Rate &{doesReviewExist ? " Edit" : ""} Review
      </Text>
    </>
  );
}

// this is the one we actually consume in the details screen
export function RateAndReviewWidget(props) {
  const { user_marks, seek_actions, show_data } = props;
  const extant = user_marks.find((m) => m.tvmaze_id === show_data.id);
  const canRateAndReview = extant && extant.kind !== "removed";
  const [isPopVisible, setIsPopVisible] = useState(false);
  const from_element = (
    <Pressable
      style={styles.popPressable}
      onPress={() => setIsPopVisible(!isPopVisible)}
    >
      <RRButtonInner canRateAndReview={canRateAndReview} extant={extant} />
    </Pressable>
  );

  // only showing the pressable rather than the popover on web to prevent an annoying warning related to why the popover doesn't work on web
  if (Platform.OS === "web") {
    return from_element;
  }

  return (
    <Popover
      isVisible={isPopVisible}
      onRequestClose={() => setIsPopVisible(false)}
      from={from_element}
      arrowStyle={{ backgroundColor: "rgb(180,180,180)" }}
    >
      <View style={styles.popInner}>
        {!canRateAndReview ? (
          <Text style={styles.sorry}>
            Sorry, you need to mark the show first (like, dislike, or watchlist)
          </Text>
        ) : null}
        {canRateAndReview ? (
          <CurrentUserRatingAndReview
            onFinishRating={(rating) => {
              // XXX this requires the mark to exist!
              seek_actions.setMark({ ...extant, rating });
            }}
            rating={extant.rating}
            review_head={
              extant && extant.review_head ? extant.review_head : false
            }
            review_body={
              extant && extant.review_body ? extant.review_body : false
            }
            onPostReviewPress={(review_head, review_body) => {
              // XXX this also requires the mark to exist!
              seek_actions.setMark({ ...extant, review_head, review_body });
            }}
            close={() => setIsPopVisible(false)}
          />
        ) : null}
      </View>
    </Popover>
  );
}

//XXX this is the color the png uses, which is equivalent to colors.background_lighter, over the background
const BG_COLOR = `rgb(28,26,40)`;

const styles = StyleSheet.create({
  CurrentUserRatingAndReview: {
    backgroundColor: BG_COLOR,
    borderRadius: 8,
    padding: 4,
    marginHorizontal: 12,
    marginVertical: 8,
  },
  ratingText: {
    color: colors.orangy,
    fontWeight: "bold",
    marginLeft: 4,
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
  },
  CUSTVRatingFull: { paddingVertical: 10, marginBottom: 10 },

  sorry: {
    fontSize: 15,
    width: 175,
    color: colors.text_grey,
  },
  textLabel: {
    marginLeft: 8,
    fontSize: 18,
    fontWeight: "bold",
    color: colors.text_white,
  },
  textLabelInactive: {
    marginLeft: 8,
    fontSize: 18,
    color: colors.text_grey,
  },
  title: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 2,
    marginTop: 2,
  },
  rTitle: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
    marginRight: 4,
  },
  bTitle: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    marginRight: 4,
    marginBottom: 10,
  },
  reviewHeadInput: {
    fontSize: 20,
    marginTop: 8,
    padding: 4,
    color: "white",
    backgroundColor: "rgba(255,255,255, 0.2)",
    width: 250,
  },
  reviewBodyInput: {
    fontSize: 16,
    marginTop: 8,
    padding: 4,
    height: 70,
    color: "white",
    backgroundColor: "rgba(255,255,255, 0.2)",
  },
  popPressable: {
    flexDirection: "row",
    padding: 5,
    marginRight: 5,
    alignItems: "center",
  },
  popInner: {
    padding: 10,
    backgroundColor: colors.background_lighter,
    borderRadius: 3,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: "rgb(180,180,180)",
  },
});
