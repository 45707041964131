export const KIND_TO_LABEL = {
  "seen-liked": "liked",
  "add-to-watchlist": "added",
  "seen-disliked": "disliked",
  removed: "removed",
};

export function get_verbage(item) {
  if ((item.review_head || item.review_body) && item.rating !== false) {
    return "rated & reviewed";
  } else if (item.review_head || item.review_body) {
    return KIND_TO_LABEL[item.kind] + " & reviewed";
  } else if (typeof item.rating === "number") {
    return KIND_TO_LABEL[item.kind] + " & rated";
  }
  return KIND_TO_LABEL[item.kind];
}
