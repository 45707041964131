import React, { useState, useEffect, useRef, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, ScrollView, FlatList, Pressable, Image, Button, View, TextInput, ImageBackground, Switch } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { useActionSheet } from "@expo/react-native-action-sheet";

import { PlaceHolderUserPhoto, UserPhotoComp } from "./UserImages.js";

import AllAssets from "../helpers/assets.js";
const { publicPageAsset } = AllAssets;

import colors from "../colors.js";
import { UserListItem } from "./UserListItem.js";

import * as Toast from "./Toast.js";

import { FSHelpers } from "../../app_shared/fs_helpers.js";
import { firebase } from "../../fire.js";
const FH = new FSHelpers(firebase);

export function FolFolCollection(props) {
  const { whichList } = props;
  const folOrfol = whichList === "followers" ? "Followers" : "Following";
  // console.log(props);

  return (
    <>
      <ScrollView>
        <View>
          {props.list.map((item) => (
            <Suspense
              key={item.id}
              fallback={<ActivityIndicator color="rgb(200,200,200)" />}
            >
              <FollowListItem {...props} key={item.id} item={item} />
            </Suspense>
          ))}
        </View>
      </ScrollView>
    </>
  );
}

function FollowListItem(props) {
  const navigation = useNavigation();
  const { seek_actions, current_user_profile, following } = props;

  const uid =
    props.whichList === "followers" ? props.item.from_uid : props.item.to_uid;
  const userPage = publicPageAsset.read(uid);
  const { displayName, screenNameCased } = userPage;

  const existing_fol = following
    ? following.find((x) => x.to_uid === userPage.uid)
    : false;

  return (
    <UserListItem userPage={userPage} navigation={navigation}>
      <FollowRequestButton
        following={following}
        existing_fol={existing_fol}
        seek_actions={seek_actions}
        userPage={userPage}
        current_user_profile={current_user_profile}
      />
    </UserListItem>
  );

  return (
    <View style={styles.border}>
      <View style={styles.container}>
        <View style={styles.userPhotoContainer}>
          {userPage.userPhoto ? (
            <UserPhotoComp
              size={64}
              userPhoto={userPage.userPhoto}
              onPress={() => {
                navigation.navigate("UserPage", {
                  uid: uid,
                });
              }}
            />
          ) : (
            <PlaceHolderUserPhoto
              size={32}
              onPress={() => {
                navigation.navigate("UserPage", {
                  uid: uid,
                });
              }}
            />
          )}
        </View>
        <View style={styles.nameContainer}>
          <Text
            style={styles.displayName}
            onPress={() => {
              navigation.navigate("UserPage", {
                uid: uid,
              });
            }}
          >
            {displayName}
          </Text>
          <Text
            style={styles.screenName}
            onPress={() => {
              navigation.navigate("UserPage", {
                uid: uid,
              });
            }}
          >
            @{screenNameCased}
          </Text>
        </View>
        <FollowRequestButton
          following={following}
          existing_fol={existing_fol}
          seek_actions={seek_actions}
          userPage={userPage}
          current_user_profile={current_user_profile}
        />
      </View>
    </View>
  );
}

function FollowRequestButton(props) {
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();
  const { existing_fol, seek_actions, userPage, current_user_profile } = props;
  if (current_user_profile && current_user_profile["uid"] === userPage.uid) {
    return <View></View>;
  }
  // and if it's not your own
  return (
    <>
      {!existing_fol ? (
        <Pressable
          style={styles.followButton}
          onPress={async () => {
            if (!current_user_profile) {
              navigation.navigate("Profile");
            } else {
              await seek_actions.makeFollowRequest(userPage.uid);
              // prettier-ignore
              Toast.alert({ title: `Now Following @${userPage.screenNameCased}`, description:`👋 ${userPage.displayName}`, duration: 4 }, "success");
            }
          }}
        >
          <Text style={styles.followButtonText}>Follow</Text>
        </Pressable>
      ) : (
        <Pressable
          style={styles.unfollowButton}
          onPress={() => {
            showActionSheetWithOptions(
              {
                options: [`Unfollow @${userPage.screenNameCased}`, "Cancel"],
                destructiveButtonIndex: 0,
                cancelButtonIndex: 1,
              },
              async (buttonIndex) => {
                if (buttonIndex === 0) {
                  await seek_actions.deleteFollowRequest(existing_fol.id);
                  // prettier-ignore
                  Toast.alert({ title: `Unfollowed @${userPage.screenNameCased}`, duration: 4, description:`👋 ${userPage.displayName}` }, "danger");
                }
              }
            );
          }}
        >
          <Text style={styles.followButtonText}>Following</Text>
        </Pressable>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 24,
    flexDirection: "row",
    marginBottom: 18,
    alignItems: "center",
  },
  userPhotoContainer: {},
  nameContainer: {
    marginHorizontal: 10,
    // maxWidth: 120
  },
  displayName: {
    color: colors.text_white,
    fontSize: 16,
    fontWeight: "bold",
  },
  screenName: {
    color: colors.text_grey,
    fontSize: 14,
    marginTop: 5,
  },
  followButton: {
    padding: 7,
    borderRadius: 8,
    borderColor: colors.main_purple,
    borderWidth: 1,
    position: "absolute",
    right: 0,
  },
  unfollowButton: {
    padding: 7,
    borderRadius: 6,
    borderColor: colors.main_purple,
    borderWidth: 1,
    position: "absolute",
    right: 0,
  },
  followButtonText: { color: "white", fontSize: 14 },
});
