import { Notifier, NotifierComponents } from "react-native-notifier";

import colors from "../colors.js";

const ALERT_TYPE_TO_STYLE = {
  // success: { backgroundColor: colors.aquamarine, textColor: colors.background },
  success: {
    backgroundColor: "rgba(142, 255, 214, 0.9)",
    textColor: colors.background,
  },
  danger: { backgroundColor: colors.red, textColor: colors.text_white },
  // info: { backgroundColor: colors.main_purple, textColor: colors.text_white },
  info: {
    backgroundColor: "rgba(130, 122, 255, 0.9)",
    textColor: colors.background,
  },
};

export function clear(hideDisplayedNotification = true) {
  Notifier.clearQueue(hideDisplayedNotification);
}
export function alert(
  { title, description, duration = 3 },
  alertType = "success"
) {
  const { backgroundColor, textColor } =
    ALERT_TYPE_TO_STYLE[alertType] || ALERT_TYPE_TO_STYLE["success"];
  Notifier.showNotification({
    title,
    description,
    duration: duration * 1000,
    Component: NotifierComponents.Alert,
    componentProps: {
      backgroundColor,
      textColor,
    },
  });
}
