import React, { useState, useCallback, useEffect, useRef } from "react";
// prettier-ignore
import { StyleSheet, SectionList, Text, SafeAreaView, RefreshControl, ActivityIndicator, Pressable, View, Image, Platform} from "react-native";
import useSeekStore from "../app_shared/use-seek-store";
import * as Linking from "expo-linking";

import { useNavigation } from "@react-navigation/native";

import { open_appropriate_source } from "./helpers/sourceOpener.js";

import { SearchContainer } from "./SearchContainer.js";

import * as SDHelpers from "./helpers/formatting_helpers.js";

import AllAssets from "./helpers/assets.js";
// prettier-ignore
const { apiShowAsset, api_show_helper, useConcurrentFetchMarkedShows } = AllAssets;

import { FSHelpers } from "./../app_shared/fs_helpers.js";

import { firebase } from "./../fire.js";

import { filter_embed_eps } from "./../app_shared/core_maze_logic.js";

import { ServiceIcon } from "./components/ServiceIcon.js";

import colors from "./colors.js";
import * as Icons from "./components/Icons.js";

import webchannels_json from "../app_shared/maze_webchannels.json";
const services_by_rg = Object.values(webchannels_json).reduce((acc, cur) => {
  acc[cur.reelgood_id] = cur;
  return acc;
}, {});

const FH = new FSHelpers(firebase);

// selector for schedule
function get_positive_marks(state) {
  return state.user_marks.filter(
    (m) => m.kind === "add-to-watchlist" || m.kind == "seen-liked"
  );
}

import Constants from "expo-constants";
import { ScrollView } from "react-native-gesture-handler";
const MAZE_API_DOMAIN = Constants.manifest.extra.MAZE_API_DOMAIN;
// const SEEKING_TV_API_DOMAIN = seeking_api_domain;

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

const seek_actions_selector = (state) => state.seek_actions;
const profile_selector = (state) => state.profile;
const public_page_selector = (state) => state.public_page;

export default function ScheduleScreen(props) {
  const seek_actions = useSeekStore(seek_actions_selector);
  const profile = useSeekStore(profile_selector);
  const positive_marks = useSeekStore(get_positive_marks);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);

  const pos_marks_string = positive_marks
    .map(({ tvmaze_id }) => tvmaze_id)
    .join(",");

  const { results, isDone, ratio, refresh } =
    useConcurrentFetchMarkedShows(pos_marks_string);

  const onUserRefresh = () => {
    // prettier-ignore
    if (isDone) { refresh() }
  };

  const shows = results;

  return (
    <SafeAreaView style={styles.container}>
      <SearchContainer style={styles.searchWidget} />
      <View style={styles.titleWrapper}>
        <Text style={styles.titleText}>Schedule</Text>
      </View>
      <View style={styles.spacer} />

      <IncrementalLoadingIndicator
        ratio={ratio}
        isDone={isDone}
        refresh={refresh}
        total={positive_marks.length}
        currentCount={results.length}
      />
      {shows ? (
        <ScheduleList
          shows={shows}
          positive_marks={positive_marks}
          pos_marks_string={pos_marks_string}
          onRefresh={refresh}
          isRefreshing={!isDone}
          onUserRefresh={onUserRefresh}
        />
      ) : null}
    </SafeAreaView>
  );
}

function IncrementalLoadingIndicator(props) {
  const { ratio, isDone, refresh, total, currentCount } = props;
  const percent = (ratio * 100).toFixed(0);
  if (total === 0 || isDone) {
    return <></>;
  }
  return (
    <View
      style={{
        display: "flex",
        flexDirection: "row",
        marginTop: 32,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {isDone ? (
        <>
          <Text style={styles.loading_message}>Loaded {total} shows</Text>
        </>
      ) : (
        <>
          <Text style={styles.loading_message}>
            Loading {percent}% ({currentCount}/{total}) done...
          </Text>
        </>
      )}
      <ActivityIndicator
        size="small"
        color="white"
        hidesWhenStopped={true}
        animating={!isDone}
        style={{ marginTop: -8, marginLeft: 4 }}
      />
    </View>
  );
}

function ScheduleList(props) {
  // prettier-ignore
  const { shows, positive_marks, pos_marks_string, onRefresh, isRefreshing, onUserRefresh } = props;
  const navigation = useNavigation();
  const sectionListRef = useRef();
  const profile = useSeekStore(profile_selector);
  // console.log("hello world");

  if (positive_marks.length === 0) {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={onUserRefresh}
            tintColor={colors.main_purple}
          />
        }
      >
        <View style={styles.list}>
          <View style={styles.sect_header}>
            <Text style={styles.sect_header_title}>Out Now</Text>
          </View>
          {!profile ? (
            <View style={styles.message}>
              <Text style={styles.message_text}>
                You don't seem to have marked any shows as liked or on your
                watchlist.
              </Text>
              <Text style={styles.message_text}>
                Mark some shows and they'll show up here if they're airing soon!
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    );
  }

  const TODAY = new Date();
  const LAST_WEEK = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate() - 7
  );
  const TWO_WEEKS_HENCE = new Date(
    TODAY.getFullYear(),
    TODAY.getMonth(),
    TODAY.getDate() + 15
  );

  const alreadyOutShows = filter_embed_eps(shows, LAST_WEEK, TODAY);
  const upcomingShows = filter_embed_eps(shows, TODAY, TWO_WEEKS_HENCE);

  const sections = [
    { title: "Out Now", data: alreadyOutShows },
    { title: "Out Soon", data: upcomingShows },
  ];

  if (!alreadyOutShows.length && !upcomingShows.length) {
    return (
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={onUserRefresh}
            tintColor={colors.main_purple}
          />
        }
      >
        <View style={styles.list}>
          <View style={styles.sect_header}>
            <Text style={styles.sect_header_title}>Upcoming</Text>
          </View>
          {!profile ? (
            <View style={styles.message}>
              <Text style={styles.message_text}>
                Sorry, none of the shows you've liked or on your watchlist are
                coming up.
              </Text>
              <Text style={styles.message_text}>
                Add some more shows and they'll show up here if they're airing
                soon!
              </Text>
            </View>
          ) : (
            <></>
          )}
        </View>
      </ScrollView>
    );
  }

  return (
    <SectionList
      refreshControl={
        <RefreshControl
          refreshing={isRefreshing}
          onRefresh={onUserRefresh}
          tintColor={colors.main_purple}
        />
      }
      getItemLayout={(data, index) => {
        return { length: 110, offset: 110 * index, index };
      }}
      ref={sectionListRef}
      sections={sections}
      style={styles.list}
      stickySectionHeadersEnabled={false}
      showsVerticalScrollIndicator={false}
      keyExtractor={(item, index) => item + index}
      renderItem={({ item, index, section }) => (
        <Item
          item={item}
          sectionTitle={section.title}
          navigation={navigation}
        />
      )}
      renderSectionHeader={({ section: { title, data } }) => (
        <>
          {data.length ? (
            <Pressable
              style={styles.sect_header}
              onPress={() => {
                sectionListRef.current.scrollToLocation({
                  itemIndex: 0,
                  sectionIndex: title === "Out Soon" ? 0 : 1,
                });
              }}
            >
              <Text style={styles.sect_header_title}>{title}</Text>
            </Pressable>
          ) : null}
        </>
      )}
    />
  );
}

function date_helper(d) {
  return d
    .toLocaleDateString("en-US", {
      // weekday: "short",
      weekday: "short",
      month: "short",
      day: "numeric",
    })
    .split(", ");
}

const Item = ({ item, sectionTitle, navigation }) => {
  const { episode, show } = item;
  const { airstamp } = episode;

  const rg_episode = SDHelpers.findRgEpFromTvmaze(episode, show.deeplinks);

  const maybe_e = episode.number ? `E${episode.number}` : ``;
  const serviceList = show.rg_source_ids ? show.rg_source_ids : [];
  apiShowAsset.preload(show.id, "omit");

  return (
    <Pressable
      style={styles.itemContainer}
      onPress={() =>
        navigation.navigate("ShowDetail", { show_id: item.show.id })
      }
    >
      <Image
        source={{ uri: item.show.image.medium }}
        style={styles.item_image}
      />
      <View style={styles.item_detailsContainer}>
        <View style={styles.item_episodeContainer}>
          <View style={styles.item_showNameWrap}>
            <Text style={styles.item_showNameText}>{show.name}</Text>
          </View>
          <Text style={styles.item_epDescText}>
            S{episode.season}
            {maybe_e} —{" "}
            <Text style={styles.item_epNameText}>{episode.name}</Text>
          </Text>
        </View>
        <View style={styles.item_schedContainer}>
          <View style={{ marginTop: 1, marginRight: 4 }}>
            <Icons.Calendar size={15} />
          </View>
          <Text style={styles.item_dateText}>
            {Platform.OS == "android"
              ? date_helper(new Date(airstamp))[0]
              : `${date_helper(new Date(airstamp))[0]}, ${
                  date_helper(new Date(airstamp))[1]
                }`}
          </Text>
        </View>
      </View>

      {/*<View style={styles.item_servicesWrapper}>
        {serviceList.map((serviceName, index) => (
          <View key={index} style={{ marginHorizontal: 14 }}>
            <ServiceIcon source_id={serviceName} size={18} />
          </View>
        ))}
      </View>*/}

      {show.deeplinks && rg_episode && rg_episode.ep_sources ? (
        <View style={styles.deeplinkWrap}>
          {rg_episode.ep_sources.map((s) => {
            return (
              <Pressable
                style={styles.deeplinkPressable}
                key={s.source_id}
                onPress={() => {
                  open_appropriate_source(s);
                }}
              >
                <Text style={styles.deeplink}>Watch on</Text>
                <Text style={styles.deeplink}>
                  {services_by_rg[s.source_id]
                    ? services_by_rg[s.source_id]["name"]
                    : s.source_id}
                </Text>
              </Pressable>
            );
          })}
        </View>
      ) : (
        <></>
      )}
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: colors.background, flex: 1 },
  searchWidget: { position: "absolute", right: 0, top: 34 },
  titleWrapper: {
    position: "absolute",
    paddingLeft: 24,
    top: 44,
    backgroundColor: colors.background,
    width: "100%",
    paddingBottom: 8,
    zIndex: 1,
  },
  deeplinkWrap: { position: "absolute", bottom: 0, right: 0, zIndex: 3 },
  deeplinkPressable: {
    padding: 16,
  },
  deeplink: { color: colors.main_purple, textAlign: "right" },
  titleText: {
    fontWeight: "500",
    fontSize: 24,
    color: colors.text_white,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  spacer: {
    width: "100%",
    marginTop: 50,
  },
  itemContainer: {
    flexDirection: "row",
    marginBottom: 24,
    backgroundColor: colors.background_lighter,
    flex: 1,
    // paddingHorizontal: 5,
    paddingHorizontal: 16,

    paddingVertical: 15,
  },
  item_image: {
    width: 64,
    height: 96,
    borderRadius: 8,
    marginRight: 10,
    // resizeMode: "contain",
  },
  item_detailsContainer: { flex: 1 },
  item_showNameWrap: {
    flexDirection: "row",

    flex: 1,
  },
  item_showNameText: {
    color: colors.text_white,
    fontWeight: "bold",
    fontSize: 17,
    flex: 1,
    flexWrap: "wrap",
    // marginTop: 6,
  },
  item_epDescText: {
    color: colors.text_grey,
    fontSize: 13,
    marginTop: 8,
  },
  item_epNameText: {
    fontStyle: "italic",
  },
  item_schedContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  item_dateText: {
    color: colors.text_grey,
    fontSize: 13,
  },
  item_timeContainer: {
    flexDirection: "row",
    marginLeft: 16,
  },
  item_servicesWrapper: {
    flexDirection: "row",
    position: "absolute",
    top: 16,
    right: 16,
  },

  sect_header: {
    marginTop: 4,
    marginHorizontal: 4,
    padding: 8,
    marginBottom: 16,
  },
  sect_header_title: {
    color: colors.text_grey,
    fontSize: 16,
  },
  list: {
    marginTop: 24,
    paddingHorizontal: 14,
    borderRadius: 8,
  },

  message: { marginTop: 50, padding: 30, marginBottom: 25 },
  message_text: {
    color: colors.text_grey,
    fontSize: 14,
    marginBottom: 16,
    textAlign: "center",
  },
  loading_message: {
    color: colors.text_grey,
    fontSize: 14,
  },

  loading: { marginTop: 75 },
});
