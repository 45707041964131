import React, { useState, useEffect, useRef, Suspense } from "react";
// prettier-ignore
import { StyleSheet, Text, Pressable, View, Modal } from "react-native";
import { Entypo } from "@expo/vector-icons";

import colors from "../colors.js";
import Popover from "react-native-popover-view";
import * as Linking from "expo-linking";

export function SummaryRatings(props) {
  const { ratings } = props;
  if (!ratings) {
    return <></>;
  }
  const filtered_ratings = ratings.filter((item) => item?.rating);
  const average_rating = filtered_ratings
    ? (filtered_ratings
        .map((item) => item.rating / item.max)
        .reduce((acc, score) => acc + score, 0) /
        filtered_ratings.length) *
      5
    : null;
  const ratio = parseFloat(average_rating) / 5;
  const style = ratio >= 0.8 ? styles.ratingGood : styles.ratingOk;
  const [isPopVisible, setIsPopVisible] = useState(false);
  const from_element = (
    <Pressable onPress={() => setIsPopVisible(!isPopVisible)}>
      <View style={styles.scoreWrapper}>
        <Text style={style}>{average_rating?.toFixed(1)}</Text>
        <Text style={styles.maxScoreText}>/5</Text>
      </View>
      <View style={styles.siteNameText}>
        <Text style={styles.extSourcesText}>
          {filtered_ratings.length} sources
        </Text>
      </View>
    </Pressable>
  );

  // threshold === 1 case
  if (filtered_ratings.length === 1) {
    const ratingVal =
      typeof filtered_ratings[0].rating === "number"
        ? filtered_ratings[0].rating.toFixed(1)
        : filtered_ratings[0].rating;
    return (
      <Pressable
        onPress={() => {
          Linking.openURL(filtered_ratings[0].url);
        }}
      >
        <View style={styles.scoreWrapper}>
          <Text style={style}>{ratingVal}</Text>
          <Text style={styles.maxScoreText}>/{filtered_ratings[0].max}</Text>
        </View>
        <View style={styles.siteNameWrapper}>
          <Text style={styles.siteNameText}>
            {getExternalSiteName(filtered_ratings[0])}
          </Text>
        </View>
      </Pressable>
    );
  }

  return (
    <Popover
      isVisible={isPopVisible}
      onRequestClose={() => setIsPopVisible(false)}
      from={from_element}
      popoverStyle={{ backgroundColor: colors.backgroundColor }}
      arrowStyle={{
        backgroundColor: colors.background_lighter,
        height: 30,
        width: 50,
      }}
    >
      <View style={styles.popInner}>
        {filtered_ratings.map((rating, i) => {
          return <ExtRatingItem rating={rating} key={i} />;
        })}
      </View>
    </Popover>
  );
}

function ExtRatingItem(props) {
  const { rating } = props;
  if (!rating || rating.rating === null) {
    return <></>;
  }

  const ratingVal =
    typeof rating.rating === "number"
      ? rating.rating.toFixed(1)
      : rating.rating;
  const ratio = parseFloat(rating.rating) / parseFloat(rating.max);
  const style = ratio >= 0.8 ? styles.ratingGood : styles.ratingOk;

  return (
    <View style={styles.ratingsItemContainer}>
      <Pressable
        onPress={() => {
          Linking.openURL(rating.url);
        }}
      >
        <View style={styles.scoreWrapper}>
          <Text style={style}>{ratingVal}</Text>
          <Text style={styles.maxScoreText}>/{rating.max}</Text>
        </View>
        <View style={styles.siteNameWrapper}>
          <Text style={styles.siteNameText}>{getExternalSiteName(rating)}</Text>
        </View>
      </Pressable>
    </View>
  );
}

function getExternalSiteName(rating) {
  const { url, isSTV } = rating;
  if (isSTV) {
    return "SeekingTV";
  }
  if (url.includes("tvmaze.com")) {
    return "TVmaze";
  }
  if (url.includes("metacritic.com")) {
    return "Metacritic";
  }
  if (url.includes("imdb.com")) {
    return "IMDb";
  }
}

const styles = StyleSheet.create({
  averageRatingText: {
    color: colors.text_grey,
    fontSize: 16,
  },
  popInner: {
    backgroundColor: colors.background_lighter,
    padding: 16,

    borderRadius: 6,
    flexDirection: "row",
  },
  ratingsItemContainer: {
    marginHorizontal: 12,
  },
  scoreWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  ratingGood: {
    color: colors.text_white,
    fontWeight: "bold",
    padding: 3,
    paddingVertical: 4,
    borderRadius: 8,
    overflow: "hidden",
    backgroundColor: "#005335",
  },
  ratingOk: {
    color: colors.text_white,
    fontWeight: "bold",
    padding: 3,
    paddingVertical: 4,
    borderRadius: 8,
    overflow: "hidden",
    // backgroundColor: "#9b8c03",
    backgroundColor: "rgb(128, 115, 0)",
  },
  maxScoreText: { color: colors.text_grey, fontSize: 12, marginLeft: 2 },
  siteNameWrapper: {
    alignItems: "center",
  },
  siteNameText: {
    color: colors.text_white,
    fontSize: 12,
  },
  extSourcesText: {
    color: colors.text_white,
    fontSize: 12,
  },
});
