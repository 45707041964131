import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Platform, FlatList, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";
import useLocalStore from "../stores/local_store";

import { Entypo } from "@expo/vector-icons";
import colors from "../colors.js";

const ROUTE_NAME = {
  WatchList: "WatchList",
  Feed: "Feed",
};
const KIND_OR_KEY = {
  WatchList: "kind",
  Feed: "key",
};

const PARAM_TO_LS_KEY = {
  WatchList: "last_list_screen_kind",
  Feed: "last_feed_screen_key",
};

export function ListPickerDropdown(props) {
  const { keyToLabel, paramVal } = props;
  const [isOpen, setIsOpen] = useState(false);
  const local_store_actions = useLocalStore(
    (state) => state.local_store_actions
  );

  return (
    <View style={styles.topBarContainer}>
      <Pressable name={keyToLabel[paramVal]} onPress={() => setIsOpen(!isOpen)}>
        <Text style={styles.pickerTextActive}>
          {keyToLabel[paramVal]}{" "}
          <Entypo name="chevron-down" size={24} color={colors.text_white} />
        </Text>
      </Pressable>

      <View style={styles.pickerInner}>
        {!isOpen ? (
          <></>
        ) : (
          Object.keys(keyToLabel).map((key) => {
            if (key === paramVal) {
              return <View key={key} />;
            }
            return (
              <Pressable
                onPress={() => {
                  setIsOpen(false);
                  local_store_actions.setUserData(
                    PARAM_TO_LS_KEY[props.route.name],
                    key
                  );
                  props.navigation.navigate(ROUTE_NAME[props.route.name], {
                    [KIND_OR_KEY[props.route.name]]: key,
                  });
                }}
                key={key}
                style={styles.pickerPress}
              >
                <Text
                  key={key}
                  style={
                    key === props.route.name
                      ? styles.pickerTextActive
                      : styles.pickerText
                  }
                >
                  {keyToLabel[key]}
                </Text>
              </Pressable>
            );
          })
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  topBarContainer: {
    position: "absolute",
    minHeight: 100,
    left: 24,
    top: 44,
    zIndex: 5,
    justifyContent: "space-between",
  },

  pickerInner: {
    position: "absolute",
    marginTop: 30,
    width: "100%",
    backgroundColor: colors.background_lighter_alpha,

    borderRadius: 12,
    borderColor: colors.background,
    borderWidth: 1,
  },
  pickerPress: {
    padding: 10,
  },
  pickerTextActive: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
  },
  pickerText: {
    fontSize: 18,
    alignSelf: "flex-start",
    color: colors.main_purple,
  },
});
