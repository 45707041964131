import React, { useState, useEffect } from "react";
// prettier-ignore
import { StyleSheet, Text, Platform, FlatList, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { AntDesign } from "@expo/vector-icons";

const MARK_KIND_TO_LABEL = {
  "add-to-watchlist": { text: "In Watchlist", name: "checkcircleo" },
  "seen-liked": { text: "Liked", name: "smile-circle" },
  "seen-disliked": { text: "Disiked", name: "frown" },
  removed: { text: "Add to Watchlist", name: "pluscircle" },
};

// the key is the current one, options are what's available for that, and then label maps to those options
// prettier-ignore
const MARK_KIND_TO_ACTION = {
  "add-to-watchlist": {label:"Add to WatchList", options:["seen-liked", "seen-disliked","removed"]   },
  "seen-liked": {label: "Mark Liked", options:["add-to-watchlist","seen-disliked", "removed"]  },
  "seen-disliked": {label:"Mark Disliked", options:["add-to-watchlist", "seen-liked", "removed"]   },
  "removed": {label:"Remove", options:["add-to-watchlist", "seen-liked", "seen-disliked"]   },
};

function get_action_sheet_opts(cur_kind) {
  // making sure to copy the array
  // prettier-ignore
  const options_as_kinds = MARK_KIND_TO_ACTION[cur_kind]["options"].map(x=>x);

  // prettier-ignore
  const options_as_labels = options_as_kinds.map((x) => MARK_KIND_TO_ACTION[x]["label"]);
  // options_as_kinds.push("removed");
  // options_as_labels.push("Remove");
  const destructiveButtonIndex =
    cur_kind === "removed" ? undefined : options_as_labels.length - 1;
  options_as_kinds.push("cancel");
  options_as_labels.push("Cancel");
  const cancelButtonIndex = options_as_labels.length - 1;
  return {
    options_as_kinds,
    options_as_labels,
    destructiveButtonIndex,
    cancelButtonIndex,
  };
}

// eventually need some sort of handling for when it's not list on any list, if extant is null
export function ActionMarkButton(props) {
  const { showActionSheetWithOptions } = useActionSheet();
  const { user_marks } = props;
  // const user_marks = useSeekStore((state) => state.user_marks);
  const extant = user_marks.find((m) => m.tvmaze_id === props.show_id);

  const { text, name } = extant
    ? MARK_KIND_TO_LABEL[extant.kind]
    : { text: "Mark it...", name: "star" };
  const ext_kind = extant ? extant.kind : "removed";
  // prettier-ignore
  const { options_as_kinds, options_as_labels, destructiveButtonIndex, cancelButtonIndex} = get_action_sheet_opts(ext_kind);
  return (
    <Pressable
      style={styles.actionMarkButton}
      onPress={() => {
        showActionSheetWithOptions(
          {
            options: options_as_labels,
            destructiveButtonIndex,
            cancelButtonIndex,
          },
          (buttonIndex) => {
            props.onSelect(options_as_kinds[buttonIndex], buttonIndex);
          }
        );
      }}
    >
      <AntDesign name={name} size={22} color="rgb(230,230,230)" />
      <Text style={styles.actionMarkButtonText}>{text}</Text>
    </Pressable>
  );
}

const styles = StyleSheet.create({
  actionMarkButton: {
    marginTop: 15,
    flexDirection: "row",
    backgroundColor: "rgba(11, 106, 255, 0.8)",
    borderWidth: 1,
    padding: 10,
    borderRadius: 6,
    marginLeft: 30,
    marginRight: 30,
    justifyContent: "center",
    alignItems: "center",
  },
  actionMarkButtonText: {
    color: "rgb(230,230,230)",
    fontSize: 18,
    marginLeft: 5,
  },
});
