// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo, useCallback } from "react";
// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { useNavigation } from "@react-navigation/native";

import AllAssets from "../helpers/assets.js";
// prettier-ignore
const { apiShowAsset  } = AllAssets;

import { PlayViaSourcesItem } from "./PlayViaSources.js";

import colors from "../colors.js";
import * as Icons from "./Icons.js";
import * as SDHelpers from "../helpers/formatting_helpers.js";

import * as core_maze_logic from "./../../app_shared/core_maze_logic.js";
const { get_upcoming, maze_url_to_slug, maze_ep_to_slug } = core_maze_logic;

export function ShowScheduleInfo(props) {
  const navigation = useNavigation();
  const { user_services, show_id } = props;

  const show_data = apiShowAsset.read(show_id, true);
  const upcoming = get_upcoming(show_data);

  // const { show_data, user_services } = props;
  const { next_ep, prev_ep, next_air_s } = upcoming;
  const season_count = SDHelpers.getSeasonCount(show_data);

  const sources =
    show_data.deeplinks?.length && show_data.deeplinks[0]["ep_sources"]
      ? show_data.deeplinks[0]["ep_sources"]
      : [];

  const maybe_show_status = show_data.status ? (
    <View style={styles.showStatus}>
      <Text style={styles.showStatusText}>
        Status: {SDHelpers.getShowStatus(show_data.status)}
      </Text>
    </View>
  ) : (
    <></>
  );

  const gotoFirstEp = () => {
    navigation.navigate("EpisodeDetailScreen", {
      show_id: show_data.id,
      ep_slug: maze_ep_to_slug(show_data._embedded.episodes[0]),
    });
  };
  const gotoLatestEp = () => {
    navigation.navigate("EpisodeDetailScreen", {
      show_id: show_data.id,
      ep_slug: maze_ep_to_slug(prev_ep),
    });
  };

  return (
    <>
      {!next_ep && !prev_ep ? (
        <View style={styles.scheduleContainer}>{maybe_show_status}</View>
      ) : (
        <>
          <View style={styles.headerWrapper}>
            <Text style={styles.headerText}>
              {prev_ep ? `Currently available` : ``}
            </Text>
          </View>
          <View
            style={
              show_data?.deeplinks
                ? styles.scheduleContainerWithDeeplinks
                : styles.scheduleContainer
            }
          >
            <View style={styles.seasonOverviewContainer}>
              <Pressable
                style={styles.overviewPressable}
                onPress={() => {
                  prev_ep
                    ? navigation.navigate("Episodes", {
                        show_id: show_data.id,
                      })
                    : () => {};
                }}
              >
                <Text style={styles.seasonOverviewText}>
                  {season_count}{" "}
                  {season_count === 1
                    ? "season"
                    : season_count > 1000
                    ? ""
                    : "seasons"}
                </Text>
                <Suspense fallback={<TinyIndicator />}>
                  <EpisodeCountComp show_id={show_data.id} prepend={"·"} />
                </Suspense>
              </Pressable>
              {maybe_show_status}
            </View>
            {/* Pilot */}
            {show_data?.deeplinks && show_data?.deeplinks[0] ? (
              <View style={styles.recentEpContainer}>
                <Text style={styles.recentEpTitleText} onPress={gotoFirstEp}>
                  Pilot
                </Text>
                <Text style={styles.epDescText} onPress={gotoFirstEp}>
                  S{SDHelpers.getEpSeason(show_data.deeplinks[0])} E
                  {SDHelpers.getEpNumber(show_data.deeplinks[0])} —{" "}
                  <Text style={styles.epNameText}>
                    {SDHelpers.getEpName(show_data.deeplinks[0])}
                  </Text>
                </Text>
                <PlayViaSourcesItem
                  sources={show_data.deeplinks[0].ep_sources}
                  user_services={user_services}
                  show_data={show_data}
                />
              </View>
            ) : (
              <></>
            )}
            {/* Latest episode */}
            <View style={styles.recentEpContainer}>
              <Text style={styles.recentEpTitleText} onPress={gotoLatestEp}>
                Latest
              </Text>
              <Text style={styles.epDescText} onPress={gotoLatestEp}>
                S{SDHelpers.getEpSeason(prev_ep)} E
                {SDHelpers.getEpNumber(prev_ep)} —{" "}
                <Text style={styles.epNameText}>
                  {SDHelpers.getEpName(prev_ep)}
                </Text>
              </Text>
              <PlayViaSourcesItem
                sources={sources}
                user_services={user_services}
                show_data={show_data}
              />
            </View>
            {/* Upcoming episode */}
            <>
              {next_ep ? (
                <View style={styles.recentEpContainer}>
                  <Text style={styles.recentEpTitleText}>Upcoming</Text>
                  <Text style={styles.epDescText}>
                    S{SDHelpers.getEpSeason(next_ep)} E
                    {SDHelpers.getEpNumber(next_ep)} —{" "}
                    <Text style={styles.epNameText}>
                      {SDHelpers.getEpName(next_ep)}
                    </Text>
                  </Text>
                  <View style={styles.epDateWrapper}>
                    {next_air_s ? (
                      <>
                        <View style={{ marginTop: 1 }}>
                          <Icons.Calendar size={15} />
                        </View>
                        <Text style={styles.epDateText}>{next_air_s}</Text>
                      </>
                    ) : (
                      <></>
                    )}
                  </View>
                </View>
              ) : (
                <></>
              )}
            </>
          </View>
          {/* See all episodes button */}
          {prev_ep ? (
            <Pressable
              onPress={() => {
                navigation.navigate("Episodes", {
                  show_id: show_data.id,
                });
              }}
              style={styles.seeAllEpisodesButton}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: colors.main_purple,
                }}
              >
                See all episodes
              </Text>
            </Pressable>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

export function EpisodeCountComp(props) {
  const show_data = apiShowAsset.peek(props.show_id, true)
    ? apiShowAsset.peek(props.show_id, true)
    : apiShowAsset.read(props.show_id);

  const episode_count = SDHelpers.getEpisodeCount(show_data);
  return (
    <Text style={props.style || styles.seasonOverviewText}>
      {props.prepend ? ` ${props.prepend} ` : null}
      {episode_count} {episode_count === 1 ? "episode" : "episodes"}
    </Text>
  );
}

function TinyIndicator(props) {
  return (
    <View style={styles.TinyIndicator}>
      <ActivityIndicator color={colors.main_purple} />
    </View>
  );
}

const styles = StyleSheet.create({
  scheduleContainer: {
    marginHorizontal: 24,
    marginTop: 24,
    paddingHorizontal: 18,
    paddingTop: 13,
    backgroundColor: colors.background_lighter,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    marginBottom: 24,
    paddingBottom: 24,
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 8,
  },
  TinyIndicator: { marginTop: -2, marginLeft: 8 },
  scheduleContainerWithDeeplinks: {
    marginHorizontal: 24,
    marginTop: 24,
    paddingHorizontal: 18,
    paddingTop: 13,
    backgroundColor: colors.background_lighter,
    borderTopStartRadius: 8,
    borderTopEndRadius: 8,
    marginBottom: 0,
    paddingBottom: 24,
  },
  headerWrapper: { marginHorizontal: 24, marginTop: 24 },
  headerText: { color: "white", fontSize: 18, fontWeight: "bold" },
  seasonOverviewContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  overviewPressable: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "baseline",
  },
  seasonOverviewText: {
    fontSize: 13,
    color: colors.main_purple,
    fontWeight: "bold",
  },
  showStatusText: {
    fontSize: 12,
    color: colors.text_grey,
  },
  recentEpContainer: {
    marginTop: 24,
    // marginBottom: 16
  },
  recentEpTitleText: {
    fontSize: 12,
    color: colors.text_grey,
    fontWeight: "bold",
  },
  epDescText: {
    marginTop: 5,
    fontSize: 16,
    fontWeight: "bold",
    color: colors.text_white,
  },
  epNameText: {
    fontSize: 16,
    fontWeight: "bold",
    fontStyle: "italic",
    color: colors.text_white,
  },
  epDateWrapper: { flexDirection: "row", marginTop: 8 },
  epDateText: { marginLeft: 3, fontSize: 13, color: colors.text_grey },
  seeAllEpisodesButton: {
    alignItems: "center",
    backgroundColor: "#211E40",
    // backgroundColor: "#312E60",
    marginHorizontal: 24,
    padding: 10,
    borderBottomEndRadius: 8,
    borderBottomStartRadius: 8,
    marginBottom: 24,
  },
});
