import React, { Suspense, useRef, useState, useEffect } from "react";
import { AntDesign, Feather } from "@expo/vector-icons";
import { BlurView } from "expo-blur";
import colors from "../colors.js";
import { LinearGradient } from "expo-linear-gradient";
import relativeDate from "tiny-relative-date";

import { useNavigation } from "@react-navigation/native";

import AllAssets from "../helpers/assets.js";
const { apiShowAsset } = AllAssets;

import * as Icons from "./Icons.js";
import * as SDHelpers from "../helpers/formatting_helpers.js";
import { ServiceIcon } from "./ServiceIcon.js";
import { PlayViaSourcesItem } from "./PlayViaSources.js";
import { ActionMarkButton } from "./ActionMarkButton.js";
import * as Toast from "./Toast.js";

// prettier-ignore
import {maze_ep_to_slug, is_ep_within_specifier, get_next_ep_for_user} from "../../app_shared/core_maze_logic.js";

// prettier-ignore
import { StyleSheet, Animated, Text, SafeAreaView, Platform, FlatList, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";

export function TvShowListItem(props) {
  const { show_data } = props;
  const year =
    show_data && show_data.premiered
      ? `${show_data.premiered.split("-")[0]}`
      : "";
  const genres = SDHelpers.getGenres(show_data);

  return (
    <Pressable onPress={props.onPress} style={styles.showListItemContainer}>
      {show_data && show_data.image ? (
        <Image
          source={{ uri: show_data.image.medium }}
          style={styles.showImage}
        />
      ) : (
        <View style={styles.showImage} />
      )}

      <View style={styles.showInfoContainer}>
        <Text style={styles.showTitleText} onPress={props.onPress}>
          {show_data ? show_data.name : null}
        </Text>
        <Text style={styles.showDetailText}>
          {year}
          {genres && year ? "  |  " : ""}
          {genres}
        </Text>
        <SourcesServiceIcons show_data={show_data} />
        {/* removed props.children*/}
      </View>
      {props.message ? (
        <Text style={[styles.showDetailText, { maxWidth: 100 }]}>
          {props.message}
        </Text>
      ) : (
        <></>
      )}
    </Pressable>
  );
}

export function TvShowListProgressiveItem(props) {
  return (
    <Suspense fallback={<TvShowListItem {...props} />}>
      <TvShowListLoadingAssetItem {...props} />
    </Suspense>
  );
}
function TvShowListLoadingAssetItem(props) {
  // XXX slightly hacky and confusing
  // if it's a search result, use the id from that - if it's a mark (in watchlist), use that id
  const id = props.show_data ? props.show_data.id : props.tvmaze_id;
  const show_data = apiShowAsset.read(id, "omit");
  return <TvShowListItem {...props} show_data={show_data} />;
}

export function SourcesServiceIcons(props) {
  const { show_data } = props;
  if (!show_data || !show_data.rg_source_ids) {
    return <></>;
  }
  const rg_source_ids = [...new Set(show_data.rg_source_ids)];
  return (
    <View style={styles.servicesContainer}>
      {rg_source_ids.map((serviceName, index) => (
        <View key={index} style={{ marginLeft: 16 }}>
          <ServiceIcon source_id={serviceName} size={18} />
        </View>
      ))}
    </View>
  );
}

export function TvShowWithNextUpItem(props) {
  // console.log(props);
  // load show *with the eps*
  // const show = apiShowAsset.read(tvmaze_id, true);
  return (
    <Suspense fallback={<TvShowListItem {...props} />}>
      <TvShowWithNextUpItemInner {...props} />
    </Suspense>
  );
}

function TvShowWithNextUpItemInner(props) {
  const navigation = useNavigation();

  const { mark, seen_episodes, user_services, user_uid, seek_actions } = props;
  const { onTopPress } = props;
  const tvmaze_show_id = mark.tvmaze_id;
  const show = apiShowAsset.read(tvmaze_show_id, true);
  const fadeAnim = useRef(new Animated.Value(1)).current;

  const seen_show_episodes = seen_episodes.filter((rec) => {
    return rec["tvmaze_show_id"] === tvmaze_show_id;
  });

  const next_up = get_next_ep_for_user(
    show._embedded.episodes,
    seen_show_episodes,
    mark.caught_up_till
  );

  const translateX = fadeAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [200, 0],
  });
  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [next_up?.id]);

  if (!next_up) {
    return (
      <TvShowListItem
        {...props}
        onPress={onTopPress}
        show_data={show}
        message="You're all caught up!"
      />
    );
  }

  const rg_episode = SDHelpers.findRgEpFromTvmaze(next_up, show.deeplinks);

  const ep_date = new Date(next_up.airstamp);

  const extant = seen_show_episodes.find(
    (m) => m.tvmaze_episode_id === next_up.id
  );
  const has_watched = extant ? extant.has_watched : false;

  // prettier-ignore
  if (next_up?.image?.medium) { Image.prefetch(next_up.image.medium); }

  const onSeenEpPress = async () => {
    if (!user_uid) {
      Toast.alert(
        {
          title: "Please Login",
          description: "Sorry, you must login to mark episodes as seen",
        },
        "danger"
      );
      return;
    }
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
    }).start(async ({ finished }) => {
      await seek_actions.setSeenEp(!has_watched, extant?.id, {
        rg_episode_id: rg_episode?.episode_id || false,
        rg_show_id: rg_episode?.show_id || false,
        tvmaze_episode_id: next_up.id,
        tvmaze_show_id: tvmaze_show_id,
      });
    });
  };

  const onPress = () => {
    navigation.navigate("EpisodeDetailScreen", {
      show_id: tvmaze_show_id,
      ep_slug: maze_ep_to_slug(next_up),
    });
  };

  return (
    <Animated.View
      style={[
        styles.episodeListItemContainer,
        {
          opacity: fadeAnim,
          transform: [{ translateX }],
        },
      ]}
    >
      <Pressable style={styles.epImageWrap} onPress={onPress}>
        {next_up && next_up.image ? (
          <Image
            source={{ uri: next_up.image.medium }}
            style={styles.episodeImage}
            resizeMode="cover"
          />
        ) : show.image ? (
          <View style={[styles.episodeImage, { overflow: "hidden" }]}>
            <Image
              source={{ uri: show.image.medium }}
              style={[styles.episodeImage, { height: 500 }]}
              resizeMode="cover"
            />
          </View>
        ) : (
          <View style={styles.episodeImage} />
        )}
        <LinearGradient
          colors={["transparent", "rgba(0,0,0,0.9)"]}
          style={styles.gradientBackground}
        />
        <View style={styles.episodeInfo}>
          <Text style={styles.showTitleText}>{show.name}</Text>
          <Text style={styles.showDetailText}>
            S{SDHelpers.getEpSeason(next_up)} E{SDHelpers.getEpNumber(next_up)}:{" "}
            {SDHelpers.getEpName(next_up)}
          </Text>

          {next_up.airstamp ? (
            <Text style={styles.showDetailText}>
              {ep_date.toLocaleString().split(",")[0]}{" "}
              {relativeDate(ep_date) === "over a year ago"
                ? null
                : ` - ${relativeDate(ep_date)}`}
            </Text>
          ) : (
            <Text style={styles.showDetailText}>Airs: TBA</Text>
          )}
        </View>
      </Pressable>
      <View style={styles.lower}>
        {rg_episode?.ep_sources ? (
          <PlayViaSourcesItem
            sources={rg_episode.ep_sources}
            user_services={user_services}
          />
        ) : (
          <View></View>
        )}
      </View>

      <Pressable
        onPress={onSeenEpPress}
        style={[
          styles.seenButton,
          {
            backgroundColor: !user_uid ? "#4D4A66" : "#211E40",
          },
        ]}
      >
        <AntDesign
          name={has_watched ? "checkcircle" : "check"}
          size={14}
          color={has_watched ? colors.main_purple : colors.aquamarine}
        />
        <Text
          style={{
            fontSize: 13,
            color: has_watched ? colors.aquamarine : colors.aquamarine,
            marginLeft: 4,
          }}
        >
          {has_watched
            ? `Marked Seen`
            : `Mark S${SDHelpers.getEpSeason(next_up)} E${SDHelpers.getEpNumber(
                next_up
              )} as Seen`}
        </Text>
      </Pressable>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  showListItemContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.background_lighter,
    marginBottom: 12,
    padding: 8,
    borderRadius: 8,
    minHeight: 80,
  },
  showImage: {
    width: 64,
    height: 97,
    borderRadius: 4,
    backgroundColor: "black",
  },
  episodeListItemContainer: {
    flex: 1,
    flexDirection: "column",
    backgroundColor: colors.background_lighter,
    marginBottom: 16,
    marginTop: 16,
    // padding: 8,
    borderRadius: 8,
    minHeight: 80,
  },
  gradientBackground: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 120,
  },
  episodeInfo: {
    position: "absolute",
    left: 8,
    justifyContent: "flex-end",
    bottom: 4,
  },
  lower: {
    marginLeft: 8,
    marginRight: 8,
    justifyContent: "center",
  },
  episodeImage: {
    width: "100%",
    height: 150,
    marginBottom: 4,
  },

  seenButton: {
    backgroundColor: "#211E40",
    padding: 8,
    marginTop: 4,
    alignItems: "center",
    borderBottomStartRadius: 8,
    borderBottomEndRadius: 8,
    flexDirection: "row",
    justifyContent: "center",
  },

  showInfoContainer: {
    flex: 1,
    marginLeft: 9,
  },
  showTitleText: {
    color: colors.text_white,
    fontWeight: "bold",
    fontSize: 17,
  },
  showDetailText: {
    color: colors.text_grey,
    fontSize: 13,
    marginTop: 8,
  },
  servicesContainer: {
    flexDirection: "row",
    // justifyContent: "flex-end",
    // marginTop: 25,
    position: "absolute",
    right: 5,
    bottom: 5,
  },
});
