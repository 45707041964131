import React, { useState, useEffect, Suspense } from "react";

import { StyleSheet, Text, View } from "react-native";

import * as Icons from "./Icons.js";
import colors from "../colors.js";

export const ICON_SCREEN_MAP = {
  Swipe: Icons.Binoculars,
  WatchList: Icons.BookmarkEmpty,
  Feed: Icons.Users,
  Schedule: Icons.Calendar,
  Profile: Icons.Gear,
};

export function TabIcon({ route_name, color }) {
  const Comp = ICON_SCREEN_MAP[route_name];

  const comp_props = { size: 25, style: { padding: 5 } };

  if (["Swipe"].includes(route_name)) {
    comp_props["fill"] = color;
  } else {
    comp_props["stroke"] = color;
  }

  if ("WatchList" === route_name) {
    comp_props["viewBox"] = "-2 -1 20 20";
  }

  if ("Swipe" === route_name) {
    comp_props["viewBox"] = "4 -1 25 25";
  }

  // for debug
  // comp_props["style"] = { backgroundColor: "red", padding: 5 };

  return <Comp {...comp_props} />;
}
