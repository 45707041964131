import React, { useState, useEffect, useMemo } from "react";
// prettier-ignore
import { StyleSheet, Text, Animated, Platform, FlatList, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";
// import { useActionSheet } from "@expo/react-native-action-sheet";
// import { AntDesign } from "@expo/vector-icons";
// adapted/updated from
// https://github.com/HandlebarLabs/react-native-examples-and-tutorials/blob/master/tutorials/progressive-image-loading/ProgressiveImage.js

export function ProgressiveImage(props) {
  // console.log("ProgressiveImage render !");

  //prettier-ignore
  const thumbnailSource = useMemo(()=>({ uri: props.thumbnailSourceUri }))
  //prettier-ignore
  const source = useMemo(()=>({ uri: props.sourceUri }))
  const style = useMemo(() => ({ flex: props.flex }));

  // const thumbnailAnimated = new Animated.Value(0); // const imageAnimated = new Animated.Value(0);
  const [isFullLoaded, setIsFullLoaded] = useState(false);
  useEffect(() => {
    setIsFullLoaded(false);
  }, [props.sourceUri]);

  const handleThumbnailLoad = () => {
    //
  };

  const onImageLoad = () => {
    setIsFullLoaded(true);
  };

  return (
    <View style={style}>
      <Image
        source={thumbnailSource}
        style={[styles.imageOverlay, { zIndex: isFullLoaded ? 1 : 2 }]}
        blurRadius={1}
        onLoad={handleThumbnailLoad}
        resizeMode={props.resizeMode}
      />
      <Image
        source={source}
        style={[styles.imageOverlay, { zIndex: isFullLoaded ? 2 : 1 }]}
        onLoad={onImageLoad}
        resizeMode={props.resizeMode}
      />
    </View>
  );
}

export default React.memo(ProgressiveImage);

const styles = StyleSheet.create({
  imageOverlay: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
});
