import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, Text, View, ImageBackground, Button, ActivityIndicator } from "react-native";
import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

// modified from https://reactjs.org/docs/error-boundaries.html
class WebErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    return (
      <View style={this.props.style}>
        {this.state.hasError ? this.props.fallback : this.props.children}
      </View>
    );
  }
}

// const ErrorBoundary = Platform.OS !== "web" ? Sentry.Native.ErrorBoundary : View;

export const ErrorBoundary =
  Platform.OS !== "web" ? Sentry.Native.ErrorBoundary : WebErrorBoundary;
