import React, { Suspense, useRef, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
// prettier-ignore
import {  Modal, Pressable, ImageBackground, StyleSheet, View, Text } from "react-native";
// import { useWindowDims } from "../helpers/dimsHook.js";

import Svg, { G, Rect, Path, Defs, ClipPath } from "react-native-svg";

import * as Icons from "./Icons.js";

export function MagnifyingGlass(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.MagnifyingGlass size={props.size} />
    </Pressable>
  );
}

export function Calendar(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Calendar size={props.size} />
    </Pressable>
  );
}

export function PlayButton(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.PlayButton size={props.size} />
    </Pressable>
  );
}

export function BackArrowCircle(props) {
  return (
    <Pressable onPress={props.onPress} style={props.style}>
      <Icons.BackArrowCircle size={props.size} />
    </Pressable>
  );
}

export function BackArrow(props) {
  return (
    <Pressable onPress={props.onPress} style={props.style}>
      <Icons.BackArrow size={props.size} />
    </Pressable>
  );
}

export function PlusCircle(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.PlusCircle size={props.size} />
    </Pressable>
  );
}

export function Trashcan(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Trashcan size={props.size} />
    </Pressable>
  );
}

export function HeartFull(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.HeartFull size={props.size} />
    </Pressable>
  );
}

export function HeartEmpty(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.HeartEmpty size={props.size} />
    </Pressable>
  );
}

export function HeartCircle(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.HeartCircle size={props.size} />
    </Pressable>
  );
}

export function ThumbsDown(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.ThumbsDown size={props.size} />
    </Pressable>
  );
}

export function ThumbsDownFull(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.ThumbsDownFull size={props.size} />
    </Pressable>
  );
}

export function ThumbsDownCircle(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.ThumbsDownCircle size={props.size} />
    </Pressable>
  );
}

export function BookmarkFull(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.BookmarkFull size={props.size} />
    </Pressable>
  );
}

export function BookmarkEmpty(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.BookmarkEmpty size={props.size} />
    </Pressable>
  );
}

export function BookmarkCircle(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.BookmarkCircle size={props.size} />
    </Pressable>
  );
}

export function Clock(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Clock size={props.size} />
    </Pressable>
  );
}

export function Edit(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Edit size={props.size} />
    </Pressable>
  );
}

export function ShareIcon(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.ShareIcon size={props.size} {...props} />
    </Pressable>
  );
}

export function AddFriend(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.AddFriend size={props.size} />
    </Pressable>
  );
}

export function DownArrow(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.DownArrow size={props.size} />
    </Pressable>
  );
}

// this one is special, currently only used in the nav, it's not a square, so props.size won't work
export function Binoculars(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Binoculars size={props.size} />
    </Pressable>
  );
}

export function Users(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Users size={props.size} />
    </Pressable>
  );
}

export function Gear(props) {
  return (
    <Pressable onPress={props.onPress}>
      <Icons.Gear size={props.size} />
    </Pressable>
  );
}
