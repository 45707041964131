// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Share, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { BlurView } from "expo-blur";
import { useDebounce } from "./helpers/hooks.js";

import { AntDesign, Feather, Entypo } from "@expo/vector-icons";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import Popover from "react-native-popover-view";

import useSeekStore from "../app_shared/use-seek-store";

// import { IndicatorOverlay } from "./components/IndicatorOverlay";
// import { ActiveSwipeIndicators } from "./components/ActiveSwipeIndicators.js";
import { LinearGradient } from "expo-linear-gradient";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useIsFocused } from "@react-navigation/native";

import * as core_maze_logic from "./../app_shared/core_maze_logic.js";
const { get_upcoming, maze_url_to_slug } = core_maze_logic;

import { BackButton } from "./components/BackButton.js";
import { ProgressiveImage } from "./components/ProgressiveImage.js";
import { RateAndReviewWidget } from "./components/CurrentUserRatingAndReview.js";
import { MaybeTrailerModal } from "./components/Trailer.js";
import { ActionMarkButtonsLarge } from "./components/ActionMarkButtonsLarge.js";
import { ShowScheduleInfo } from "./components/ShowScheduleInfo.js";
import { ShowCastInfo } from "./components/ShowCastInfo.js";
import { SummaryRatings } from "./components/SummaryRatings.js";
import { NotFoundView } from "./UserPageScreen.js";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
const FH = new FSHelpers(firebase);

import AllAssets from "./helpers/assets.js";
// prettier-ignore
const { apiShowAsset, apiShowCastAsset, mazeApiShowSlugAsset } = AllAssets;

import { remove_html_tags_bad } from "./helpers/utils.js";
import { seeking_api_domain } from "../fire.js";
import Constants from "expo-constants";
const MAZE_API_DOMAIN = Constants.manifest.extra.MAZE_API_DOMAIN;

import useLocalStore from "./stores/local_store";

import colors from "./colors.js";
import * as ButtonIcons from "./components/ButtonIcons.js";
import * as SDHelpers from "./helpers/formatting_helpers.js";

export default function ShowDetailScreen(props) {
  return (
    <Suspense fallback={<Fallback />}>
      <ShowDetailInner {...props} />
    </Suspense>
  );
}
function Fallback() {
  const navigation = useNavigation();
  const navigationHistory = useNavigationState((state) => state.history);
  const [vis, setVis] = useState(false);
  const visDebounced = useDebounce(vis, 8000);
  useEffect(() => {
    setVis(true);
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.backPressable}>
        <BackButton />
      </View>
      <View style={{ marginTop: 150 }}>
        <ActivityIndicator color="white" size="large" />
      </View>
      {visDebounced ? (
        <Text
          onPress={
            navigationHistory.length === 1
              ? () => navigation.navigate("Swipe")
              : navigation.goBack
          }
          style={{
            color: "white",
            textAlign: "center",
            padding: 15,
            marginTop: 50,
          }}
        >
          It seems like something went wrong, go back?
        </Text>
      ) : null}
    </View>
  );
}

export function ShowDetailInner(props) {
  const navigation = useNavigation();
  const navigationHistory = useNavigationState((state) => state.history);
  const { params } = props.route;
  const show_data = params.show_id
    ? apiShowAsset.peek(params.show_id, true) ||
      apiShowAsset.read(params.show_id, "omit")
    : mazeApiShowSlugAsset.read(params.show_slug, "omit");

  const upcoming = get_upcoming(show_data);

  const local_keys = useLocalStore((state) => state.local_keys);
  // prettier-ignore
  const shouldPinUp = useLocalStore((state) => state.user_data[local_keys.pin_ds_up]);

  const animTarget = shouldPinUp ? 175 : 525;

  const pinUpAnim = useRef(new Animated.Value(animTarget)).current;
  useEffect(() => {
    Animated.timing(pinUpAnim, {
      toValue: animTarget,
      duration: 300,
      useNativeDriver: false,
    }).start();
  }, [animTarget]);

  const local_store_actions = useLocalStore(
    (state) => state.local_store_actions
  );

  const genres = SDHelpers.getGenres(show_data, 3);
  const premiered_year = show_data.premiered
    ? show_data.premiered.split("-")[0]
    : ``;
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const user_marks = useSeekStore((state) => state.user_marks);
  const user_services = useSeekStore((state) => state.user_services);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);
  const profile = useSeekStore((state) => state.profile);

  const [trailerMode, setTrailerMode] = useState(false);
  const [textShown, setTextShown] = useState(false);
  const [lengthMore, setLengthMore] = useState(false);
  const toggleNumberOfLines = () => {
    setTextShown(!textShown);
  };
  const onTextLayout = useCallback((e) => {
    setLengthMore(e.nativeEvent.lines.length >= 6);
  }, []);

  useEffect(() => {
    setTextShown(false);
    setLengthMore(false);
  }, [props.route.params.show_id, props.route.params.show_slug]);

  if (show_data.error) {
    return (
      <View style={styles.container}>
        <View style={styles.backPressable}>
          <BackButton />
        </View>
        <View style={styles.notFoundView}>
          <Text style={styles.notFoundText}> TV Show Not Found</Text>
          <Pressable
            onPress={
              navigationHistory.length === 1
                ? () => navigation.navigate("Swipe")
                : navigation.goBack
            }
            style={styles.backButton}
          >
            <AntDesign name="arrowleft" size={20} color="white" />
            <Text style={styles.backButtonText}>Back</Text>
          </Pressable>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      <View style={styles.backPressable}>
        <BackButton />
      </View>
      <MaybeTrailerModal
        trailerMode={trailerMode}
        setTrailerMode={setTrailerMode}
        show_data={show_data}
        upcoming={upcoming}
      />

      <ScrollView keyboardShouldPersistTaps={"handled"}>
        <View style={styles.pinUpPressable}>
          <Pressable
            onPress={() => {
              local_store_actions.setUserData(
                local_keys.pin_ds_up,
                !shouldPinUp
              );
            }}
          >
            <AntDesign
              name={shouldPinUp ? "down" : "up"}
              size={35}
              color="rgba(255,255,255,0.7)"
            />
          </Pressable>
          <ButtonIcons.ShareIcon
            size={35}
            fill="rgba(255,255,255, 0.7)"
            style={{ marginLeft: 8 }}
            onPress={async () => {
              const slug = maze_url_to_slug(show_data.url);
              const url = `https://www.seekingtv.com/tv/${slug}`;
              const result = await Share.share({ url });
            }}
          />
        </View>
        <Animated.View
          style={[styles.showImageContainer, { height: pinUpAnim }]}
        >
          <ProgressiveImage
            thumbnailSourceUri={show_data.image?.medium}
            sourceUri={show_data.image?.original}
            resizeMode="cover"
            flex={1}
          />
          <LinearGradient
            colors={["transparent", colors.background]}
            style={styles.gradientBackground}
          />
          <View style={styles.showNameTextWrapper}>
            <Text style={styles.showNameText}>
              {show_data.name}{" "}
              <Text style={styles.premiereYearText}> {premiered_year}</Text>
            </Text>
          </View>
        </Animated.View>
        <View style={styles.showDetailsContainer}>
          <Text style={styles.premiereGenreText}>{genres}</Text>
          <SummaryRatings ratings={show_data.ratings} show_data={show_data} />
        </View>
        <View style={styles.showSummaryWrapper}>
          <Text
            onTextLayout={onTextLayout}
            numberOfLines={textShown ? undefined : 6}
            style={styles.showSummaryText}
          >
            {remove_html_tags_bad(show_data.summary)}
          </Text>
          {lengthMore ? (
            <>
              {textShown ? (
                <Pressable onPress={toggleNumberOfLines}>
                  <View style={styles.readMoreWrapper}>
                    <Entypo
                      name="chevron-up"
                      size={24}
                      color={colors.text_grey}
                    />
                  </View>
                </Pressable>
              ) : (
                <Pressable onPress={toggleNumberOfLines}>
                  <LinearGradient
                    colors={["transparent", colors.background]}
                    style={styles.summaryGradientBackground}
                  />
                  <View style={styles.readMoreWrapper}>
                    <Entypo
                      name="chevron-down"
                      size={24}
                      color={colors.text_grey}
                    />
                  </View>
                </Pressable>
              )}
            </>
          ) : (
            <></>
          )}
        </View>
        <View style={styles.trailerWrapper}>
          <View style={styles.trailerButton}>
            <ButtonIcons.PlayButton
              onPress={() => setTrailerMode(!trailerMode)}
              stroke={colors.main_purple}
            />
            <Text style={styles.trailerText}>Trailer</Text>
          </View>
          <RateAndReviewWidget
            show_data={show_data}
            seek_actions={seek_actions}
            user_marks={user_marks}
          />
        </View>
        <ActionMarkButtonsLarge
          show_data={show_data}
          seek_actions={seek_actions}
          user_marks={user_marks}
        />
        <Suspense
          fallback={
            <ActivityIndicator color="white" style={{ marginTop: 16 }} />
          }
        >
          <ShowScheduleInfo
            show_id={show_data.id}
            user_services={user_services}
            upcoming={upcoming}
          />
        </Suspense>
        <Suspense fallback={<ActivityIndicator color="white" />}>
          <ShowCastInfo
            show_id={props.route.params.show_id || show_data.id}
            seek_actions={seek_actions}
            favorite_artists={favorite_artists}
            user_uid={profile?.uid}
          />
        </Suspense>
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  backPressable: {
    position: "absolute",
    top: 50,
    left: 24,
    zIndex: 5,
    padding: 5,
  },
  pinUpPressable: {
    position: "absolute",
    top: 50,
    right: 24,
    zIndex: 5,
    padding: 5,
    flexDirection: "row",
  },
  nonBlurredContent: {
    alignItems: "center",
    justifyContent: "center",
    zIndex: 4,
    width: 40,
    height: 40,
    borderRadius: 33,
  },
  // showImageContainer: { height: 500, marginTop: -25, width: "100%" },
  showImageContainer: { marginTop: -25, width: "100%" },
  gradientBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 125,
    zIndex: 4,
  },
  showNameTextWrapper: {
    position: "absolute",
    marginRight: 24,
    maxWidth: "100%",
    // bottom: 8,
    bottom: 0,
    left: 24,
    zIndex: 5,
  },
  showNameText: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
  },
  premiereYearText: {
    fontSize: 18,
    fontWeight: "400",
    color: colors.text_grey,
  },
  showDetailsContainer: {
    zIndex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 24,
    // backgroundColor: "red",
    alignItems: "center",
  },
  premiereGenreText: { fontSize: 16, color: colors.text_grey },
  showSummaryWrapper: {
    marginHorizontal: 24,
    marginTop: 16,
    paddingBottom: 16,
  },
  showSummaryText: { fontSize: 15, color: colors.text_grey },
  summaryGradientBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 125,
  },
  readMoreWrapper: {
    alignItems: "center",
  },
  trailerButton: {
    flexDirection: "row",
  },
  trailerWrapper: {
    flexDirection: "row",
    marginLeft: 24,
    marginTop: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  trailerText: {
    marginLeft: 8,
    fontSize: 18,
    fontWeight: "bold",
    color: colors.text_white,
  },
  notFoundView: { flex: 1, alignItems: "center", justifyContent: "center" },
  notFoundText: { color: colors.text_white, fontSize: 24 },
});
