import React, { useState, useEffect, useRef, Suspense } from "react";
// prettier-ignore
import { StyleSheet, FlatList, Text, ScrollView, Pressable, Button, View, Image, TextInput, ImageBackground, Switch} from "react-native";

import colors from "../colors.js";
import * as Icons from "./Icons.js";
import * as ButtonIcons from "./ButtonIcons.js";

import { firebase } from "../../fire.js";
import { FSHelpers } from "../../app_shared/fs_helpers.js";
const FH = new FSHelpers(firebase);

export function ActionMarkButtonsSmall(props) {
  // console.log(props);
  const { tvmaze_id } = props.item;
  const { user_marks, seek_actions } = props;
  const extant = user_marks.find((m) => m.tvmaze_id === tvmaze_id);
  // console.log("usermarks extant", extant);

  return (
    <View style={styles.actionIndicatorContainer}>
      {extant && extant.kind === "seen-liked" ? (
        <View style={{ marginRight: 24 }}>
          <ButtonIcons.HeartFull
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "removed",
              });
            }}
          />
        </View>
      ) : (
        <View style={{ marginRight: 24 }}>
          <ButtonIcons.HeartEmpty
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "seen-liked",
              });
            }}
          />
        </View>
      )}
      {extant && extant.kind === "seen-disliked" ? (
        <View style={{ marginRight: 24 }}>
          <ButtonIcons.ThumbsDownFull
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "removed",
              });
            }}
          />
        </View>
      ) : (
        <View style={{ marginRight: 24 }}>
          <ButtonIcons.ThumbsDown
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "seen-disliked",
              });
            }}
          />
        </View>
      )}
      {extant && extant.kind === "add-to-watchlist" ? (
        <>
          <ButtonIcons.BookmarkFull
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "removed",
              });
            }}
          />
        </>
      ) : (
        <>
          <ButtonIcons.BookmarkEmpty
            size={20}
            onPress={() => {
              seek_actions.setMark({
                tvmaze_id: tvmaze_id,
                kind: "add-to-watchlist",
              });
            }}
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  actionIndicatorContainer: {
    flexDirection: "row",
    position: "absolute",
    right: 0,
    bottom: 0,
  },
});
