import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, ScrollView, FlatList, Pressable, Button, View, Image, Platform, TextInput, ImageBackground, Switch} from "react-native";
import useSeekStore from "../app_shared/use-seek-store";

import { AntDesign } from "@expo/vector-icons";

import { LinearGradient } from "expo-linear-gradient";

import { useNavigation } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { ActionMarkButtonsSmall } from "./components/ActionMarkButtonsSmall.js";
import { ProgressiveImage } from "./components/ProgressiveImage.js";
import * as Toast from "./components/Toast.js";

import AllAssets from "./helpers/assets.js";
const { apiCastCreditsAsset, apiShowAsset, apiPersonAsset } = AllAssets;

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
import colors from "./colors.js";
import * as SDHelpers from "./helpers/formatting_helpers.js";
import * as Icons from "./components/Icons.js";
import * as ButtonIcons from "./components/ButtonIcons";

const FH = new FSHelpers(firebase);

export default function ArtistDetailScreen(props) {
  const person = apiPersonAsset.read(props.route.params.personId);
  const profile = useSeekStore((state) => state.profile);
  const user_uid = profile?.uid;
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);
  const extantArtist = favorite_artists.find(
    (m) => m.tvmaze_artist_id === person.id
  );

  const birthday = person.birthday ? (
    <View style={styles.personDetailsWrapper}>
      <Text style={styles.personDetailsTitleText}>Born</Text>
      <Text style={styles.personDetailsText}>
        {SDHelpers.getFormattedDate(person.birthday, Platform.OS)}{" "}
        {!person.deathday ? `(${SDHelpers.getAge(person.birthday)})` : ""}
      </Text>
    </View>
  ) : (
    <></>
  );

  const deathday = person.deathday ? (
    <View style={styles.personDetailsWrapper}>
      <Text style={styles.personDetailsTitleText}>Died</Text>
      <Text style={styles.personDetailsText}>
        {SDHelpers.getFormattedDate(person.deathday, Platform.OS)}{" "}
        {`(${SDHelpers.getAge(person.birthday)})`}
      </Text>
    </View>
  ) : (
    <></>
  );

  const origin = person.country?.name ? (
    <View style={styles.personDetailsWrapper}>
      <Text style={styles.personDetailsTitleText}>Origin</Text>
      <Text style={styles.personDetailsText}>{person.country.name}</Text>
    </View>
  ) : (
    <></>
  );

  return (
    <View style={styles.container}>
      <View style={styles.backPressable}>
        <ButtonIcons.BackArrowCircle
          size={40}
          onPress={props.navigation.goBack}
        />
      </View>
      <ScrollView>
        <View style={styles.personImageContainer}>
          <ProgressiveImage
            thumbnailSourceUri={person.image?.medium}
            sourceUri={person.image?.original}
            resizeMode="cover"
            flex={1}
          />
          <LinearGradient
            colors={["transparent", colors.background]}
            style={styles.gradientBackground}
          />
          <View style={styles.personNameWrapper}>
            <Text style={styles.personNameText}>{person.name}</Text>
            <View style={styles.likeArtistButton}>
              {extantArtist ? (
                <ButtonIcons.HeartFull
                  size={32}
                  onPress={() => {
                    if (!user_uid) {
                      // prettier-ignore
                      Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                      return;
                    }
                    seek_actions.setFavArt(person.id);
                  }}
                />
              ) : (
                <ButtonIcons.HeartEmpty
                  size={32}
                  onPress={() => {
                    if (!user_uid) {
                      // prettier-ignore
                      Toast.alert({title:"Please Login", description:"Sorry, you must login to save favorite artists"}, "danger");
                      return;
                    }
                    seek_actions.setFavArt(person.id);
                  }}
                />
              )}
            </View>
          </View>
        </View>

        {person.birthday || person.deathday || person.origin ? (
          <>
            <View style={styles.sectionTitleWrapper}>
              <Text style={styles.sectionTitleText}>Information</Text>
            </View>
            <View style={styles.personDetailsContainer}>
              {birthday}
              {deathday}
              {origin}
            </View>
          </>
        ) : (
          <></>
        )}
        <Suspense
          fallback={
            <ActivityIndicator style={{ marginTop: 32 }} color="white" />
          }
        >
          <CastCreditsList
            {...props}
            seek_actions={seek_actions}
            person={person}
          />
        </Suspense>
      </ScrollView>
    </View>
  );
}

function CastCreditsList(props) {
  const { seek_actions, person } = props;
  const cast_credits = apiCastCreditsAsset.read(person.id);
  const user_marks = useSeekStore((state) => state.user_marks);

  return (
    <>
      {cast_credits.length ? (
        <View style={styles.sectionTitleWrapper}>
          <Text style={styles.sectionTitleText}>TV Starring {person.name}</Text>
        </View>
      ) : (
        <></>
      )}
      <View style={styles.castCreditsContainer}>
        {cast_credits.map((item) => (
          <CastListItem key={item._embedded.character.id} item={item}>
            <ActionMarkButtonsSmall
              item={{ tvmaze_id: item._embedded.show.id }}
              user_marks={user_marks}
              seek_actions={seek_actions}
            />
          </CastListItem>
        ))}
      </View>
    </>
  );
}

function CastListItem(props) {
  const navigation = useNavigation();
  const { item } = props;
  const show = apiShowAsset.read(item._embedded.show.id, "omit");
  const characterName = item._embedded.character.name
    ? item._embedded.character.name
    : "";

  return (
    <View key={show.id.toString()} style={styles.showListItemContainer}>
      <View style={styles.characterPhotoContainer}>
        {item._embedded.character.image ? (
          <Image
            source={{ uri: item._embedded.character.image.medium }}
            style={styles.characterImage}
          />
        ) : (
          <View style={styles.characterInitialsCircle}>
            <Text style={styles.characterInitialsText}>
              {SDHelpers.getInitials(characterName)}
            </Text>
          </View>
        )}
      </View>

      <View style={styles.showInfoContainer}>
        <Pressable
          onPress={() => {
            navigation.navigate("ShowDetail", { show_id: show.id });
          }}
        >
          <Text style={styles.showNameText}>{show.name}</Text>
        </Pressable>
        <Text style={styles.characterNameText}>as {characterName}</Text>
        {props.children}
      </View>

      <Pressable
        onPress={() => {
          navigation.navigate("ShowDetail", { show_id: show.id });
        }}
      >
        {show.image && show.image.medium ? (
          <Image source={{ uri: show.image.medium }} style={styles.showImage} />
        ) : null}
      </Pressable>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  backPressable: {
    position: "absolute",
    top: "5%",
    left: 24,
    zIndex: 5,
    padding: 5,
  },
  personImageContainer: { height: 500, marginTop: -25, width: "100%" },
  gradientBackground: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: 72,
    zIndex: 4,
  },
  personNameWrapper: {
    flexDirection: "row",
    position: "absolute",
    maxWidth: "100%",
    bottom: 8,
    left: 24,
    zIndex: 5,
  },
  personNameText: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
  },
  likeArtistButton: { marginLeft: 12, marginTop: 5 },
  sectionTitleWrapper: { marginHorizontal: 24, marginTop: 24 },
  sectionTitleText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
  },
  personDetailsContainer: {
    marginHorizontal: 24,
    marginTop: 18,
    paddingHorizontal: 18,
    paddingTop: 15,
    backgroundColor: colors.background_lighter,
    borderRadius: 8,
  },
  personDetailsWrapper: { marginBottom: 15 },
  personDetailsTitleText: {
    fontSize: 12,
    fontWeight: "bold",
    color: colors.text_grey,
  },
  personDetailsText: { fontSize: 15, color: colors.text_white },
  castCreditsContainer: { marginTop: 24 },
  showListItemContainer: {
    flex: 1,
    flexDirection: "row",
    marginHorizontal: 24,
    marginBottom: 24,
    // padding: 8,
  },
  characterPhotoContainer: { marginTop: 0 },
  characterImage: { height: 60, width: 60, borderRadius: 300, marginRight: 10 },
  characterInitialsCircle: {
    width: 60,
    height: 60,
    borderRadius: 300,
    backgroundColor: colors.main_purple,
    justifyContent: "center",
    marginRight: 10,
  },
  characterInitialsText: {
    fontSize: 20,
    textAlign: "center",
    color: colors.text_white,
  },
  activityIndicatorIconWrapper: {
    position: "absolute",
    right: -8,
    top: 35,
  },
  showImage: {
    width: 64,
    height: 97,
    borderRadius: 4,
    marginLeft: 12,
  },
  showInfoContainer: {
    flex: 1,
    marginLeft: 9,
    marginTop: 0,
  },
  showNameText: {
    color: colors.text_white,
    fontSize: 16,
    fontWeight: "bold",
  },

  characterNameText: {
    color: colors.text_grey,
    fontSize: 13,
    marginTop: 5,
  },
});
