import React, { Suspense, useRef, useState } from "react";
import { AntDesign } from "@expo/vector-icons";
import { BlurView } from "expo-blur";
import colors from "../colors.js";

import AllAssets from "../helpers/assets.js";
// prettier-ignore
const { seekApiShowSearchQueryAsset, seekApiPeopleSearchQueryAsset, apiShowAsset, userPageSearchQueryAsset } = AllAssets;

import * as Icons from "./Icons.js";

import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
// prettier-ignore
import { TextInput, Modal, Animated, Pressable, FlatList, ImageBackground, StyleSheet, View, Text,ActivityIndicator } from "react-native";
import { useWindowDims } from "../helpers/dimsHook.js";
import { useDebounce } from "../helpers/hooks.js";

const PressableAnimated = Animated.createAnimatedComponent(Pressable);
const BlurViewAnimated = Animated.createAnimatedComponent(BlurView);

const MODES = {
  show: {
    keyExtractor: (item) => item.id.toString(),
    asset: seekApiShowSearchQueryAsset,
    label: "Shows",
    placeholder: `Search for a show`,
  },
  artist: {
    keyExtractor: (item) => item.id.toString(),
    asset: seekApiPeopleSearchQueryAsset,
    label: "Artists",
    placeholder: `Search for an artist`,
  },
  user: {
    keyExtractor: (item) => item.uid.toString(),
    asset: userPageSearchQueryAsset,
    label: "Users",
    placeholder: `Search by screen name`,
  },
};

export function SearchWidget(props) {
  const openAnim = useRef(new Animated.Value(0)).current;
  const nativeOpenAnim = useRef(new Animated.Value(0)).current;

  // is this whole thing active and open, or closed, just displaying a button
  const [isActive, setIsActive] = useState(false);
  const [searchMode, setSearchMode] = useState(props.initialMode || "show");

  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);

  Animated.timing(openAnim, {
    toValue: isActive ? 1 : 0,
    duration: 400,
    useNativeDriver: false,
  }).start();

  Animated.timing(nativeOpenAnim, {
    toValue: isActive ? 1 : 0,
    duration: 400,
    useNativeDriver: true,
  }).start();

  const widthAnim = openAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["10%", "90%"],
  });
  const leftAnim = openAnim.interpolate({
    inputRange: [0, 1],
    outputRange: ["80%", "5%"],
  });
  const paddingAnim = openAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 10],
  });

  const wrapStyle = isActive ? styles.wrapActive : styles.wrap;
  const searchButtonStyle = isActive
    ? styles.searchButtonActive
    : styles.searchButton;

  return (
    <>
      <BlurViewAnimated
        intensity={100}
        tint="dark"
        pointerEvents="none"
        style={[styles.BlurView, { opacity: nativeOpenAnim }]}
      />
      <Animated.View
        style={[
          wrapStyle,
          { width: widthAnim, left: leftAnim, padding: paddingAnim },
          props.style,
        ]}
      >
        <PressableAnimated
          style={searchButtonStyle}
          onPress={() => {
            setIsActive(true);
          }}
        >
          <Icons.MagnifyingGlass />
        </PressableAnimated>
        {isActive ? (
          <SearchInput
            setIsActive={setIsActive}
            searchMode={searchMode}
            setSearchValue={setSearchValue}
          />
        ) : null}
      </Animated.View>
      <ResultWrap
        isActive={isActive}
        nativeOpenAnim={nativeOpenAnim}
        searchMode={searchMode}
        setSearchMode={setSearchMode}
        debouncedSearchValue={debouncedSearchValue}
        passedProps={props}
      />
    </>
  );
}

function ResultWrap(props) {
  const { isActive, searchMode, setSearchMode, nativeOpenAnim } = props;
  const { debouncedSearchValue } = props;
  if (!isActive) {
    return null;
  }
  // console.log(debouncedSearchValue);
  // console.log(search_results);

  return (
    <Animated.View style={[styles.ResultWrap, { opacity: nativeOpenAnim }]}>
      <View style={styles.searchKindWrap}>
        {Object.keys(MODES).map((key) => {
          return (
            <Pressable
              key={key}
              onPress={() => setSearchMode(key)}
              style={
                searchMode === key
                  ? styles.searchKindPressableActive
                  : styles.searchKindPressable
              }
            >
              <Text
                style={
                  searchMode === key
                    ? styles.searchKindTextActive
                    : styles.searchKindText
                }
              >
                {MODES[key]["label"]}
              </Text>
            </Pressable>
          );
        })}
      </View>
      <Suspense
        fallback={
          <ActivityIndicator
            color="rgb(200,200,200)"
            size="large"
            style={{ marginTop: 50 }}
          />
        }
      >
        <ResultInner {...props} />
      </Suspense>
    </Animated.View>
  );
}
function ResultInner(props) {
  const windowHeight = useWindowDims().height;

  const { isActive, searchMode, setSearchMode, nativeOpenAnim } = props;
  const { debouncedSearchValue, passedProps } = props;
  const { asset, keyExtractor } = MODES[searchMode];

  const search_results = asset.read(debouncedSearchValue);

  const renderItem =
    searchMode === "show"
      ? passedProps.renderShowItem
      : searchMode === "artist"
      ? passedProps.renderArtistItem
      : passedProps.renderUserItem;

  // console.log(search_results);
  return (
    <FlatList
      data={search_results}
      ListEmptyComponent={
        <NoSearchResults
          debouncedSearchValue={debouncedSearchValue}
          label={MODES[searchMode]["label"]}
        />
      }
      initialNumToRender={5}
      keyboardShouldPersistTaps="handled"
      keyExtractor={(item) => keyExtractor(item)}
      renderItem={renderItem}
    />
  );
}

function SearchInput(props) {
  const { searchMode, setSearchValue } = props;
  const { placeholder } = MODES[searchMode];

  return (
    <>
      <TextInput
        placeholder={`${placeholder}...`}
        placeholderTextColor={colors.text_grey}
        textColor={colors.text_white}
        autoFocus={true}
        style={styles.TextInput}
        onChangeText={setSearchValue}
      />
      <AntDesign
        name="close"
        onPress={() => {
          props.setIsActive(false);
          props.setSearchValue("");
        }}
        color={colors.text_grey}
        size={25}
        style={styles.closeIcon}
      />
    </>
  );
}

function NoSearchResults(props) {
  const { debouncedSearchValue, label } = props;
  if (!debouncedSearchValue || debouncedSearchValue.length < 2) {
    return <></>;
  }
  return (
    <View style={styles.noResultView}>
      <Text style={styles.noResultText}>
        No {label} found for "{debouncedSearchValue}"
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrap: {
    flexDirection: "row",
    position: "absolute",
    alignItems: "center",
    // top: 50,
    zIndex: 10,
  },
  wrapActive: {
    alignItems: "center",
    top: 50,
    zIndex: 10,
    backgroundColor: colors.background_alpha,
    flexDirection: "row",
    position: "absolute",
    borderRadius: 25,
    borderColor: colors.main_purple,
    borderWidth: 1,
    // marginLeft: 20,
    // marginRight: 20,
  },
  searchButton: {
    borderRadius: 150,
    backgroundColor: colors.background_alpha,
    padding: 15,
    justifyContent: "center",
    alignItems: "center",
  },
  searchButtonActive: {
    // borderRadius: 150,
    // backgroundColor: colors.background_alpha,
    padding: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  TextInput: {
    width: "80%",
    padding: 10,
    // marginLeft:5,
    color: colors.text_white,
    fontSize: 18,
    backgroundColor: colors.background_lighter,
  },
  closeIcon: { padding: 10, marginLeft: -15 },
  // ResultWrap is hackily positioned, it'd be better to only use absolute pos
  // at the top level, and have ResultWrap inside, positioned normally - - but then I'd have to redo the animation to switch to dir column
  ResultWrap: {
    position: "absolute",
    top: 125,
    zIndex: 11,
    width: "100%",
    height: "90%",
  },
  searchKindWrap: {
    flexDirection: "row",
    justifyContent: "center",
    // alignItems: "space-around",
    alignItems: "center",
    padding: 20,
  },
  searchKindPressable: {
    padding: 10,
    flex: 1,
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: "rgba(0,0,0,0)",
  },
  searchKindPressableActive: {
    padding: 10,
    flex: 1,
    alignItems: "center",
    borderBottomWidth: 2,
    borderBottomColor: colors.main_purple,
  },
  searchKindText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "700",
  },
  searchKindTextActive: {
    color: colors.main_purple,
    fontSize: 18,
    fontWeight: "700",
  },
  FlatList: {
    // height: "100%",
    // flex: 1,
  },
  BlurView: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "150%",
    // windowHeight
    zIndex: 2,
  },
  noResultView: { alignItems: "center", marginTop: 50 },
  noResultText: { color: "white", fontSize: 16 },
});
