// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, Animated, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import * as Icons from "./components/Icons.js";

import useSeekStore from "../app_shared/use-seek-store";
import useLocalStore from "./stores/local_store";

import { SwipeStackView } from "./components/SwipeStackView.js";
import { TvShowCard } from "./components/TvShowCard.js";
// prettier-ignore
import { ListPickDropDown, WarningComp, HelpButton } from "./components/SwipeMisc.js";

import { SearchContainer } from "./SearchContainer.js";

import { useNavigation } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useIsFocused } from "@react-navigation/native";
import pool from "@ricokahler/pool";
import { createAsset } from "use-asset";

import { chunkArray, groupBy } from "./helpers/utils.js";
import colors from "./colors.js";

import { createStackNavigator } from "@react-navigation/stack";
const Stack = createStackNavigator();
// prettier-ignore
import { TvShowListItem, SourcesWithLoad } from "./components/TvShowListItem.js";
import { ServiceIcon } from "./components/ServiceIcon.js";
import { ActionMarkButton } from "./components/ActionMarkButton.js";
import { IntroArrows } from "./components/IntroArrows.js";

// prettier-ignore
import { useDebounce, useInterval, useFSPagination, useFSPagingMQWorkAround } from "./helpers/hooks.js";
import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
const FH = new FSHelpers(firebase);
// prettier-ignore
import { getFirestore, query, where, collection, orderBy } from "firebase/firestore/lite";
const db = getFirestore();
import AllAssets from "./helpers/assets.js";
const { apiShowAsset, apiCastCreditsAsset, get_reco_page_url } = AllAssets;

import { seeking_api_domain } from "../fire.js";
import Constants from "expo-constants";
const MAZE_API_DOMAIN = Constants.manifest.extra.MAZE_API_DOMAIN;

// XXX must match server (`functions/shows.js`)
// XXX does not apply to FriendSwipeInner
const RECO_PAGE_LENGTH = 20;

const one_day_in_ms = 8.64e7;

// https://reactnavigation.org/docs/nesting-navigators

function BlackBack(props) {
  return (
    <ImageBackground
      source={require("../assets/bg-gradient-half.png")}
      style={styles.imageBackground}
    >
      {props.children}
    </ImageBackground>
  );
}

const initialDefaultRouteName = "all";

const last_q_key_sel = (state) => state.user_data["last_swipe_screen_q_key"];
const is_loaded_sel = (state) => state.isLoaded;
const is_done_login_loading_sel = (state) => state.isDoneLoginLoading;

export default function SwipeScreen(props) {
  const initialRouteName =
    props?.route?.params?.queue_key || initialDefaultRouteName;

  return (
    <>
      <SearchContainer style={styles.SearchContainer} />
      <BlackBack>
        <Suspense
          fallback={
            <ActivityIndicator
              color="rgb(200,200,200)"
              size="large"
              style={styles.activityIndicator1}
            />
          }
        >
          <ErrorBoundary fallback={<></>} style={{ flex: 1 }}>
            <Stack.Navigator
              initialRouteName={initialRouteName}
              headerMode="none"
              screenOptions={{ cardStyle: { backgroundColor: "transparent" } }}
            >
              <Stack.Screen name="all" component={SwipingViewAll} />
              <Stack.Screen name="mine" component={SwipingViewMine} />
              {/* prettier-ignore */}
              <Stack.Screen name="friends" component={SwipingViewFriendsWrapped} />
              <Stack.Screen name="artists" component={SwipingViewFavArtists} />
            </Stack.Navigator>
          </ErrorBoundary>
        </Suspense>
      </BlackBack>
    </>
  );
}
// prettier-ignore
function usePaginatedRecoAPI({ profile, lang_arr, user_marks_ids, name, user_service_ids }) {
  const [recsArr, setRecsArr] = useState([]);
  const [noRecsFound, setNoRecsFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lastRef = useRef()


  const abortCtrl = new AbortController();
  const opts = { signal: abortCtrl.signal };
  const fetchData = async () => {
    setIsLoading(true);
    // console.log("fetch data called for", name, lastRef.current, lang_arr);
    const url = get_reco_page_url(name, lastRef.current, user_service_ids, lang_arr);
    // console.log(user_marks_ids.length);

    let resp_data;
    try {
      const resp = await fetch(url, opts);
      resp_data = await resp.json();
      lastRef.current = resp_data[resp_data.length-1]
    } catch (e) {
      // console.log("abort (or error), return");
      return;
    }

    // differentiate between server has no more recs pages VS we've seen all
    //  filter out what we've seen
    const arr = resp_data.map(({id})=>id).filter(
      (rec_show_id) => !user_marks_ids.includes(rec_show_id)
    );

    // we're at the end of the list the server has
    if (resp_data.length !== RECO_PAGE_LENGTH) {
      console.log("out of recs!");
      setNoRecsFound(true);
      setIsLoading(false);
    }
    // if we can't find any recs you haven't yet seen
    if (arr.length === 0 && resp_data.length === RECO_PAGE_LENGTH) {
      console.log("arr.length is 0: fetch more data!");
      fetchData()
      return;
    }
    // otherwise preload the first one
    apiShowAsset.preload(arr[0]);

    setRecsArr([...arr]);
    setIsLoading(false);
  };

  useEffect(() => {
    // console.log('calling effect!')
    setNoRecsFound(false);
    lastRef.current = false
    fetchData();
    return () => {
      // console.log(name, "abort to clean up!");
      abortCtrl.abort();
    };
  }, [profile?.uid, lang_arr, user_service_ids]);

  return { recsArr, noRecsFound, isLoading, setPageNum:fetchData };
} //end hook

// the individual screens

// this one is straightforward
function SwipingViewAll(props) {
  const profile = useSeekStore((state) => state.profile);
  const lang_arr = profile?.options?.lang_arr || "";

  const user_marks = useSeekStore((state) => state.user_marks);
  // console.log(user_marks);
  const user_marks_ids = user_marks.map(({ tvmaze_id }) => tvmaze_id);
  // console.log(user_marks_ids);
  const seek_actions = useSeekStore((state) => state.seek_actions);

  const name = props.route.name;

  const { recsArr, isLoading, noRecsFound, setPageNum } = usePaginatedRecoAPI({
    profile,
    lang_arr,
    user_marks_ids,
    name,
    user_service_ids: false,
  });
  // previously had the reset stuff here

  // console.log(recsArr.length);
  const setPageNumIfNotLoading = (n) => {
    console.log("called setPageNumIfNotLoading");
    if (!isLoading) {
      setPageNum(n);
    }
  };
  const extra = {
    pageNum: 0,
    setPageNum: setPageNumIfNotLoading,
    recsArr,
    noRecsFound,
    seek_actions,
  };

  return (
    <BlackBack>
      <ListPickDropDown {...props} isLoading={isLoading} />
      <SwipeStackView {...props} {...extra} renderKey={recsArr.join(",")} />
    </BlackBack>
  );
}

// the one controlled by the user's services
function SwipingViewMine(props) {
  const profile = useSeekStore((state) => state.profile);
  const lang_arr = profile?.options?.lang_arr || "";

  const user_services = useSeekStore((state) => state.user_services);
  // console.log(user_services);
  const user_marks = useSeekStore((state) => state.user_marks);
  const user_marks_ids = user_marks.map(({ tvmaze_id }) => tvmaze_id);
  const seek_actions = useSeekStore((state) => state.seek_actions);

  const isDoneLoginLoading = useSeekStore(is_done_login_loading_sel);

  const user_service_ids = Object.entries(user_services)
    .filter(([a, b]) => b.enabled)
    .map(([a, b]) => a)
    .sort()
    .join(",");

  // console.log("r service_ids", user_service_ids);
  const name = props.route.name;

  const { recsArr, isLoading, noRecsFound, setPageNum } = usePaginatedRecoAPI({
    profile,
    lang_arr,
    user_marks_ids,
    name,
    user_service_ids,
  });

  const setPageNumIfNotLoading = (n) => {
    if (!isLoading) {
      setPageNum(n);
    }
  };

  const extra = {
    pageNum: 0,
    setPageNum: setPageNumIfNotLoading,
    recsArr,
    noRecsFound,
    user_service_ids,
    seek_actions,
  };

  if (!user_service_ids) {
    const onPress = () => props.navigation.navigate("Profile");
    // prettier-ignore
    const content = (<Pressable onPress={onPress}><Text style={styles.selectServiceText}>Please select some streaming services to filter by.</Text></Pressable> );
    return (
      <BlackBack>
        <ListPickDropDown {...props} isLoading={isLoading} />
        {isDoneLoginLoading === false ? (
          <ActivityIndicator
            color="rgb(200,200,200)"
            size="large"
            style={styles.activityIndicator2}
          />
        ) : (
          <WarningComp headline="No Services Selected" content={content} />
        )}
      </BlackBack>
    );
  }
  // XXX we deal with the noRecsFound case inside  NoMoreCardsComp

  return (
    <BlackBack>
      <ListPickDropDown {...props} isLoading={isLoading} />
      {lang_arr.split(",").length > 1 ? (
        <TooManyLanguagesWarning lang_arr={lang_arr} />
      ) : (
        <></>
      )}
      <SwipeStackView {...props} {...extra} renderKey={recsArr.join(",")} />
    </BlackBack>
  );
}

function TooManyLanguagesWarning({ lang_arr }) {
  const [shown, setShown] = useState(true);
  return !shown ? null : (
    <View
      style={{
        backgroundColor: "rgba(30,30,30, 1)",
        position: "absolute",
        zIndex: 11,
        width: "100%",
        bottom: 0,
        padding: 30,
        paddingBottom: 40,
      }}
    >
      <Text style={{ color: "white", fontSize: 22, textAlign: "center" }}>
        Sorry - Too Many Languages
      </Text>

      <Text style={{ color: "white", fontSize: 17, marginTop: 12 }}>
        Filtering only works for one language at a time while viewing
        recommendations for your services
      </Text>
      <Pressable onPress={() => setShown(false)}>
        <Text
          style={{
            color: "white",
            fontSize: 17,
            marginTop: 8,
            textAlign: "right",
            fontWeight: "bold",
          }}
        >
          <AntDesign name="close" size={17} color="white" /> Hide This
        </Text>
      </Pressable>
    </View>
  );
}

function SwipingViewFriendsWrapped(props) {
  const following = useSeekStore((state) => state.following);
  const profile = useSeekStore((state) => state.profile);
  const isDoneLoginLoading = useSeekStore(is_done_login_loading_sel);

  if (!following || !following.length) {
    const onPress = () => props.navigation.navigate("Feed", { key: "all" });
    // also should say invite people
    // prettier-ignore
    const content = (<Pressable onPress={onPress}><Text style={styles.selectServiceText}>Check out the Feed to find people to follow.</Text></Pressable> );
    return (
      <BlackBack>
        <ListPickDropDown {...props} />
        {isDoneLoginLoading === false ? (
          <ActivityIndicator
            color="rgb(200,200,200)"
            size="large"
            style={styles.activityIndicator2}
          />
        ) : (
          <WarningComp headline="Not Following Anyone" content={content} />
        )}
      </BlackBack>
    );
  }

  return <FriendSwipeInner {...props} />;
}

function FriendSwipeInner(props) {
  const [noRecsFound, setNoRecsFound] = useState(false);

  const following = useSeekStore((state) => state.following);
  const user_marks = useSeekStore((state) => state.user_marks);
  const user_marks_ids = user_marks.map(({ tvmaze_id }) => tvmaze_id);
  const seek_actions = useSeekStore((state) => state.seek_actions);

  const following_uids = following ? following.map(({ to_uid }) => to_uid) : [];

  // XXX unrelated to the page length in the other (non friend) swipe views,
  // i.e. we don't have to worry about it being the same on the server
  const page_length = 50;
  const deps = [];
  const filter_ids = user_marks_ids;

  const [feedMarksSL, fetchDataSL, refreshSL] = useFSPagingMQWorkAround(
    query(
      collection(db, "user_marks"),
      orderBy("ts", "desc"),
      where("is_public", "==", true),
      where("kind", "==", "seen-liked")
    ),
    following_uids,
    page_length,
    deps,
    filter_ids
  );

  const [feedMarksAW, fetchDataAW, refreshAW] = useFSPagingMQWorkAround(
    query(
      collection(db, "user_marks"),
      orderBy("ts", "desc"),
      where("is_public", "==", true),
      where("kind", "==", "add-to-watchlist")
    ),
    following_uids,
    page_length,
    deps,
    filter_ids
  );

  // this is the one we actually want
  const feedMarks = [...feedMarksSL, ...feedMarksAW];

  // we'll use groupedFeedmarks to say WHICH friend liked it in the card
  const groupedFeedmarks = groupBy(feedMarks, "tvmaze_id");

  // this will maintain the order
  const recsArr = makeUnique(feedMarks.map((m) => m.tvmaze_id));

  useInterval(() => {
    console.log("resetting via useInterval");
    refreshAW();
    refreshSL();
    setNoRecsFound(false);
  }, one_day_in_ms * 3);

  const setPageNum = () => {
    console.log("setPageNum faked for rerfresh");
    refreshAW();
    refreshSL();
  };
  // page num is just a stub here, pagination is handled within the pagination hook
  const pageNum = 0;
  // prettier-ignore
  const extra = { recsArr, feedMarks, following, noRecsFound, setPageNum, pageNum, groupedFeedmarks, seek_actions };

  return (
    <BlackBack>
      <ListPickDropDown {...props} />
      <SwipeStackView {...props} {...extra} />
    </BlackBack>
  );
}

// this is like what's in assets.js *except* it uses useSeekStore.getState()
// because we explicity don't want it rerendering when user_marks changes
// because we cant to keep the list stable
const apiArtistCreditSwipePage = createAsset(
  async (artists_maze_ids_string, pageNum) => {
    const artist_maze_ids = artists_maze_ids_string.split(",").filter((s) => s);
    // console.log("total artists len", artist_maze_ids.length);
    // console.log(artist_maze_ids);
    // console.log("pageNum", pageNum);

    // n_artists_per is like page length sorta, used below as well to decide noRecsFound
    const n_artists_per = 4;
    // const n_artists_per = 2;

    const page_artists = artist_maze_ids.slice(
      pageNum * n_artists_per,
      (pageNum + 1) * n_artists_per
    );
    // console.log({ page_artists });

    // XXX slightly hacky, but we very much don't want to re render when this changes bc it changes on any every swipe
    const user_marks_ids = useSeekStore
      .getState()
      ["user_marks"].map(({ tvmaze_id }) => tvmaze_id);
    // console.log("ums", user_marks_ids.length);

    const collection = page_artists;
    const maxConcurrency = 2;
    const task = async (tvmaze_artist_id) => {
      const url = `${MAZE_API_DOMAIN}/people/${tvmaze_artist_id}/castcredits?embed[]=show`;
      const res = await fetch(url);
      return await res.json();
    };

    const res = (await pool({ collection, maxConcurrency, task })).flat();
    const recsArr = res
      .map((credit) => {
        return credit._embedded.show.id;
      })
      .filter((rec_show_id) => !user_marks_ids.includes(rec_show_id));

    // do we have more artists to go through after this, or are we totally out
    // used in SwipingViewFavArtists below to decide if we should advance the pagenum
    const noRecsFound = (pageNum + 1) * n_artists_per >= artist_maze_ids.length;

    return { recsArr: makeUnique(recsArr), noRecsFound };
  },
  // abusing a very brief cache with getState() XXX
  1000
);

function SwipingViewFavArtists(props) {
  const [pageNum, setPageNum] = useState(0);

  const profile = useSeekStore((state) => state.profile);
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const isDoneLoginLoading = useSeekStore(is_done_login_loading_sel);

  useInterval(() => {
    console.log("resetting page num via useInterval");
    setPageNum(0);
  }, one_day_in_ms * 3);

  const favorite_artists_ids = useSeekStore(
    (state) => state.favorite_artists
  ).map(({ tvmaze_artist_id }) => tvmaze_artist_id);

  // prettier-ignore
  const {recsArr, noRecsFound} = apiArtistCreditSwipePage.read(favorite_artists_ids.join(','), pageNum)
  // if we have artists, but our asset returned no recs for this page
  // but isn't saying we're totally out, advance the page. #94 bug fix.
  if (favorite_artists_ids.length && recsArr.length === 0 && !noRecsFound) {
    setPageNum(pageNum + 1);
  }

  // console.log({ noRecsFound, favorite_artists_ids, recsArr, pageNum });

  if (!favorite_artists_ids || !favorite_artists_ids.length) {
    const onPress = () =>
      props.navigation.navigate("FavoriteArtists", { uid: profile.uid });
    // prettier-ignore
    const content = (<Pressable onPress={onPress}><Text style={styles.selectServiceText}>Discover your favorite artists.</Text></Pressable> );

    return (
      <BlackBack>
        <ListPickDropDown {...props} />
        {isDoneLoginLoading === false ? (
          <ActivityIndicator
            color="rgb(200,200,200)"
            size="large"
            style={styles.activityIndicator2}
          />
        ) : (
          <WarningComp headline="No Favorite Artists" content={content} />
        )}
      </BlackBack>
    );
  }

  return (
    <BlackBack>
      <ListPickDropDown {...props} />
      <SwipeStackView
        {...props}
        seek_actions={seek_actions}
        recsArr={recsArr}
        pageNum={pageNum}
        setPageNum={(n) => {
          console.log("SET PAGE NUMBER n", n);
          setPageNum(n);
        }}
        noRecsFound={noRecsFound}
        disable_fetch_threshold={true}
        renderKey={recsArr.join(",")}
      />
    </BlackBack>
  );
}

const makeUnique = (array) => {
  return [...new Set(array)];
};

const styles = StyleSheet.create({
  imageBackground: {
    backgroundColor: "#000",
    flex: 1,
  },
  activityIndicator1: { marginTop: 50 },
  activityIndicator2: { marginTop: 250 },
  selectServiceText: { color: "rgb(11, 106, 255)", fontWeight: "bold" },

  SearchContainer: { top: 50 },
});
