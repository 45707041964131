import { useEffect, useState } from "react";
import { useWindowDimensions } from "react-native";

// this is my hacky fix just use the bigger one
export function useWindowDims() {
  const { width, height } = useWindowDimensions();
  // portrait only fix !
  // it's normal
  if (height > width) {
    return { width, height };
  } else {
    // it's flipped and we don't care because we know it's portrait
    return { width: height, height: width };
  }
}
