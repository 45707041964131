import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, SafeAreaView, ScrollView, FlatList, Pressable, Button, View,  TextInput, ImageBackground, Switch} from "react-native";

import { useNavigation } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useActionSheet } from "@expo/react-native-action-sheet";

import useSeekStore from "../app_shared/use-seek-store";

import { AntDesign } from "@expo/vector-icons";

import { FolFolCollection } from "./components/FolFolCollection.js";
import { SearchContainer } from "./SearchContainer.js";

import AllAssets from "./helpers/assets.js";
const { userFolsAsset, publicPageAsset } = AllAssets;

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";

import colors from "./colors.js";
import { BackArrow } from "./components/Icons.js";
import * as ButtonIcons from "./components/ButtonIcons.js";

const FH = new FSHelpers(firebase);

export default function ConnectionsScreen(props) {
  const profile = useSeekStore((state) => state.profile);
  const { params } = props.route;
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const current_user_profile = useSeekStore((state) => state.profile);
  const following = useSeekStore((state) => state.following);

  // is it your own? we want to update that differently and more quickly
  if (profile.uid === params.uid) {
    return (
      <OwnConnections
        {...props}
        seek_actions={seek_actions}
        current_user_profile={current_user_profile}
        following={following}
      />
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <Pressable onPress={props.navigation.goBack} style={styles.backButton}>
        <BackArrow />
      </Pressable>
      <SearchContainer style={styles.searchWidget} initialMode="user" />
      <View style={styles.titleWrapper}>
        <Text style={styles.titleText}>{params.whichList}</Text>
      </View>
      <Suspense
        fallback={
          <ActivityIndicator
            color="rgb(200,200,200)"
            size="large"
            style={{ marginTop: 50 }}
          />
        }
      >
        <OtherUserConnections
          {...props}
          seek_actions={seek_actions}
          current_user_profile={current_user_profile}
          following={following}
        />
      </Suspense>
    </SafeAreaView>
  );
}

function OtherUserConnections(props) {
  const { params } = props.route;

  const { seek_actions, current_user_profile, following } = props;
  const folfol = userFolsAsset.read(params.uid);
  const userPage = publicPageAsset.read(params.uid);
  const list = folfol[params.whichList];

  return (
    <FolFolCollection
      list={list}
      userPageUid={userPage.uid}
      whichList={params.whichList}
      seek_actions={seek_actions}
      current_user_profile={current_user_profile}
      following={following}
    />
  );
}

function OwnConnections(props) {
  // console.log("OwnConnections");
  const { params } = props.route;
  const { seek_actions, current_user_profile, following } = props;
  const profile = useSeekStore((state) => state.profile);
  const public_page = useSeekStore((state) => state.public_page);
  const followers = useSeekStore((state) => state.followers);
  const list = params.whichList === "following" ? following : followers;

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.backButton}>
        <ButtonIcons.BackArrow onPress={props.navigation.goBack} />
      </View>
      <SearchContainer style={styles.searchWidget} initialMode="user" />
      <View style={styles.titleWrapper}>
        <Text style={styles.titleText}>{params.whichList}</Text>
      </View>
      <FolFolCollection
        list={list}
        userPageUid={params.uid}
        whichList={params.whichList}
        following={following}
        seek_actions={seek_actions}
        current_user_profile={current_user_profile}
      />
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
  },
  backButton: {
    marginTop: 13,
    marginLeft: 24,
    alignItems: "center",
    flexDirection: "row",
  },
  searchWidget: { position: "absolute", top: 65, right: 0 },
  titleWrapper: {
    marginTop: 13,
    marginBottom: 32,
    marginHorizontal: 24,
  },
  titleText: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
});
