export function is_ep_within_specifier(episode, specifier) {
  if (specifier === undefined) {
    return false;
  }
  const { season, number } = specifier;
  if (episode.season < season) {
    return true;
    // yes it is
  } else if (episode.season === season) {
    return episode.number <= number;
  } else {
    return false;
  }
}

export function get_next_ep_for_user(maze_episodes, show_seen, caught_up_till) {
  const seen_ep_ids = show_seen
    .filter((x) => x.has_watched)
    .map(({ tvmaze_episode_id }) => tvmaze_episode_id);

  // we want these to override caught_up_till for a given ep
  const unseen_ep_ids = show_seen
    .filter((x) => !x.has_watched)
    .map(({ tvmaze_episode_id }) => tvmaze_episode_id);

  return maze_episodes.find((ep) => {
    if (unseen_ep_ids.includes(ep.id)) {
      return true;
    } else if (is_ep_within_specifier(ep, caught_up_till)) {
      return false;
    } else {
      return !seen_ep_ids.includes(ep.id);
    }
  });
}

export function get_upcoming(show_data) {
  try {
    return get_upcoming_actual(show_data);
  } catch (e) {
    // console.log(e);
    return {};
  }
}

function get_upcoming_actual(show_data) {
  const prev_ep_data = find_prev_ep(show_data);
  const next_ep_data = find_next_ep(show_data);

  const a_week_from_now = +new Date() + 6.048e8;

  const is_airing_in_week = a_week_from_now >= next_ep_data.next_air;
  const is_returning =
    next_ep_data.next_ep &&
    next_ep_data.next_ep.season !== prev_ep_data.prev_ep.season;
  return { is_returning, is_airing_in_week, ...prev_ep_data, ...next_ep_data };
}
// actually a lot of this isn't gonna be nec embed=nextepisode

function find_prev_ep(show_data) {
  // console.log(show_data._embedded);
  const { episodes } = show_data._embedded;

  try {
    // const prev_href = show_data._links.previousepisode.href;
    // const prev_id = prev_href.split("/")[prev_href.split("/").length - 1];
    // console.log(prev_id, prev_href);
    // not strict ===  because it's strings and ints
    // const prev_ep = episodes.find((e) => e.id == prev_id);
    // console.log(prev_ep);
    const prev_ep = show_data._embedded.previousepisode;
    const prev_air = new Date(prev_ep.airstamp);
    // prettier-ignore
    const prev_air_s = prev_air.toLocaleDateString('en-US',{ weekday: 'short', month: 'short', day: 'numeric',  year: 'numeric' } )
    return { prev_air_s, prev_air, prev_ep };
  } catch (e) {
    // console.log(e);
    // console.log(episodes);
    return {};
  }
}
function find_next_ep(show_data) {
  try {
    // const { episodes } = show_data._embedded;
    // const next_href = show_data._links.nextepisode.href;
    // const next_id = next_href.split("/")[next_href.split("/").length - 1];
    // const next_ep = episodes.find((e) => e.id == next_id);
    const next_ep = show_data._embedded.nextepisode;

    const next_air = new Date(next_ep.airstamp);
    // prettier-ignore
    const next_air_s = next_air.toLocaleDateString('en-US',{ weekday: 'short', month: 'short', day: 'numeric', year:'numeric' } )

    return { next_air_s, next_air, next_ep };
  } catch (e) {
    return {};
  }
}

export function patch_maze_show_image_paths(data, MAZE_STATIC_DOMAIN) {
  if (!data) {
    return false;
  } else if (data["image"]) {
    let path = data["image"]["original"]
      .replace("http://static.tvmaze.com/", "")
      .replace("https://static.tvmaze.com/", "");
    data["image"]["original"] = `${MAZE_STATIC_DOMAIN}/${path}`;
    let m_path = data["image"]["medium"]
      .replace("http://static.tvmaze.com/", "")
      .replace("https://static.tvmaze.com/", "");
    data["image"]["medium"] = `${MAZE_STATIC_DOMAIN}/${m_path}`;
  }
  return data;
}

export async function fetch_single_show_search(showName, domain) {
  const url = `${domain}/singlesearch/shows?q=${showName}`;
  const res = await fetch(url);
  return await res.json();
}

export async function fetch_person_by_id(person_id, domain) {
  const res = await fetch(`${domain}/people/${person_id}`);
  return await res.json();
}

export async function fetch_person_credits_by_id(person_id, domain) {
  const res = await fetch(
    `${domain}/people/${person_id}/castcredits?embed[]=show&embed[]=character`
  );
  return await res.json();
}

// hacky, just take everything after the final `/`
// "https://www.tvmaze.com/shows/47415/control-z"
export function maze_url_to_slug(url) {
  return url.split("/")[url.split("/").length - 1];
}

export function maze_ep_to_slug(episode) {
  try {
    return `${episode.season}x${episode.number.toString().padStart(2, "0")}`;
  } catch (e) {
    return "/";
  }
}

export function filter_embed_eps(shows_arr, start, end) {
  const previous = shows_arr.map((item) => {
    return {
      show: item,
      episode: item._embedded?.previousepisode,
    };
  });
  const next = shows_arr.map((item) => {
    return {
      show: item,
      episode: item._embedded?.nextepisode,
    };
  });
  const allEpisodes = [...previous, ...next];
  const filteredAllEpisodes = allEpisodes.filter((s) => {
    const d = new Date(s.episode?.airstamp);
    return d >= start && d <= end;
  });
  // sort the filtered shows by ep date
  filteredAllEpisodes.sort(show_eps_comparator_by_date);
  // and return
  return filteredAllEpisodes;
}

function show_eps_comparator_by_date(a, b) {
  const a_d = new Date(a.episode?.airstamp);
  const b_d = new Date(b.episode?.airstamp);
  return +a_d - +b_d;
}
