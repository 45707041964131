import React, { useRef, useState, useEffect } from "react";

import { StyleSheet, Animated, ActivityIndicator } from "react-native";
import { useNavigation } from "@react-navigation/native";

import CardStack, { Card } from "react-native-card-stack-swiper";
import { ActiveSwipeIndicators } from "./ActiveSwipeIndicators.js";
import { ListPickDropDown, NoMoreCardsComp } from "./SwipeMisc.js";
import { useWindowDims } from "../helpers/dimsHook.js";
import { TvShowCard } from "./TvShowCard.js";

// if we have less than 3 cards left, fetch the next page
const FETCH_THRESHOLD = 3;

// the abstracted swipe view
// XXX  warning it has two modes one where we pass a renderKey and the other where we don't (most cases)
export function SwipeStackView(props) {
  // console.log(props);
  // prettier-ignore
  const { recsArr, noRecsFound, seek_actions, user_service_ids, following, groupedFeedmarks, pageNum, setPageNum } = props;

  // a ref to the swiper component, so we can control it if need be
  const swiperRef = useRef();
  const lastIndex = useRef(-1);
  // https://github.com/lhandel/react-native-card-stack-swiper#cardstack-props
  // Threshold defaults are height/4 and width/2
  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;

  // should be able to use an XY but had issues and this worked
  const panX = useRef(new Animated.Value(0)).current;
  const panY = useRef(new Animated.Value(0)).current;

  // if we have renderKey, use it as key below, just for the fav artists one for now
  // it re renders the swiper entirely when it changes, a different approach
  const maybe_a_key =
    typeof props.renderKey !== "undefined" ? { key: props.renderKey } : null;
  // console.log(maybe_a_key)
  // key={pageNum}

  return (
    <>
      <ActiveSwipeIndicators panX={panX} panY={panY} />
      {recsArr ? (
        <CardStack
          renderNoMoreCards={() => (
            <NoMoreCardsComp
              noRecsFound={noRecsFound}
              name={props.route.name}
              user_service_ids={user_service_ids}
              following={following}
            />
          )}
          secondCardZoom={0.9}
          verticalThreshold={windowHeight / 7}
          horizontalThreshold={windowWidth / 5}
          ref={(swiper) => {
            swiperRef.current = swiper;
          }}
          onSwipeEnd={() => {
            panX.setValue(0);
            panY.setValue(0);
          }}
          onSwipe={(x, y) => {
            panX.setValue(x);
            panY.setValue(y);
          }}
          {...maybe_a_key}
          onSwiped={(i) => {
            lastIndex.current = i;
            // console.log(i, "of ", recsArr.length);
            // if (
            //   !props.disable_fetch_threshold &&
            //   i + FETCH_THRESHOLD > recsArr.length
            // ) {
            //   setPageNum(pageNum + 1);
            // }
          }}
          onSwipedAll={() => {
            // console.log("swiped all, on page", pageNum);
            setPageNum(pageNum + 1);
          }}
          style={styles.cardStack}
          cardContainerStyle={{ flex: 1 }}
          onSwipedLeft={(i) => {
            seek_actions.setMark({
              tvmaze_id: recsArr[i],
              kind: "seen-disliked",
            });
          }}
          onSwipedRight={(i) => {
            seek_actions.setMark({
              tvmaze_id: recsArr[i],
              kind: "seen-liked",
            });
          }}
          onSwipedTop={(i) => {
            seek_actions.setMark({
              tvmaze_id: recsArr[i],
              kind: "removed",
            });
          }}
          onSwipedBottom={(i) => {
            seek_actions.setMark({
              tvmaze_id: recsArr[i],
              kind: "add-to-watchlist",
            });
          }}
        >
          {recsArr.map((show_id, index) => (
            <TvShowCard
              key={show_id}
              show_id={show_id}
              index={index}
              swiperRef={swiperRef}
              lastIndex={lastIndex}
              panX={panX}
              panY={panY}
              groupedFeedmarks={groupedFeedmarks}
            />
          ))}
        </CardStack>
      ) : (
        <ActivityIndicator
          color="white"
          size="large"
          style={styles.activityIndicator}
        />
      )}
    </>
  );
}
const styles = StyleSheet.create({
  cardStack: {
    flex: 1,
    alignItems: "center",
    zIndex: 1,
    // for debug
    // backgroundColor: "red",
  },
  activityIndicator: { marginTop: 150 },
});
