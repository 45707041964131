import React, { useRef, useState, useEffect, useMemo, Suspense } from "react";

// prettier-ignore
import { Animated, Modal, Pressable, ActivityIndicator, ScrollView, Platform, Image, StyleSheet, View, Text } from "react-native";
import * as Linking from "expo-linking";
import { Entypo } from "@expo/vector-icons";

import { ServiceIcon } from "./ServiceIcon.js";
import colors from "../colors.js";

import { open_appropriate_source } from "../helpers/sourceOpener.js";

const webchannels_json = require("../../app_shared/maze_webchannels.json");
const possible_services = Object.values(webchannels_json);
const poss_services_by_id = {};
possible_services.forEach((s) => {
  poss_services_by_id[s.id] = s.reelgood_id;
});

const webChannelToName = {};
possible_services.forEach((s) => {
  webChannelToName[s.reelgood_id] = s.name;
});

export function PlayViaSourcesItem(props) {
  const { sources, user_services } = props;
  // console.log(sources);
  const user_service_ids = Object.entries(user_services)
    .filter(([a, b]) => b.enabled)
    .map(([a, b]) => a)
    .sort();
  const enabled_user_services = [];
  user_service_ids.forEach((s) => {
    enabled_user_services.push(poss_services_by_id[s]);
  });

  return (
    <>
      {sources.map((source, index) => {
        if (source.source_id === "hbo-now-subscription") {
          return <React.Fragment key={source.source_id}></React.Fragment>;
        }
        return (
          <Pressable
            key={source.source_id}
            onPress={() => {
              open_appropriate_source(source);
            }}
            style={[
              styles.serviceItemContainer,
              {
                marginTop: index === 0 ? 20 : 15,
                borderBottomWidth: index === sources.length - 1 ? 0 : 1,
              },
            ]}
          >
            <View style={styles.serviceItemNameContainer}>
              <View style={styles.serviceIconWrapper}>
                <ServiceIcon source_id={source.source_id} />
              </View>
              <Text style={styles.serviceItemName}>
                {webChannelToName[source.source_id]}
              </Text>
            </View>
            {enabled_user_services.includes(source.source_id) ? (
              <View style={styles.watchNowPressable}>
                <Text style={styles.watchNowText}>Watch now</Text>
                <Entypo
                  name="chevron-right"
                  size={22}
                  color={colors.aquamarine}
                />
              </View>
            ) : (
              <View>
                <Text style={styles.subscribeText}>
                  Subscribe
                  <Entypo
                    name="chevron-right"
                    size={15}
                    color={colors.text_grey}
                  />
                </Text>
              </View>
            )}
          </Pressable>
        );
      })}
    </>
  );
}

const styles = StyleSheet.create({
  serviceItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 15,
    borderBottomColor: colors.background,
  },
  serviceItemNameContainer: { flexDirection: "row" },
  serviceIconWrapper: {
    width: 32,
    justifyContent: "center",
    alignItems: "center",
  },
  serviceItemName: {
    fontSize: 16,
    fontWeight: "bold",
    color: colors.text_white,
    marginLeft: 8,
  },
  watchNowPressable: {
    flexDirection: "row",
    alignItems: "center",
  },
  watchNowText: {
    fontSize: 14,
    fontWeight: "bold",
    color: colors.aquamarine,
  },
  subscribeText: {
    fontSize: 14,
    fontWeight: "bold",
    color: colors.text_grey,
  },
});
