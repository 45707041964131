import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, Share, ActivityIndicator, Text, SafeAreaView, ScrollView, FlatList, Pressable, Button, View,  TextInput, ImageBackground, Switch} from "react-native";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import * as ButtonIcons from "./ButtonIcons.js";

export function BackButton(props) {
  const navigation = useNavigation();
  const navigationHistory = useNavigationState((state) => state.history);
  return (
    <ButtonIcons.BackArrowCircle
      size={40}
      onPress={
        navigationHistory.length === 1
          ? () => navigation.navigate("Swipe")
          : navigation.goBack
      }
    />
  );
}
