// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import colors from "../colors.js";
import * as Icons from "./Icons.js";

export function ActionMarkButtonsLarge(props) {
  const { show_data, seek_actions, user_marks } = props;
  // console.log(show_data);
  const extant = user_marks.find((m) => m.tvmaze_id === show_data.id);
  // console.log(extant.kind);

  return (
    <View style={styles.actionMarkContainer}>
      {/* Like button */}
      {extant?.kind === "seen-liked" ? (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "removed",
            });
          }}
        >
          <View style={[styles.actionMarkWrapper, styles.likeBorder]}>
            <Icons.HeartFull size={30} />
            <Text style={[{ color: colors.aquamarine }, styles.actionMarkText]}>
              Like
            </Text>
          </View>
        </Pressable>
      ) : (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "seen-liked",
            });
          }}
        >
          <View style={styles.actionMarkWrapper}>
            <Icons.HeartEmpty size={30} />
            <Text style={[{ color: colors.aquamarine }, styles.actionMarkText]}>
              Like
            </Text>
          </View>
        </Pressable>
      )}
      {/* Dislike button */}
      {extant?.kind === "seen-disliked" ? (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "removed",
            });
          }}
        >
          <View style={[styles.actionMarkWrapper, styles.dislikeBorder]}>
            <Icons.ThumbsDownFull size={30} />
            <Text style={[{ color: colors.red }, styles.actionMarkText]}>
              Dislike
            </Text>
          </View>
        </Pressable>
      ) : (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "seen-disliked",
            });
          }}
        >
          <View style={styles.actionMarkWrapper}>
            <Icons.ThumbsDown size={30} />
            <Text style={[{ color: colors.red }, styles.actionMarkText]}>
              Dislike
            </Text>
          </View>
        </Pressable>
      )}
      {/* Watchlist button */}
      {extant?.kind === "add-to-watchlist" ? (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "removed",
            });
          }}
        >
          <View style={[styles.actionMarkWrapper, styles.watchlistBorder]}>
            <View style={{ marginLeft: 12 }}>
              <Icons.BookmarkFull size={30} />
            </View>
            <Text style={[{ color: colors.yellow }, styles.actionMarkText]}>
              +Watchlist
            </Text>
          </View>
        </Pressable>
      ) : (
        <Pressable
          onPress={() => {
            seek_actions.setMark({
              tvmaze_id: show_data.id,
              kind: "add-to-watchlist",
            });
          }}
        >
          <View style={styles.actionMarkWrapper}>
            <View style={{ marginLeft: 12 }}>
              <Icons.BookmarkEmpty size={30} />
            </View>
            <Text style={[{ color: colors.yellow }, styles.actionMarkText]}>
              +Watchlist
            </Text>
          </View>
        </Pressable>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  actionMarkContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: 24,
    marginTop: 24,
  },
  actionMarkWrapper: {
    width: 88,
    height: 88,
    backgroundColor: colors.background_lighter,
    borderRadius: 24,
    paddingVertical: 18,
    alignItems: "center",
  },
  likeBorder: { borderColor: colors.aquamarine, borderWidth: 1 },
  dislikeBorder: { borderColor: colors.red, borderWidth: 1 },
  watchlistBorder: { borderColor: colors.yellow, borderWidth: 1 },
  actionMarkText: {
    fontSize: 13,
  },
});
