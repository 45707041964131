import React, { useRef, useState, useEffect } from "react";
import { Entypo } from "@expo/vector-icons";
// prettier-ignore
import { StyleSheet, View, ActivityIndicator, Pressable, Text } from "react-native";
import { useNavigation } from "@react-navigation/native";
import colors from "../colors.js";
import { AntDesign } from "@expo/vector-icons";
import { IntroArrows } from "./IntroArrows.js";

import AuthSingleton from "../../use-auth.js";
import useLocalStore from "../stores/local_store";

const { ProvideAuth, useAuth } = AuthSingleton;

const QUEUE_CATEGORY_KEY_TO_LABEL = {
  all: "All",
  mine: "Your Services",
  friends: "Friends Added",
  artists: "Your Favorite Artists",
};

export function NoMoreCardsComp(props) {
  const { noRecsFound, name } = props;
  const { user_service_ids, following } = props;
  const navigation = useNavigation();
  // this is just to direct it to the users own fav artist page below
  const auth = useAuth();
  const user_uid = auth?.user?.uid;

  // normal, we're just loading
  if (!noRecsFound) {
    return (
      <ActivityIndicator
        color="white"
        size="large"
        style={styles.activityIndicator2}
      />
    );
  }
  // otherwise, yes noRecsFound is true AND we're out of visible cards
  if (name === "all") {
    const content = (
      <Pressable onPress={() => navigation.navigate("Profile")}>
        <Text style={styles.noMoreCardsText}>
          Sorry about that! Please adjust your language filters or try again
          later.
        </Text>
      </Pressable>
    );
    return <WarningComp headline="Out of Recommendations" content={content} />;
  }
  if (name === "mine") {
    const onPress = () => navigation.navigate("Profile");
    const content = (
      <Pressable onPress={onPress}>
        <Text style={styles.noMoreCardsText}>
          Please select additional streaming services or change your language
          filters.
        </Text>
      </Pressable>
    );
    return (
      <WarningComp headline="No More Recommendations Found" content={content} />
    );
  }
  if (name === "friends") {
    const onPress = () => navigation.navigate("Feed", { key: "all" });
    const content = (
      <Pressable onPress={onPress}>
        <Text style={styles.noMoreCardsText}>
          Check out the Feed to find more people to follow.
        </Text>
      </Pressable>
    );
    return (
      <WarningComp headline="No More Recommendations Found" content={content} />
    );
  }
  if (name === "artists") {
    const onPress = () =>
      navigation.navigate("FavoriteArtists", { uid: user_uid });
    const content = (
      <Pressable onPress={onPress}>
        <Text style={styles.noMoreCardsText}>
          Search for your favorite actors and actresses.
        </Text>
      </Pressable>
    );
    return (
      <WarningComp headline="No More Recommendations Found" content={content} />
    );
  }

  return <WarningComp headline="Problem" content="Something went wrong" />;
}

export function ListPickDropDown(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <ListPickDropDownInner {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
  );
}

export function ListPickDropDownInner(props) {
  // prettier-ignore
  const local_store_actions = useLocalStore((state) => state.local_store_actions);

  const { isOpen, setIsOpen, isLoading } = props;
  const maybe_activity_ind = isLoading ? (
    <ActivityIndicator
      color={colors.main_purple}
      size={24}
      style={styles.small_ind}
    />
  ) : (
    <Entypo name="chevron-down" size={24} color={colors.main_purple} />
  );

  return (
    <View style={styles.topBarContainer} pointerEvents="box-none">
      <View pointerEvents="box-none">
        <Pressable
          onPress={() => setIsOpen(!isOpen)}
          style={styles.listPickOpener}
        >
          <Text style={styles.pickerText}>
            {QUEUE_CATEGORY_KEY_TO_LABEL[props.route.name]}
          </Text>
          {maybe_activity_ind}
        </Pressable>
        <View style={styles.pickerInner}>
          {!isOpen ? (
            <></>
          ) : (
            Object.keys(QUEUE_CATEGORY_KEY_TO_LABEL).map((key) => {
              return (
                <Pressable
                  key={key}
                  style={styles.pickerPress}
                  onPress={() => {
                    setIsOpen(false);
                    if (key === props.route.name) {
                      return;
                    }
                    // this should exist except in the intro tour
                    if (!props.navigation.replace) {
                      // prettier-ignore
                      console.log('Error, navigation prop not found in listpicker for', props.route)
                      return;
                    }
                    // store it to persist for next time
                    local_store_actions.setUserData(
                      "last_swipe_screen_q_key",
                      key
                    );
                    // and then actually do it
                    props.navigation.replace(key);
                  }}
                >
                  <Text
                    style={
                      key === props.route.name
                        ? styles.pickerTextActive
                        : styles.pickerText
                    }
                  >
                    {QUEUE_CATEGORY_KEY_TO_LABEL[key]}
                  </Text>
                </Pressable>
              );
            })
          )}
        </View>
      </View>
    </View>
  );
}

export function WarningComp(props) {
  return (
    <View style={styles.warningCompContainer}>
      <AntDesign
        name="exclamationcircle"
        size={64}
        color="rgb(200,200,200)"
        style={styles.exclamationCircle}
      />
      <Text style={styles.headlineText}>{props.headline}</Text>
      <Text style={styles.contentText}>{props.content}</Text>
    </View>
  );
}

export function HelpButton(props) {
  // this state is about wherther the intro arrows are visible
  // the help button itself should always be visible
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Pressable
        style={
          visible
            ? styles.introArrowContainer
            : [styles.introArrowContainer, { zIndex: -1 }]
        }
        onPressIn={() => setVisible(false)}
      >
        {visible ? <IntroArrows /> : <></>}
      </Pressable>
      <Pressable
        style={styles.HelpButton}
        hitSlop={10}
        onPress={() => setVisible(!visible)}
      >
        <AntDesign
          name="questioncircle"
          size={24}
          color={visible ? "rgba(130, 122, 255, 1)" : "rgba(255,255,255,0.5)"}
        />
      </Pressable>
    </>
  );
}

const styles = StyleSheet.create({
  activityIndicator2: { marginTop: 150 },
  listPickOpener: {
    borderRadius: 5,
    backgroundColor: colors.background_alpha,
    padding: 10,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  pickerInner: {
    position: "absolute",
    width: 215,
    borderRadius: 5,
    overflow: "hidden",
  },
  pickerPress: {
    padding: 10,
    backgroundColor: colors.background_alpha,
  },
  pickerText: {
    color: colors.main_purple,
    fontSize: 16,
  },
  pickerTextActive: {
    color: colors.text_white,
    fontSize: 16,
  },
  topBarContainer: {
    position: "absolute",
    minHeight: 200,
    left: 0,
    top: 50,
    zIndex: 5,
    justifyContent: "space-between",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  warningCompContainer: { alignItems: "center", marginTop: 100, padding: 30 },
  exclamationCircle: { marginTop: 16, marginBottom: 16 },
  headlineText: {
    color: "rgb(200,200,200)",
    flexWrap: "wrap",
    textAlign: "center",
    fontSize: 18,
    paddingBottom: 20,
  },
  contentText: {
    color: "rgb(200,200,200)",
    flexWrap: "wrap",
    textAlign: "center",
    padding: 10,
    fontSize: 16,
  },
  noMoreCardsText: {
    color: "rgb(11, 106, 255)",
    fontWeight: "bold",
    textAlign: "center",
  },
  HelpButton: {
    zIndex: 6,
    position: "absolute",
    bottom: 15,
    right: 15,
  },
  introArrowContainer: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.8)",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    zIndex: 6,
  },
  small_ind: { marginLeft: 4 },
});
