import React, { useState, useCallback, useEffect, Suspense } from "react";

// prettier-ignore
import { StyleSheet, ActivityIndicator, FlatList, Text, SafeAreaView, ScrollView, RefreshControl, Pressable, Button, View, Image, TextInput, ImageBackground, Switch} from "react-native";
import useSeekStore from "../app_shared/use-seek-store";
import { AntDesign } from "@expo/vector-icons";
import relativeDate from "tiny-relative-date";

import { ListPickerDropdown } from "./components/ListPickerDropdown.js";
import { WarningComp } from "./components/SwipeMisc.js";

import { SearchContainer } from "./SearchContainer.js";

import { ArtistListItem } from "./components/ArtistListItem.js";

import { FeedListItem } from "./components/FeedListItem.js";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import { ActionMarkButton } from "./components/ActionMarkButton.js";
import { Entypo } from "@expo/vector-icons";

import AllAssets from "./helpers/assets.js";

const { apiShowAsset, publicPageAsset } = AllAssets;

// prettier-ignore
import { useDebounce, useFSPagination, useFSPagingMQWorkAround } from "./helpers/hooks.js";

import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
// prettier-ignore
import { getFirestore, collection, query, where, doc,  orderBy, limit, addDoc } from "firebase/firestore/lite";

const FH = new FSHelpers(firebase);
const db = getFirestore(firebase);

import colors from "./colors.js";
import * as Icons from "./components/Icons.js";

const PARAM_KEY_TO_LABEL = {
  all: "All Activity",
  friends: "Friend Activity",
};

const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

export default function FeedScreen(props) {
  const { params } = props.route;

  return params.key === "friends" ? (
    <FeedFriendsView {...props} />
  ) : (
    <FeedWorldView {...props} />
  );
}

function FeedWorldView(props) {
  const { params } = props.route;

  const deps = [props.route.params.key];

  const page_length = 10;
  const [feedMarks, fetchData, refreshData] = useFSPagination(
    query(
      collection(db, "user_marks"),
      orderBy("ts", "desc"),
      where("is_public", "==", true),
      where("kind", "in", ["add-to-watchlist", "seen-liked"])
    ),
    page_length,
    deps
  );

  return (
    <FeedInnerView
      {...props}
      feedMarks={feedMarks}
      fetchData={fetchData}
      refreshData={refreshData}
    />
  );
}

const selector = (state) => state.following;
function FeedFriendsView(props) {
  // console.log("render FeedFriendsView");
  const following = useSeekStore(selector);

  const page_length = 50;

  let following_uids = following
    ? following.map(({ to_uid }) => to_uid).sort()
    : [];

  const query_sans_in = query(
    collection(db, "user_marks"),
    orderBy("ts", "desc"),
    where("is_public", "==", true)
  );
  const deps = [following.length];

  const [feedMarks, fetchData, refreshData] = useFSPagingMQWorkAround(
    query_sans_in,
    following_uids,
    page_length,
    deps
  );

  return (
    <FeedInnerView
      {...props}
      feedMarks={feedMarks}
      fetchData={fetchData}
      refreshData={refreshData}
    />
  );
}

const profile_selector = (state) => state.profile;

function FeedInnerView(props) {
  const { feedMarks, fetchData, refreshData } = props;
  const { params } = props.route;
  const profile = useSeekStore(profile_selector);

  const [refreshing, setRefreshing] = useState(false);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await Promise.all([wait(500), refreshData()]);
    setRefreshing(false);
  }, []);

  const ListEmptyComponent =
    params.key === "friends" && !profile ? (
      <WarningComp headline="Not Logged In" />
    ) : params.key === "friends" && profile ? (
      <WarningComp headline="Not Following Anyone" />
    ) : (
      <ActivityIndicator size="large" />
    );

  return (
    <>
      <SearchContainer style={styles.searchWidget} initialMode="user" />
      <SafeAreaView style={styles.container}>
        <ListPickerDropdown
          {...props}
          keyToLabel={PARAM_KEY_TO_LABEL}
          paramVal={params.key}
        />
        <FlatList
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              tintColor={colors.main_purple}
            />
          }
          data={feedMarks}
          ListEmptyComponent={ListEmptyComponent}
          style={styles.flatList}
          extraData={{ k: params.key }}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index, separators }) => {
            return (
              <Suspense fallback={<></>}>
                <ErrorBoundary fallback={<></>}>
                  <FeedListItem
                    item={item}
                    onImagePress={() => {
                      props.navigation.navigate("ShowDetail", {
                        show_id: item.tvmaze_id,
                      });
                    }}
                    onPress={() => {
                      props.navigation.navigate("UserPage", {
                        uid: item.user_uid,
                      });
                    }}
                  />
                </ErrorBoundary>
              </Suspense>
            );
          }}
          onEndReachedThreshold={0.25}
          onEndReached={(dist) => {
            fetchData();
          }}
        />
      </SafeAreaView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
    // padding: 5,
  },
  searchWidget: { position: "absolute", right: 0, top: 34 },
  titleContainer: { flexDirection: "row", marginTop: 25, marginLeft: 24 },
  addSearchContainer: {
    flexDirection: "row",
    position: "absolute",
    right: 24,
    top: 4,
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 20,
    color: colors.text_white,
  },
  downArrow: { marginTop: 12, marginLeft: 12 },
  flatList: { marginTop: 87 },
  pickerInner: {
    position: "absolute",
    marginTop: 30,
    width: "100%",
    backgroundColor: colors.background_lighter,
    borderBottomEndRadius: 12,
    borderBottomStartRadius: 12,
  },
  pickerPress: {
    padding: 10,
  },
  pickerTextActive: {
    fontSize: 24,
    fontWeight: "bold",
    color: colors.text_white,
  },
  pickerText: {
    fontSize: 20,
    // alignSelf: "flex-end",
    // alignSelf: "center",
    alignSelf: "flex-start",
    color: colors.main_purple,
  },
});
