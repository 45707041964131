const colors = {
  background: "#121018",
  background_alpha: "rgba(18,16,24, 0.95)",
  background_lighter: "#1C1A28",
  background_lighter_alpha: "rgba(28,26,40, 0.95)",
  main_purple: "#827AFF",
  purple_alpha: "rgba(125, 117, 255, 0.25)",
  text_white: "#FFFFFF",
  text_grey: "rgb(200,200,200)",
  aquamarine: "#8EFFD6",
  red: "#FF4A6B",
  yellow: "#FCEB52",
  orangy: "#f1c40f",
};
export default colors;
