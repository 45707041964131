// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo , useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, FlatList, SafeAreaView, Image, Animated, ScrollView, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import colors from "../colors.js";
import { useNavigation } from "@react-navigation/native";

import AllAssets from "../helpers/assets.js";
const { apiShowCastAsset } = AllAssets;

import * as SDHelpers from "../helpers/formatting_helpers.js";
import * as Toast from "./Toast.js";
import * as ButtonIcons from "./ButtonIcons.js";

export function ShowCastInfo(props) {
  const cast = apiShowCastAsset.read(props.show_id);

  return (
    <>
      <Text style={styles.titleText}>Cast</Text>
      {cast.length ? (
        <View style={styles.castContainer}>
          {cast.map((item) => (
            <ShowCastItem
              key={item.person.id.toString() + item.character.id.toString()}
              item={item}
              {...props}
            />
          ))}
        </View>
      ) : (
        <></>
      )}
    </>
  );
}

function ShowCastItem(props) {
  const navigation = useNavigation();
  const { item, seek_actions, favorite_artists, user_uid } = props;
  const extantArtist = favorite_artists.find(
    (m) => m.tvmaze_artist_id === item.person.id
  );

  const initials = SDHelpers.getInitials(item.person.name);

  return (
    <View style={styles.castItemContainer}>
      <Pressable
        onPress={() => {
          navigation.navigate("ArtistDetailScreen", {
            personId: item.person.id,
          });
        }}
        hitSlop={10}
      >
        {item.person.image ? (
          <Image
            source={{ uri: item.person.image.medium }}
            style={styles.castItemImage}
          />
        ) : (
          <View style={styles.initialsCircle}>
            <Text style={styles.initialsCircleText}>{initials}</Text>
          </View>
        )}
      </Pressable>

      <Pressable
        onPress={() => {
          navigation.navigate("ArtistDetailScreen", {
            personId: item.person.id,
          });
        }}
        hitSlop={10}
      >
        <Text style={styles.personNameText}>{item.person.name}</Text>
        <Text style={styles.characterNameText}>{item.character.name}</Text>
      </Pressable>

      <View style={styles.favoriteButtonWrapper}>
        {extantArtist ? (
          <ButtonIcons.HeartFull
            onPress={async () => {
              if (!user_uid) {
                Toast.alert(
                  {
                    title: "Please Login",
                    description:
                      "Sorry, you must login to save favorite artists",
                  },
                  "danger"
                );
                return;
              }
              await seek_actions.setFavArt(item.person.id);
            }}
          />
        ) : (
          <ButtonIcons.HeartEmpty
            onPress={() => {
              if (!user_uid) {
                Toast.alert(
                  {
                    title: "Please Login",
                    description:
                      "Sorry, you must login to save favorite artists",
                  },
                  "danger"
                );
                return;
              }
              seek_actions.setFavArt(item.person.id);
            }}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  castContainer: {
    backgroundColor: colors.background_lighter,
    borderRadius: 8,
    marginHorizontal: 24,
    marginBottom: 32,
    paddingVertical: 8,
    paddingHorizontal: 16,
  },
  titleText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 24,
    marginBottom: 20,
  },
  castItemContainer: {
    flexDirection: "row",
    paddingVertical: 10,
    borderBottomColor: colors.background,
    borderBottomWidth: 1,
  },
  castItemImage: { height: 40, width: 40, borderRadius: 300, marginRight: 10 },
  initialsCircle: {
    width: 40,
    height: 40,
    borderRadius: 300,
    backgroundColor: colors.main_purple,
    justifyContent: "center",
    marginRight: 10,
  },
  initialsCircleText: {
    fontSize: 16,
    textAlign: "center",
    color: colors.text_white,
  },
  personNameText: { color: colors.text_white, fontWeight: "bold" },
  characterNameText: { color: colors.text_grey, fontSize: 13 },
  favoriteButtonWrapper: { position: "absolute", right: 0, top: 20 },
});
