// prettier-ignore
import React, { Suspense, useRef, useState, useEffect, useMemo, useReducer, useCallback} from "react";
// prettier-ignore
import { StyleSheet, Text, Animated, ActivityIndicator, View, ImageBackground, Pressable, } from "react-native";
import { AntDesign } from "@expo/vector-icons";
import { ErrorBoundary } from "./components/ErrorBoundary.js";
import * as Icons from "./components/Icons.js";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { ListPickDropDownInner } from "./components/SwipeMisc.js";

import useLocalStore from "./stores/local_store";
import { IntroArrows } from "./components/IntroArrows.js";
import * as Toast from "./components/Toast.js";
import { DummyTVCard } from "./components/TvShowCard.js";
import { SearchContainer } from "./SearchContainer.js";

import { TabIcon, ICON_SCREEN_MAP } from "./components/BottomTabHelpers.js";

import colors from "./colors.js";

import { useWindowDims } from "./helpers/dimsHook.js";
import { ActiveSwipeIndicators } from "./components/ActiveSwipeIndicators.js";

import AllAssets from "./helpers/assets.js";
const { apiShowAsset } = AllAssets;

export default function IntroductionScreen() {
  // console.log("intro screen render");
  const local_keys = useLocalStore((state) => state.local_keys);
  // prettier-ignore
  const hasSeen = useLocalStore((state) => state.user_data[local_keys.seen_intro]);
  const local_store_actions = useLocalStore(
    (state) => state.local_store_actions
  );
  const searchAnim = useRef(new Animated.Value(0)).current;
  const timers = useRef({}).current;

  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [isTabMode, setIsTabMode] = useState(false);
  const [cardIndex, setCardIndex] = useState(-1);

  const deal_with_swipes = (index) => {
    const pause_dur = 3500;
    // pos put this with a use effect instead
    setCardIndex(index);
    if (index === 0) {
      Toast.alert({ title: "Great first swipe!", duration: 2 }, "success");
      timers.timerId0 = setTimeout(() => {
        Toast.alert(
          {
            title: "You can choose different categories to swipe through",
            duration: 4.5,
          },
          "info"
        );
        // just a tiny delay on this
        setTimeout(() => setIsPickerOpen(true), 1200);
      }, 2000);

      timers.timerId1 = setTimeout(() => {
        setIsPickerOpen(false);
        Animated.timing(searchAnim, {
          toValue: 1,
          duration: 1000,
          useNativeDriver: false,
        }).start();

        Toast.alert(
          {
            title: "and search for specific shows, artists & users",
            duration: 5,
          },
          "info"
        );
      }, pause_dur * 2);

      timers.timerId2 = setTimeout(() => {
        Animated.timing(searchAnim, {
          toValue: 0,
          duration: 1000,
          useNativeDriver: false,
        }).start();
        setIsTabMode(true)
        // prettier-ignore
        // Toast.alert({title: "", duration: 5},"info");
      }, pause_dur * 3);
    }
  };

  // for the   ActivityIndicator
  const panX = useRef(new Animated.Value(0)).current;
  const panY = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    return () => {
      clearTimeout(timers.timerId0);
      clearTimeout(timers.timerId1);
      clearTimeout(timers.timerId2);
      console.log("cleared!");
    };
  }, []);

  return (
    // <View style={[styles.IntroductionScreen, containerStyle]}>
    <View style={styles.IntroductionScreen}>
      <ActiveSwipeIndicators panX={panX} panY={panY} />
      {cardIndex === 0 ? (
        <>
          <ListPickDropDownInner
            route={{ name: "all" }}
            navigation={[]}
            isOpen={isPickerOpen}
            setIsOpen={setIsPickerOpen}
          />
          <DummySearch searchAnim={searchAnim} />
          {isTabMode ? <FakeTabsActive /> : <FakeTabsInactive />}
        </>
      ) : null}
      {/*  https://github.com/lhandel/react-native-card-stack-swiper  */}
      <CardStack
        renderNoMoreCards={() => <></>}
        onSwipeEnd={() => {
          panX.setValue(0);
          panY.setValue(0);
        }}
        onSwiped={(index) => {
          deal_with_swipes(index);
        }}
        onSwipe={(x, y) => {
          panX.setValue(x);
          panY.setValue(y);
        }}
        onSwipedAll={() => {
          Toast.clear();
          setTimeout(() => {
            // prettier-ignore
            Toast.alert({ title: "Intro Complete! 😎", duration: 10, description:'You can replay the intro anytime from your Profile (⚙)' }, "info");
            // set it so they won't see it again, wrapped in a timeout
            local_store_actions.setSeenIntro(true);
          }, 500);
        }}
      >
        <FirstIntroCard />
        <SecondIntroCard />
      </CardStack>
    </View>
  );
}

function FirstIntroCard(props) {
  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;
  const bottomTabHeight = 0;
  const [showArrows, setShowArrows] = useState(false);

  useEffect(() => {
    Toast.alert(
      {
        title: "Welcome to SeekingTV!",
        duration: 4,
        description: "This intro should take less than 30 seconds",
      },
      "info"
    );
    const timerId1 = setTimeout(() => {
      setShowArrows(true);
      Toast.alert(
        {
          title: "The Seek screen lets you swipe to choose shows",
          description: "Try swiping to continue...",
          duration: 20,
        },
        "info"
      );
    }, 4000);
    return () => {
      Toast.clear();
      clearTimeout(timerId1);
    };
  }, []);

  return (
    <>
      <Card>
        <DummyTVCard
          show_id={1871}
          bottomTabHeight={bottomTabHeight}
          style={{ position: "absolute" }}
        />
      </Card>
      {showArrows ? (
        <View style={styles.introArrowContainer}>
          <IntroArrows style={styles.IntroArrows} />
        </View>
      ) : null}
    </>
  );
}

function DummySearch(props) {
  // const openAnim = useRef(new Animated.Value(0)).current;
  const backgroundColor = props.searchAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [colors.background_alpha, "rgba(255,255,255, 0.9)"],
  });
  const padding = props.searchAnim.interpolate({
    inputRange: [0, 1],
    outputRange: [15, 25],
  });
  return (
    <Animated.View style={[styles.SearchButton, { backgroundColor, padding }]}>
      <Icons.MagnifyingGlass />
    </Animated.View>
  );
}

function SecondIntroCard(props) {
  const windowWidth = useWindowDims().width;
  const windowHeight = useWindowDims().height;
  const bottomTabHeight = 75;

  return (
    <Card>
      <DummyTVCard
        show_id={82}
        bottomTabHeight={bottomTabHeight}
        style={{ position: "absolute" }}
      />
      <View
        style={[
          { backgroundColor: "rgba(0,0,0,0.5)" },
          StyleSheet.absoluteFill,
        ]}
      />
    </Card>
  );
}

function WrappedIcon(props) {
  const { highlightedTab, route_name } = props;

  const isHighlighted = highlightedTab === route_name;
  const backgroundColor = isHighlighted ? colors.text_white : "rgba(0,0,0,0)";
  let color = isHighlighted ? colors.main_purple : "rgb(100,100,100)";
  color = route_name === "Swipe" ? colors.aquamarine : color;
  return (
    <View style={{ backgroundColor, borderRadius: 5, padding: 6 }}>
      <TabIcon route_name={route_name} color={color} />
    </View>
  );
}

function run_tab_tour(setHighlightedTab) {
  const pause_dur = 3000;
  // tabs #1, watchlist
  setHighlightedTab("WatchList");
  // prettier-ignore
  Toast.alert({ title: "To find shows you've added, visit the Watchlist screen", duration: 60 }, "info");

  const tid1 = setTimeout(() => {
    // prettier-ignore
    Toast.alert({ title: "To check out what your friends are watching, see the Feed", duration: 60, }, "info");
    setHighlightedTab("Feed");
  }, pause_dur);

  const tid2 = setTimeout(() => {
    // prettier-ignore
    Toast.alert({ title: "To create an account, choose your streaming services, & set up a profile, head to the Profile screen", description:"Swipe one more time to finish the intro...", duration: 60, }, "info");
    setHighlightedTab("Profile");
  }, pause_dur * 2);

  return () => {
    clearTimeout(tid1);
    clearTimeout(tid2);
    // clearTimeout(tid3);
  };
}

function FakeTabsActive(props) {
  const [highlightedTab, setHighlightedTab] = useState("Seek");
  // TODO
  useEffect(() => {
    return run_tab_tour(setHighlightedTab);
  }, []);
  // const color = colors.main_purple;
  // const color = colors.text_grey;
  return (
    <View
      style={{
        width: "100%",
        bottom: 0,
        height: 50,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        position: "absolute",
      }}
    >
      {/*<TabIcon route_name="WatchList" color={color} />*/}
      <WrappedIcon route_name="WatchList" highlightedTab={highlightedTab} />
      <WrappedIcon route_name="Feed" highlightedTab={highlightedTab} />
      <WrappedIcon route_name="Swipe" highlightedTab={highlightedTab} />
      <WrappedIcon route_name="Schedule" highlightedTab={highlightedTab} />
      <WrappedIcon route_name="Profile" highlightedTab={highlightedTab} />
    </View>
  );
}
function FakeTabsInactive(props) {
  // const color = colors.main_purple;
  const color = "rgb(100,100,100)";
  return (
    <View
      style={{
        width: "100%",
        bottom: 0,
        height: 50,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        position: "absolute",
      }}
    >
      <TabIcon route_name="WatchList" color={color} />
      <TabIcon route_name="Feed" color={color} />
      <TabIcon route_name="Swipe" color={color} />
      <TabIcon route_name="Schedule" color={color} />
      <TabIcon route_name="Profile" color={color} />
    </View>
  );
}
// ["WatchList", "Feed", "Swipe", "Schedule" "Profile"]

const styles = StyleSheet.create({
  IntroductionScreen: {
    zIndex: 25,
    position: "absolute",
    flex: 1,
    top: 0,
    backgroundColor: "rgb(20,20,20)",
    // alignSelf: "stretch",
    width: "100%",
    // borderWidth: 2,
    // borderColor: "blue",
    height: "100%",
  },

  introArrowContainer: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    top: 0,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  IntroArrows: {},
  SearchButton: {
    borderRadius: 150,
    // backgroundColor: colors.background_alpha,
    // padding: 15,
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 50,
    zIndex: 30,
    left: "80%",
  },
});

// wrap: {
//   flexDirection: "row",
//   position: "absolute",
//   alignItems: "center",
//   // top: 50,
//   zIndex: 10,
// },
