import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Share, Text, ScrollView, Pressable, Button, View,  TextInput, Image, Switch} from "react-native";
import { useNavigation } from "@react-navigation/native";
import { useDebounce } from "../helpers/hooks.js";
import * as Toast from "./Toast.js";

import colors from "../colors.js";

// XXX THIS SHOULD MATCH functions/shows.js
const LANG_CODES_TO_MAZE = {
  en: "English",
  es: "Spanish",
  zh: "Chinese",
  fr: "French",
  ko: "Korean",
  ja: "Japanese",
  ar: "Arabic",
};

// prettier-ignore
const invert = (obj) => Object.assign({}, ...Object.entries(obj).map(([a,b]) => ({ [b]: a })))

const MAZE_LANG_TO_CODE = invert(LANG_CODES_TO_MAZE);

const LANG_CODES_TO_MAZE_ENTRIES = Object.entries(LANG_CODES_TO_MAZE);

export function LanguageOptions(props) {
  const navigation = useNavigation();
  const { seek_actions, profile } = props;
  const lang_arr = profile?.options?.lang_arr
    ? profile.options.lang_arr.split(",")
    : [];

  return (
    <View style={styles.LanguageOptions}>
      <Text style={styles.settingsTitleText}>Language Filters</Text>
      <Text style={styles.descText}>
        Language filters only apply on the <Text style={styles.bold}>All</Text>{" "}
        & <Text style={styles.bold}>Your Services</Text> swipe screens
      </Text>
      <View style={styles.innerContainer}>
        <LanguageSwitches
          lang_arr={lang_arr}
          seek_actions={seek_actions}
          hasProfile={!!profile}
        />
      </View>
    </View>
  );
}

function LanguageSwitches(props) {
  const { lang_arr, seek_actions, hasProfile } = props;
  const { setUserProfileOption } = seek_actions;
  return (
    <>
      {LANG_CODES_TO_MAZE_ENTRIES.map(([key, label]) => {
        return (
          <SingleSwitch
            label={label}
            key={key}
            value={lang_arr.includes(key)}
            onValueChange={(v) => {
              if (!hasProfile) {
                Toast.clear();
                // prettier-ignore
                Toast.alert({ title: "Please login before selecting languages" }, "danger");
                return;
              }

              const lang_arr_string = v
                ? [...lang_arr, key].sort().join(",")
                : lang_arr
                    .filter((z) => z !== key)
                    .sort()
                    .join(",");
              setUserProfileOption("lang_arr", lang_arr_string);
            }}
          />
        );
      })}
    </>
  );
}

function SingleSwitch(props) {
  return (
    <View
      style={{
        flexDirection: "row",
        padding: 16,
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 48,
        borderBottomWidth: 0,
        borderBottomColor: colors.background,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text style={styles.keyText}>{props.label}</Text>
      <Switch
        trackColor={{ false: "#767577", true: "#00CC83" }}
        thumbColor={"#f4f3f4"}
        ios_backgroundColor="#3e3e3e"
        onValueChange={props.onValueChange}
        value={props.value}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  LanguageOptions: {
    // flexDirection: "row",
    marginHorizontal: 24,
    marginTop: 29,
  },
  bold: {
    fontWeight: "bold",
    color: colors.text_white,
  },
  descText: {
    color: colors.text_grey,
    marginTop: 8,
  },
  settingsTitleText: {
    color: colors.text_white,
    fontSize: 24,
    fontWeight: "bold",
  },

  innerContainer: {
    backgroundColor: colors.background_lighter,
    marginTop: 16,
    borderRadius: 8,
  },
  keyText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
    marginLeft: 12,
  },
});
