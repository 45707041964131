import React, { useState, useEffect, useRef, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, ScrollView, FlatList, Pressable, Image, Button, View, TextInput, ImageBackground, Switch } from "react-native";

import { useNavigation } from "@react-navigation/native";
import { useActionSheet } from "@expo/react-native-action-sheet";

import { PlaceHolderUserPhoto, UserPhotoComp } from "./UserImages.js";

import AllAssets from "../helpers/assets.js";
const { publicPageAsset } = AllAssets;
import colors from "../colors.js";

import { FSHelpers } from "../../app_shared/fs_helpers.js";
import { firebase } from "../../fire.js";
const FH = new FSHelpers(firebase);

export function UserListItem(props) {
  const { navigation, userPage } = props;
  const { uid } = userPage;

  // anonymous  case, has no public page
  if (!userPage || userPage.error) {
    return <AnonListItem />;
  }

  return (
    <View style={styles.border}>
      <View style={styles.container}>
        <View style={styles.userPhotoContainer}>
          {userPage.userPhoto ? (
            <UserPhotoComp
              size={64}
              userPhoto={userPage.userPhoto}
              onPress={() => {
                navigation.navigate("UserPage", {
                  uid: uid,
                });
              }}
            />
          ) : (
            <PlaceHolderUserPhoto
              size={32}
              onPress={() => {
                navigation.navigate("UserPage", {
                  uid: uid,
                });
              }}
            />
          )}
        </View>
        <View style={styles.nameContainer}>
          <Text
            style={styles.displayName}
            onPress={() => {
              navigation.navigate("UserPage", {
                uid: uid,
              });
            }}
          >
            {userPage.displayName}
          </Text>
          <Text
            style={styles.screenName}
            onPress={() => {
              navigation.navigate("UserPage", {
                uid: uid,
              });
            }}
          >
            @{userPage.screenNameCased}
          </Text>
        </View>
        {props.children}
      </View>
    </View>
  );
}

function AnonListItem(props) {
  return (
    <View style={styles.border}>
      <View style={styles.container}>
        <View style={styles.userPhotoContainer}>
          <PlaceHolderUserPhoto size={32} />
        </View>
        <View style={styles.nameContainer}>
          <Text style={styles.displayName}>Anonymous User</Text>
        </View>
        {/* no children, can't follow */}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 24,
    flexDirection: "row",
    marginBottom: 18,
    alignItems: "center",
  },
  userPhotoContainer: {},
  nameContainer: {
    marginHorizontal: 10,
    // maxWidth: 120
  },
  displayName: {
    color: colors.text_white,
    fontSize: 16,
    fontWeight: "bold",
  },
  screenName: {
    color: colors.text_grey,
    fontSize: 14,
    marginTop: 5,
  },
});
