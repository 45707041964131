import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Platform, Share, Text, ScrollView, Pressable, Button, View,  TextInput, Image, Switch} from "react-native";
import * as AppleAuthentication from "expo-apple-authentication";
import { AntDesign } from "@expo/vector-icons";

import colors from "../colors.js";

export function LoginAppleButton(props) {
  const { onPress } = props;

  if (Platform.OS === "ios") {
    return (
      <AppleAuthentication.AppleAuthenticationButton
        buttonType={AppleAuthentication.AppleAuthenticationButtonType.SIGN_IN}
        buttonStyle={AppleAuthentication.AppleAuthenticationButtonStyle.WHITE}
        cornerRadius={5}
        style={styles.appleAuthenticationButton}
        onPress={onPress}
      />
    );
  } else if (Platform.OS === "web") {
    return (
      <AntDesign.Button
        onPress={onPress}
        name="apple1"
        backgroundColor="white"
        color="black"
      >
        Sign in with Apple
      </AntDesign.Button>
    );
  } else {
    // android doesn't support apple yet on expo
    return null;
  }
}

export function LogoutButton(props) {
  // const { fullName } = profile;
  // const givenName = fullName && fullName.givenName ? fullName.givenName : "";
  return (
    <AntDesign.Button
      onPress={props.onPress}
      backgroundColor="rgb(230,230,230)"
      color="black"
      name="logout"
    >
      <Text>Sign Out</Text>
    </AntDesign.Button>
  );
}

export function LoginGoogleButton(props) {
  const { onPress } = props;
  return (
    <Pressable style={styles.standardAuthenticationButton} onPress={onPress}>
      <AntDesign
        style={styles.standardIcon}
        name="google"
        color="black"
        size={16}
      />
      <Text style={styles.standardAuthenticationButtonText}>
        Sign in with Google
      </Text>
    </Pressable>
  );
  // return (
  //   <AntDesign.Button
  //     onPress={onPress}
  //     name="google"
  //     backgroundColor="white"
  //     color="black"
  //     height={50}
  //     width={200}
  //   >
  //     Sign in with Google
  //   </AntDesign.Button>
  // );
}

const styles = StyleSheet.create({
  appleAuthenticationButton: { width: 200, height: 44 },
  standardAuthenticationButton: {
    width: 200,
    height: 44,
    backgroundColor: "white",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 8,
  },
  standardIcon: {
    marginRight: 2,
  },
  standardAuthenticationButtonText: {
    color: "black",
    fontSize: 16,
    fontWeight: "500",
  },
});
