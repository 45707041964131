export function getGenres(item, targetNum = 3) {
  try {
    let newGenreArr = [];
    item.genres.forEach((genre) => {
      if (genre === "Science-Fiction") {
        newGenreArr.push("Sci-Fi");
      } else {
        newGenreArr.push(genre);
      }
    });
    return newGenreArr.slice(0, targetNum).join(", ");
  } catch (e) {
    return "";
  }
}

export function getSeasonCount(item) {
  try {
    return item._embedded.previousepisode.season;
  } catch (e) {
    // console.log("No season count for this show");
    return "0";
  }
}

export function getEpisodeCount(item) {
  try {
    return item._embedded.episode_count;
  } catch (e) {
    // console.log("No episode count for this show");
    return "0";
  }
}

export function getEpSeason(item) {
  try {
    if (item.season) {
      return item.season;
    } else {
      return Math.floor(item.sequence_number);
    }
  } catch (e) {
    return "";
  }
}

export function getEpNumber(item) {
  try {
    if (item.number) {
      // for tvmaze _embedded
      return item.number;
    } else if (item.episode_number) {
      // for rg deeplinks
      return item.episode_number;
    } else {
      return ` Special`;
    }
  } catch (e) {
    return "";
  }
}

export function getEpName(item) {
  try {
    if (item.name) {
      // for tvmaze _embedded
      return `${item.name}`;
    } else {
      // for rg deeplinks
      return `${item.title}`;
    }
  } catch (e) {
    return "";
  }
}

export function getShowStatus(status) {
  if (status === "To Be Determined") {
    return "T.B.D.";
  } else {
    return status;
  }
}

export function getInitials(person_name) {
  let initials = "";
  let splitName = person_name ? person_name.trim().split(" ") : "";
  for (let name of splitName) {
    initials += name[0];
  }
  return initials.slice(0, 2);
}

export function getAge(birthday) {
  let dob = new Date(birthday);
  let month_diff = Date.now() - dob.getTime();
  let age_dt = new Date(month_diff);
  let year = age_dt.getFullYear();
  let age = Math.abs(year - 1970);
  return `${age} years old`;
}

export function getFormattedDate(date, platform_os) {
  let d = new Date(date);
  let month = d.toLocaleString("en-us", { month: "long" });
  let day = d.getDate() + 1;
  let year = d.getFullYear();
  if (platform_os === "android") {
    return `${d.getMonth() + 1}-${day}-${year}`;
  }
  return `${month} ${day}, ${year}`;
}

export function findRgEpFromTvmaze(item, rg_episodes) {
  if (!rg_episodes) {
    return false;
  }
  return rg_episodes.find(
    (a) =>
      Math.floor(a.sequence_number) == item.season &&
      a.episode_number == item.number
  );
}

export function date_helper(d) {
  const r = d.toLocaleDateString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return r;
}
