import React, { useState, useEffect, Suspense } from "react";
import { AntDesign } from "@expo/vector-icons";

// prettier-ignore
import { StyleSheet, Text, SafeAreaView, Platform, FlatList, ActivityIndicator, View, Image, ImageBackground, Pressable, Button, Dimensions, Keyboard } from "react-native";

import { ErrorBoundary } from "./components/ErrorBoundary.js";

import { useNavigation } from "@react-navigation/native";
// import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";

// import { createStackNavigator } from "@react-navigation/stack";
// const Stack = createStackNavigator();
import { removeDuplicates } from "./helpers/utils.js";
import { Entypo } from "@expo/vector-icons";
// prettier-ignore
import { TvShowListProgressiveItem, TvShowWithNextUpItem } from "./components/TvShowListItem.js";
import { ArtistListItem } from "./components/ArtistListItem.js";
import { ListPickerDropdown } from "./components/ListPickerDropdown.js";

import { ServiceIcon } from "./components/ServiceIcon.js";
import { ActionMarkButton } from "./components/ActionMarkButton.js";

import { SearchContainer } from "./SearchContainer.js";

// prettier-ignore
import AllAssets from "./helpers/assets.js";

import { useDebounce } from "./helpers/hooks.js";
import useSeekStore from "../app_shared/use-seek-store";

import colors from "./colors.js";
// prettier-ignore
import * as Icons from "./components/Icons.js";

import * as Haptics from "expo-haptics";

function Haptic_Select() {
  if (Platform.OS === "web") {
    return;
  }
  Haptics.selectionAsync();
}

const LIST_CATEGORY_KEY_TO_LABEL = {
  "add-to-watchlist": "Your Watchlist",
  "seen-liked": "Liked Shows",
  "seen-disliked": "Disliked Shows",
};

export default function WatchListScreen(props) {
  const { params } = props.route;
  const seek_actions = useSeekStore((state) => state.seek_actions);
  const user_marks = useSeekStore((state) => state.user_marks);
  const favorite_artists = useSeekStore((state) => state.favorite_artists);

  return (
    <SafeAreaView style={styles.container}>
      <SearchContainer style={styles.searchWidget} />
      <ListPickerDropdown
        {...props}
        keyToLabel={LIST_CATEGORY_KEY_TO_LABEL}
        paramVal={params.kind}
      />
      <Suspense
        fallback={
          <ActivityIndicator
            size="large"
            color="white"
            style={styles.activityIndicator}
          />
        }
      >
        <ErrorBoundary fallback={<></>} style={{ flex: 1 }}>
          <InnerWatchList {...props} />
        </ErrorBoundary>
      </Suspense>
    </SafeAreaView>
  );
}

//XXX if flatlist isn't updating correctly, you may need extraData
//       extraData={marks}
// // https://docs.expo.io/versions/latest/react-native/flatlist/
const seek_actions_selector = (state) => state.seek_actions;
const user_services_selector = (state) => state.user_services;
const seen_episodes_selector = (state) => state.seen_episodes;
const profile_selector = (state) => state.profile;

function InnerWatchList(props) {
  const navigation = useNavigation();
  const user_marks = useSeekStore((state) => state.user_marks);
  const kind = props.route.params.kind;
  const filtered_marks = user_marks.filter((x) => x.kind === kind);
  const seen_episodes = useSeekStore(seen_episodes_selector);
  const seek_actions = useSeekStore(seek_actions_selector);
  const user_services = useSeekStore(user_services_selector);
  const profile = useSeekStore(profile_selector);

  return (
    <FlatList
      data={filtered_marks}
      ListEmptyComponent={
        <View style={styles.emptyListView}>
          <Text style={styles.emptyText}>
            {LIST_CATEGORY_KEY_TO_LABEL[kind].replace("Your ", "")} empty so
            far.
          </Text>
          <Text style={styles.searchForShowText}>
            Try searching for a show to add!
          </Text>
        </View>
      }
      style={styles.flatList}
      keyboardShouldPersistTaps="handled"
      keyExtractor={(item) => item.tvmaze_id.toString()}
      renderItem={({ item }) =>
        kind === "add-to-watchlist" ? (
          <TvShowWithNextUpItem
            mark={item}
            seen_episodes={seen_episodes}
            seek_actions={seek_actions}
            user_services={user_services}
            user_uid={profile?.uid}
            onTopPress={() => {
              navigation.navigate("ShowDetail", {
                show_id: item.tvmaze_id,
              });
            }}
          />
        ) : (
          <TvShowListProgressiveItem
            {...item}
            onPress={() => {
              navigation.navigate("ShowDetail", {
                show_id: item.tvmaze_id,
              });
            }}
          />
        )
      }
    />
  );
}

const styles = StyleSheet.create({
  container: { backgroundColor: colors.background, flex: 1 },
  searchWidget: { position: "absolute", right: 0, top: 34 },

  showListItemContainer: {
    // flex: 1,
    flexDirection: "row",
    backgroundColor: colors.background_lighter,
    marginBottom: 18,
    padding: 8,
    borderRadius: 8,
    minHeight: 80,
  },

  flatList: { marginTop: 87, marginHorizontal: 24 },

  activityIndicator: { marginTop: 200 },
  noResultView: { alignItems: "center", marginTop: 200 },
  noResultText: { color: "white", fontSize: 16 },
  emptyListView: { marginTop: 100, alignItems: "center" },
  emptyText: { fontSize: 16, color: "white" },
  searchForShowText: { fontSize: 14, color: "white", marginTop: 10 },
});
