import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, ActivityIndicator, Text, SafeAreaView, ScrollView, FlatList, Pressable, Button, View, Image, TextInput, ImageBackground, Switch, Keyboard } from "react-native";
import { ErrorBoundary } from "./ErrorBoundary.js";
import { useNavigation } from "@react-navigation/native";

import { ActionMarkButton } from "./ActionMarkButton.js";

import { FSHelpers } from "../../app_shared/fs_helpers.js";
import { firebase } from "../../fire.js";
import { current } from "immer";
import colors from "../colors.js";
// prettier-ignore
import { MagnifyingGlass, Calendar, PlayButton, BackArrow, PlusCircle, Trashcan, HeartFull, HeartEmpty, ThumbsDown, BookmarkFull, BookmarkEmpty,} from "./Icons.js";
import * as SDHelpers from "../helpers/formatting_helpers.js";

const FH = new FSHelpers(firebase);

export function ArtistListItem(props) {
  const navigation = useNavigation();
  const { person } = props;
  const initials = SDHelpers.getInitials(person.name);

  return (
    <View key={person.id.toString()} style={styles.peopleListItemContainer}>
      <Pressable
        onPress={() => {
          navigation.navigate("ArtistDetailScreen", {
            personId: person.id,
          });
        }}
      >
        <View style={styles.peopleListItemContainerInner}>
          {person.image ? (
            <Image
              onPress={props.onPress}
              source={{ uri: person.image.medium }}
              style={styles.personImage}
            />
          ) : (
            <View style={styles.personInitialsCircle}>
              <Text
                style={{
                  fontSize: 20,
                  textAlign: "center",
                  color: colors.text_white,
                }}
              >
                {initials}
              </Text>
            </View>
          )}
          <View>
            <Text onPress={props.onPress} style={styles.personNameText}>
              {person.name}
            </Text>
            <Text style={styles.personDetailsText}>Actor, Director</Text>
          </View>
        </View>
      </Pressable>
      {props.children || <Text></Text>}
    </View>
  );
}

const styles = StyleSheet.create({
  peopleListItemContainer: {
    backgroundColor: colors.background_lighter,
    borderRadius: 8,
    padding: 8,
    paddingRight: 20,
    marginVertical: 5,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    minHeight: 50,
  },
  peopleListItemContainerInner: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  personImage: { height: 60, width: 60, borderRadius: 300, marginRight: 10 },
  personInitialsCircle: {
    width: 60,
    height: 60,
    borderRadius: 300,
    backgroundColor: colors.main_purple,
    justifyContent: "center",
    marginRight: 10,
  },
  personNameText: {
    fontWeight: "600",
    fontSize: 17,
    color: colors.text_white,
    marginBottom: 8,
  },
  personDetailsText: {
    fontWeight: "400",
    fontSize: 13,
    color: colors.text_grey,
  },
});
