import React, { useState, useEffect, Suspense } from "react";
// prettier-ignore
import { StyleSheet, Share, ActivityIndicator, Text, SafeAreaView, ScrollView, FlatList, Pressable, Button, View,  TextInput, ImageBackground, Switch} from "react-native";

import { useNavigation, useNavigationState } from "@react-navigation/native";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { createStackNavigator } from "@react-navigation/stack";

import useSeekStore from "../app_shared/use-seek-store";
import { AntDesign } from "@expo/vector-icons";

import * as Toast from "./components/Toast.js";

import { BackButton } from "./components/BackButton.js";
import { ActionMarkButtonsSmall } from "./components/ActionMarkButtonsSmall.js";
import { FeedListItem } from "./components/FeedListItem.js";
import { ActionMarkButton } from "./components/ActionMarkButton.js";
import { PublicPageSettings } from "./components/PublicPageSettings.js";

import { useDebounce, useFSPagination } from "./helpers/hooks.js";

import AllAssets from "./helpers/assets.js";
// prettier-ignore
const  { pageByScreenNameAsset, publicMarksAsset, userFolsAsset, publicPageAsset } = AllAssets;
import { FSHelpers } from "./../app_shared/fs_helpers.js";
import { firebase } from "./../fire.js";
// prettier-ignore
import { getFirestore, collection, query, where, doc,  orderBy, limit, addDoc } from "firebase/firestore/lite";
const db = getFirestore(firebase);

// prettier-ignore
import { PlaceHolderUserPhoto, UserPhotoComp } from "./components/UserImages.js";

import { get_public_url_for_sn } from "./helpers/name_helpers.js";

import colors from "./colors.js";
import * as ButtonIcons from "./components/ButtonIcons.js";

const FH = new FSHelpers(firebase);

export default function UserPageScreen(props) {
  // console.log("UserPageScreen");
  return (
    <SafeAreaView style={styles.container}>
      <Suspense
        fallback={
          <ActivityIndicator
            color="rgb(250,200,100)"
            size="large"
            style={styles.activityIndicator}
          />
        }
      >
        <UserPageScreenNFWrap {...props} />
      </Suspense>
    </SafeAreaView>
  );
}

function NotFoundView(props) {
  const navigation = useNavigation();
  return (
    <View style={styles.notFoundView}>
      <Text style={styles.notFoundText}> User Page Not Found</Text>
      <Pressable onPress={() => navigation.goBack()} style={styles.backButton}>
        <AntDesign name="arrowleft" size={20} color="white" />
        <Text style={styles.backButtonText}>Back</Text>
      </Pressable>
    </View>
  );
}
// this just wraps the below to make it easier to deal with what's essentially a 404
function UserPageScreenNFWrap(props) {
  const { params } = props.route;
  const userPage = params.uid
    ? publicPageAsset.read(params.uid)
    : pageByScreenNameAsset.read(params.screenName);

  return userPage.error === true ? (
    <NotFoundView />
  ) : (
    <UserPageScreenInner {...props} userPage={userPage} />
  );
}

const profile_selector = (state) => state.profile;
const seek_actions_selector = (state) => state.seek_actions;
const user_marks_selector = (state) => state.user_marks;
const following_selector = (state) => state.following;
const followers_selector = (state) => state.followers;
const favorite_artists_selector = (state) => state.favorite_artists;

function UserPageScreenInner(props) {
  const { params } = props.route;
  const { userPage } = props;
  // console.log("render UserPageScreenInner");
  const seek_actions = useSeekStore(seek_actions_selector);
  const current_user_profile = useSeekStore(profile_selector);

  // const deps = [params.uid, params.screenName];
  const deps = params.uid ? [params.uid] : [params.screenName];
  // console.log(deps);

  const page_length = 4;
  // profile_marks belong to the profile's owner whereas user_marks belong to the current user
  const [profile_marks, fetchData] = useFSPagination(
    query(
      collection(db, "user_marks"),
      orderBy("ts", "desc"),
      where("is_public", "==", true),
      where("user_uid", "==", userPage.uid)
    ),
    page_length,
    deps
  );

  const user_marks = useSeekStore(user_marks_selector);

  return (
    <>
      <View style={styles.backButton}>
        <BackButton />
      </View>
      <PageInfoBar
        userPage={userPage}
        seek_actions={seek_actions}
        current_user_profile={current_user_profile}
        navigation={props.navigation}
      />
      <FlatList
        data={profile_marks}
        style={styles.feedList}
        ListEmptyComponent={<View></View>}
        extraData={{ userPage }}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item, index, separators }) => {
          return (
            <Suspense fallback={<></>}>
              <FeedListItem
                item={item}
                onImagePress={() => {
                  props.navigation.navigate("ShowDetail", {
                    show_id: item.tvmaze_id,
                  });
                }}
              >
                <ActionMarkButtonsSmall
                  item={item}
                  user_marks={user_marks}
                  seek_actions={seek_actions}
                />
              </FeedListItem>
            </Suspense>
          );
        }}
        onEndReachedThreshold={0.25}
        onEndReached={(dist) => {
          // console.log("onEndReached", dist);
          fetchData();
        }}
      />
    </>
  );
}

function PageInfoBar(props) {
  const { current_user_profile, seek_actions, userPage } = props;
  // console.log("render PageInfoBar!");

  const following = useSeekStore(following_selector);
  const followers = useSeekStore(followers_selector);

  const existing_fol = following
    ? following.find((x) => x.to_uid === userPage.uid)
    : false;

  const favorite_artists = useSeekStore(favorite_artists_selector);

  return (
    <>
      <View style={styles.titleWrapper}>
        <Text style={styles.titleText}>
          {current_user_profile && current_user_profile["uid"] === userPage.uid
            ? "Your Profile"
            : `@${userPage.screenName}`}
        </Text>
        {current_user_profile &&
        current_user_profile["uid"] === userPage.uid ? (
          <>
            <ButtonIcons.Gear
              onPress={() => props.navigation.navigate("Profile")}
            />
          </>
        ) : (
          <FollowRequestButton
            following={following}
            existing_fol={existing_fol}
            seek_actions={seek_actions}
            userPage={userPage}
            current_user_profile={current_user_profile}
          />
        )}
      </View>
      <View style={styles.userInfoContainer}>
        {userPage.userPhoto ? (
          <UserPhotoComp size={64} userPhoto={userPage.userPhoto} />
        ) : (
          <PlaceHolderUserPhoto size={32} />
        )}
        <View style={styles.userInfoContainerInner}>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <Text style={styles.displayNameText}>{userPage.displayName}</Text>
            <ButtonIcons.ShareIcon
              size={20}
              style={{ marginLeft: 8 }}
              onPress={async () => {
                const url = get_public_url_for_sn(userPage.screenName)[
                  "actual"
                ];
                const result = await Share.share({ url });
              }}
            />
          </View>
          <Suspense fallback={<ActivityIndicator style={{ marginTop: 16 }} />}>
            <UserFolInfo
              navigation={props.navigation}
              userPage={userPage}
              current_user_profile={current_user_profile}
            />
          </Suspense>
        </View>
      </View>
      {/* Row of folFol / favArtist info for own profile */}
      {current_user_profile && current_user_profile["uid"] === userPage.uid ? (
        <View style={styles.ownFolFolContainer}>
          <Pressable
            onPress={() => {
              props.navigation.navigate("Connections", {
                whichList: "followers",
                uid: userPage.uid,
              });
            }}
          >
            <View style={styles.ownFolFolWrapper}>
              <Text style={styles.ownFollowingText}>{followers.length}</Text>
              <Text style={styles.ownFollowingText}>
                {followers.length === 1 ? "Follower" : "Followers"}
              </Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => {
              props.navigation.navigate("Connections", {
                whichList: "following",
                uid: userPage.uid,
              });
            }}
          >
            <View style={styles.ownFolFolWrapper}>
              <Text style={styles.ownFollowingText}>{following.length}</Text>
              <Text style={styles.ownFollowingText}>Following</Text>
            </View>
          </Pressable>
          <Pressable
            onPress={() => {
              props.navigation.navigate("FavoriteArtists", {
                uid: current_user_profile.uid,
              });
            }}
          >
            <View style={styles.ownFolFolWrapper}>
              <Text style={styles.ownFollowingText}>
                {favorite_artists.length}
              </Text>
              <Text style={styles.ownFollowingText}>
                {favorite_artists.length === 1 ? "Fav. Artist" : "Fav. Artists"}
              </Text>
            </View>
          </Pressable>
        </View>
      ) : (
        <></>
      )}
    </>
  );
}

function UserFolInfo(props) {
  const { current_user_profile, navigation, userPage } = props;
  const folfol = userFolsAsset.read(userPage.uid);

  return (
    <View style={styles.folFolContainer}>
      {current_user_profile && current_user_profile["uid"] === userPage.uid ? (
        <Text style={styles.folFolText}>@{userPage.screenName}</Text>
      ) : (
        <>
          <Text
            style={styles.folFolText}
            onPress={() => {
              props.navigation.navigate("Connections", {
                whichList: "followers",
                uid: userPage.uid,
              });
            }}
          >
            {folfol.followers.length}{" "}
            {folfol.followers.length === 1 ? "Follower" : "Followers"}
          </Text>
          <Text
            style={styles.folFolText}
            onPress={() => {
              props.navigation.navigate("Connections", {
                whichList: "following",
                uid: userPage.uid,
              });
            }}
          >
            {folfol.following.length} Following
          </Text>
        </>
      )}
    </View>
  );
}

function FollowRequestButton(props) {
  const navigation = useNavigation();
  const { showActionSheetWithOptions } = useActionSheet();
  const { existing_fol, seek_actions, userPage, current_user_profile } = props;
  if (current_user_profile && current_user_profile["uid"] === userPage.uid) {
    return <View></View>;
  }
  // and if it's not your own
  return (
    <>
      {!existing_fol ? (
        <Pressable
          style={styles.followRequestButton}
          onPress={async () => {
            if (!current_user_profile) {
              navigation.navigate("Profile");
            } else {
              userFolsAsset.clear(userPage.uid);
              await seek_actions.makeFollowRequest(userPage.uid);
              // prettier-ignore
              Toast.alert({ title: `Now Following @${userPage.screenNameCased}`, description:`👋 ${userPage.displayName}`, duration: 4 }, "success");
            }
          }}
        >
          <Text style={styles.followRequestButtonText}>Follow +</Text>
        </Pressable>
      ) : (
        <Pressable
          style={styles.followRequestButton}
          onPress={() => {
            showActionSheetWithOptions(
              {
                options: [`Unfollow @${userPage.screenNameCased}`, "Cancel"],
                destructiveButtonIndex: 0,
                cancelButtonIndex: 1,
              },
              async (buttonIndex) => {
                if (buttonIndex === 0) {
                  userFolsAsset.clear(userPage.uid);
                  await seek_actions.deleteFollowRequest(existing_fol.id);
                  // prettier-ignore
                  Toast.alert({ title: `Unfollowed @${userPage.screenNameCased}`, duration: 4, description:`👋 ${userPage.displayName}` }, "danger");
                }
              }
            );
          }}
        >
          <Text style={styles.followRequestButtonText}>Following</Text>
        </Pressable>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.background,
    flex: 1,
  },
  activityIndicator: { marginTop: 50 },
  notFoundView: { flex: 1, alignItems: "center", justifyContent: "center" },
  notFoundText: { color: colors.text_white, fontSize: 24 },
  backButton: {
    marginTop: 13,
    marginLeft: 24,
    alignItems: "center",
    flexDirection: "row",
    zIndex: 5,
  },

  titleWrapper: {
    marginTop: 13,
    marginHorizontal: 24,
    paddingBottom: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomColor: colors.background_lighter,
    borderBottomWidth: 2,
  },
  titleText: {
    fontWeight: "bold",
    fontSize: 24,
    color: colors.text_white,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
  followRequestButton: {
    width: 120,
    height: 40,
    borderRadius: 30,
    borderColor: colors.aquamarine,
    borderWidth: 1.5,
    alignItems: "center",
    justifyContent: "center",
  },
  followRequestButtonText: {
    color: colors.aquamarine,
    fontSize: 15,
    fontWeight: "bold",
  },
  userInfoContainer: {
    flexDirection: "row",
    marginHorizontal: 24,
    marginTop: 29,
  },
  userInfoContainerInner: {
    marginLeft: 16,
  },
  displayNameText: {
    color: colors.text_white,
    fontSize: 18,
    fontWeight: "bold",
  },
  folFolContainer: {
    flexDirection: "row",
    marginTop: 8.5,
  },
  folFolText: { fontSize: 14, color: colors.text_grey, marginRight: 32 },
  ownFolFolContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginHorizontal: 24,
    marginTop: 24,
    borderTopWidth: 2,
    borderTopColor: colors.background_lighter,
  },
  ownFolFolWrapper: {
    alignItems: "center",
    marginTop: 29,
    paddingHorizontal: 15,
  },
  ownFollowingText: { color: colors.text_grey, fontWeight: "bold" },
  feedList: { marginTop: 29 },
});
